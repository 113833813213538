import {
  Box,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { ExpandLess } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import WBPAddressText from "./sub-components/WBPAddressText";
import BeeCareOrderContext from "../contexts/BeeCareOrderContext";
import SettingsContext from "../contexts/SettingsContext";
import UserContext from "../contexts/UserContext";
import DonationSummary from "./sub-components/DonationSummary";
import references from "../services/references";
import DiscountToolTip from "./sub-components/DiscountToolTip";

const useStyles = makeStyles((theme) => ({
  desktopMargin: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  stepperBand: {
    background: theme.palette.background.paper,
    borderTop: "1px solid #eee",
  },
  sidebarRoot: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      background: theme.palette.background.gold,
      width: "100%",
      zIndex: 2,
    },
  },
  goldPaper: {
    background: theme.palette.background.gold,
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "#FFF",
    },
    "& .MuiTypography-body1": {
      color: "#DDD",
    },
  },
  customSteps: {
    "& .MuiStepConnector-alternativeLabel": {
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    "& .MuiStepConnector-line": {
      borderColor: "#eeeeee",
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepIcon-root": {
      background: "white url(/images/step.svg) no-repeat center center / cover",
      color: "transparent",
    },
    "& .MuiStepIcon-active": {
      background:
        "white url(/images/step-active.svg) no-repeat center center / cover",
    },
  },
  flexGrow2: {
    [theme.breakpoints.down("md")]: {
      flexGrow: ".5",
    },
  },
  fullWidth: {
    width: "100%",
  },
  desktopPadding: {
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
  },
}));

export default function BeeCareOrderSideBar({ children, step, showSummary }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [beeCareOrder] = useContext(BeeCareOrderContext);
  const settings = useContext(SettingsContext).beecare;
  const [user] = useContext(UserContext);
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(references.defaultOrders.beecare.price);

  useEffect(() => {
    setCurrency(settings ? settings.currency : "");
  }, [settings.currency]);

  useEffect(() => {
    if (beeCareOrder.variant && beeCareOrder.variant.price) {
      setPrice(
        typeof beeCareOrder.variant.price === "object" &&
          beeCareOrder.variant.price.amount
          ? Number(beeCareOrder.variant.price.amount)
          : Number(beeCareOrder.variant.price)
      );
    }
  }, [beeCareOrder.variant]);

  const summaryBody = () => {
    return (
      <Box px={4} className={`${classes.desktopPadding} ${classes.fullWidth}`}>
        <Box
          color={step < 1 ? "grey.300" : "inherit"}
          alignItems="flex-start"
          pt={1}
        >
          <Grid container xs>
            <Grid container direction="row" pt={1} alignItems="flex-start">
              <Grid item xs={1} className={classes.countColumn}>
                <Typography variant="body1">{beeCareOrder.amount}</Typography>
              </Grid>
              <Grid item xs={7} className={classes.itemColumn}>
                <Typography variant="body1">{t("BeeCare")}</Typography>
                {beeCareOrder.priceReduction > 0 && (
                  <DiscountToolTip
                    priceReduction={beeCareOrder.priceReduction}
                    currency={currency}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.priceColumn}>
                <Typography variant="body1" align="right">
                  {currency +
                    " " +
                    parseFloat(beeCareOrder.amount * price).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            {beeCareOrder.discount_vouchers.length > 0 && (
              <Grid
                container
                item
                direction="row"
                py={1}
                alignItems="flex-start"
              >
                <Grid item xs={1} className={classes.countColumn}>
                  <Typography variant="body1">
                    {beeCareOrder.discount_vouchers.length}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.itemColumn}>
                  <Typography variant="body1">{t("326")}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.priceColumn}>
                  <Typography variant="body1" align="right">
                    {"- " +
                      currency +
                      " " +
                      parseFloat(
                        beeCareOrder.discount_vouchers.length * price
                      ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {beeCareOrder.donation > 0 && (
              <Grid container direction="row" py={1} alignItems="center">
                <Grid item xs={1} className={classes.countColumn}>
                  <Typography variant="body1">1</Typography>
                </Grid>
                <Grid item xs={7} className={classes.itemColumn}>
                  <Typography variant="body1">{t("Donation")}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.priceColumn}>
                  <Typography variant="body1" align="right">
                    {currency + " " + Number(beeCareOrder.donation).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider></Divider>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              <strong>{t("Total")}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary">
              {currency +
                " " +
                Number(
                  (beeCareOrder.amount -
                    beeCareOrder.discount_vouchers.length) *
                    price +
                    beeCareOrder.donation
                ).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Typography variant="h4">
            {t("Return address")}
            <WBPAddressText
              address={user.addresses.find(
                (address) => address.id === beeCareOrder.address_id
              )}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item md={8} xs={12} className="fullHeight">
          {children}
        </Grid>
        <Grid item md={4} xs={12} className={classes.sidebarRoot}>
          <Box className={classes.desktopMargin}>
            <Paper square={true} elevation={0} className={classes.pb}>
              {showSummary &&
                (isMobile ? (
                  step < 2 && (
                    <Accordion className={classes.goldPaper} square={true}>
                      <Box px={2}>
                        <AccordionSummary
                          expandIcon={<ExpandLess />}
                          aria-controls="panel1a-content"
                          id="orderSummary-header"
                        >
                          <Typography variant="h6">
                            {t("Order summary")}
                          </Typography>
                        </AccordionSummary>
                      </Box>
                      <AccordionDetails>{summaryBody()}</AccordionDetails>
                    </Accordion>
                  )
                ) : (
                  <div>
                    {step < 2 && (
                      <Box px={3} pt={3}>
                        <Typography variant="h4">
                          {t("Order summary")}
                        </Typography>
                      </Box>
                    )}
                    {step < 2 && summaryBody()}
                    {step === 2 && <DonationSummary />}
                  </div>
                ))}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
