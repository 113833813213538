import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";
import UserContext from "../../../contexts/UserContext";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function BeeCareStatusInvoice({ value, displayStepDetails }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [user] = useContext(UserContext);

  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return (
    <>
      {value.beecare &&
        value.lastSeason.voucher_code &&
        value.lastSeason.voucher_code !== null && (
          <WBPBeeCareStatusInfoBox
            selectedStep={value.beecare.selectedStep}
            displayStepDetails={changeSelectedStep}
            showNextButton={false}
            secondary
          >
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h6">{t("254")}</Typography>
                <Typography variant="body1">{t("255")}</Typography>
              </Grid>
              <Grid
                container
                item
                xs
                md
                justifyContent={isMobile ? "flex-start" : "flex-end"}
                style={{ marginTop: isMobile ? "24px" : 0 }}
              >
                <Typography
                  variant="button"
                  color="textSecondary"
                  align={isMobile ? "left" : "right"}
                  paragraph
                >
                  {t("256")}:
                  <br />
                  <strong>{value.lastSeason.voucher_code.toUpperCase()}</strong>
                </Typography>
              </Grid>
            </Grid>
          </WBPBeeCareStatusInfoBox>
        )}
      <WBPBeeCareStatusInfoBox
        selectedStep={value.beecare.selectedStep}
        displayStepDetails={changeSelectedStep}
        title={t("278")}
        showNextButton={false}
        secondary
      >
        {t("279", {
          emailAddress: user.billing_contact.email.trim().toLowerCase(),
        })}
      </WBPBeeCareStatusInfoBox>
    </>
  );
}
