import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let message = props.message;
  let emailAddress = null;
  let errorObject = null;
  if (typeof props.message === "object") {
    message = props.message.text ? props.message.text : props.message;
    if (props.message.emailAddress) {
      emailAddress = t(props.message.emailAddress);
    }
    if (props.message.error) {
      errorObject = props.message.error;
    }
  }

  useEffect(() => {
    if (props.open) setOpen(true);
  }, [props.open]);

  const handleClose = (event, isYes) => {
    if (isYes && "onTrueHandle" in props) {
      props.onTrueHandle();
    } else {
      if ("onFalseHandle" in props) {
        props.onFalseHandle();
      }
    }
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event) => {
          handleClose(event, false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {typeof message === "object" ? (
              message
            ) : (
              <Trans
                i18nKey={message}
                values={{
                  email: emailAddress,
                  errorMessage: errorObject !== null ? errorObject.detail : "",
                  errorStatus: errorObject !== null ? errorObject.status : "",
                  errorTitle: errorObject !== null ? errorObject.title : "",
                }}
                components={{
                  lnk: (
                    <Link
                      href={"mailto:" + emailAddress}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                  ul: <ul className="bullets" />,
                  li: <li />,
                  b: <strong />,
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.type === "confirm" && (
            <Button
              id="button-alertdialog-cancelno"
              onClick={(event) => {
                handleClose(event, false);
              }}
              color="secondary"
            >
              {props.noButtonText || t("Cancel")}
            </Button>
          )}
          <Button
            id="button-alertdialog-okyes"
            onClick={(event) => {
              handleClose(event, true);
            }}
            color="primary"
            autoFocus
          >
            {props.yesButtonText || t(props.type === "confirm" ? "Yes" : "OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
