import {
  Button,
  Grid,
  TextField,
  Box,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery,
  FormControl,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AuthContext from "../../contexts/AuthContext";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    maxHeight: "calc( 100vh - 100px )",
    display: "block",
  },
}));

export default function ChangeEmailModal(propsData) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { sendVerifyBeforeUpdateEmail } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newEmailAddressRef = useRef("");
  const [displayError, setDisplayError] = useState({});

  const emailRegEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,}$/;

  const helperText = () => {
    let txt = {};
    if (newEmailAddressRef.current.value.length < 1) {
      txt.email = t("This field is compulsory");
    } else if (!emailRegEx.test(newEmailAddressRef.current.value)) {
      txt.email = t("Email format is invalid");
    }

    setDisplayError(txt);
    return txt;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const hText = helperText();
    if (
      !("email" in hText) &&
      propsData.emailAddress.trim().toLowerCase() !==
        newEmailAddressRef.current.value.trim().toLowerCase()
    ) {
      sendVerifyBeforeUpdateEmail(newEmailAddressRef.current.value);
    }
  };

  const closeModal = () => {
    clearFields();
    propsData.onClose();
  };
  const clearFields = () => {
    setDisplayError({});
    setIsSubmitting(false);
  };

  return (
    <Dialog
      open={propsData.open}
      onClose={closeModal}
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <form
        className={classes.root}
        onSubmit={!isSubmitting ? submitHandler : undefined}
      >
        <Box p={isMobile ? 2 : 4} pb={isMobile ? 10 : 4}>
          <FormControl>
            <Box mb={2}>
              <Typography variant="h3">{t("465")}</Typography>
            </Box>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  id="current_email"
                  label={t("466")}
                  variant="outlined"
                  name="current_email"
                  hinttext={t("466")}
                  floatinglabeltext={t("466")}
                  value={propsData.emailAddress}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="new_email"
                  inputRef={newEmailAddressRef}
                  label={t("467")}
                  variant="outlined"
                  name="new_email"
                  hinttext={t("467")}
                  floatinglabeltext={t("467")}
                  onChange={(event) => {
                    event.preventDefault();
                    clearFields();
                  }}
                  helperText={displayError.email}
                  error={"email" in displayError}
                  fullWidth
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  id="button-addressmodal-cancel"
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  id="button-addressmodal-submit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={"email" in displayError ? true : false}
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </form>
    </Dialog>
  );
}
