import { Grid, Typography, Link, useMediaQuery, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";

export default function BeeCareStatusInbound({
  value,
  completedSteps,
  displayStepDetails,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return completedSteps.indexOf(0) > -1 ? (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      showPreviousButton={false}
      title={t("343")}
      secondary
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          {t("344")}
        </Grid>
        <Grid
          container
          item
          xs
          md
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          style={{ marginTop: isMobile ? "24px" : 0 }}
        >
          {value.inSeason.tracking_number && (
            <Typography
              variant="button"
              color="textSecondary"
              align={isMobile ? "left" : "right"}
            >
              {t("298")}:
              <br />
              <Link href={value.inSeason.tracking_url} target="_blank">
                {value.inSeason.tracking_number}
              </Link>
            </Typography>
          )}
        </Grid>
      </Grid>
    </WBPBeeCareStatusInfoBox>
  ) : (
    <>
      {value.lastSeason.wasOrderedInMyBeehome === false ? (
        <WBPBeeCareStatusInfoBox
          selectedStep={value.beecare.selectedStep}
          displayStepDetails={changeSelectedStep}
          showPreviousButton={false}
          title={t("396")}
          secondary
        >
          {t("397")}
        </WBPBeeCareStatusInfoBox>
      ) : (
        <WBPBeeCareStatusInfoBox
          selectedStep={value.beecare.selectedStep}
          displayStepDetails={changeSelectedStep}
          showPreviousButton={false}
          title={t("341")}
          secondary
        >
          <Trans
            i18nKey="342"
            components={{
              lnk: (
                <Link href={t("sendinbox")} rel="noreferrer" target="_self" />
              ),
            }}
          />
        </WBPBeeCareStatusInfoBox>
      )}
    </>
  );
}
