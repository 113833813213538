import { TableCell, alpha } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ListItem } from "@mui/material";
import { ToggleButton } from "@mui/material";

export const BorderLessCell = withStyles({
  root: {
    border: "none",
  },
})(TableCell);

export const ConfiguredListItem = withStyles({
  root: {
    backgroundColor: "transparent !important",
    "&:hover": {
      boxShadow: "inset 4px 0 #F1B420",
    },
    "&.Mui-selected": {
      boxShadow: "inset 4px 0 #F1B420",
    },
  },
})(ListItem);

export const WBPToggleButton = withStyles((theme) => ({
  root: {
    borderWidth: "2px",
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    width: "100%",
    "&:hover": {
      color: "#FFF",
      backgroundColor: theme.palette.secondary.main + "!important",
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
      backgroundOpacity: "0.5",
    },
  },
}))(ToggleButton);
