import React from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  desktopPadding: {
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingBottom: 1,
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
  },
  donationImage: {
    display: "inline-block",
    width: "100%",
    margin: "auto",
  },
}));

export default function DonationSummary({ showClose = false }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      px={4}
      className={`${classes.desktopPadding} ${classes.fullWidth}`}
      bgcolor="primary.main"
      color="primary.contrastText"
    >
      <Box mb={6}>
        <Grid container direction="row" alignItems="baseline">
          <Grid container item xs={10} md={12} alignItems="baseline">
            <Typography variant="h4" style={{ color: "#FFFFFF" }} paragraph>
              {t("367")}
            </Typography>
          </Grid>
          {showClose && (
            <Grid container item xs={2}>
              <IconButton
                id="button-donationsummary-close"
                edge="end"
                aria-label="set default"
                style={{ color: "#FFFFFF" }}
                size="large"
              >
                <Close />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Typography variant="body1" style={{ color: "#FFFFFF" }} paragraph>
          <Trans i18nKey={"368"} />
        </Typography>
        <Box>
          <img
            className={classes.donationImage}
            src="/images/Pollinature_Bestäubungsservice_mit_Mauerbienen.jpg"
          />
        </Box>
      </Box>
    </Box>
  );
}
