import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  media: {
    height: 130,
  },
  check: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 9,
  },
  overlay: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#F1B420AA",
  },
}));

export default function HouseCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [raised, setRaised] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (props.houseType === props.code) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props]);

  const onMouseOver = () => {
    setRaised(true);
  };
  const onMouseOut = () => {
    setRaised(false);
  };
  const toggleSelected = () => {
    props.onChange({ code: props.code, value: true });
  };

  return (
    <Card
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      elavation="6"
      raised={raised}
      onMouseDown={toggleSelected}
      className={classes.root}
      variant="elevation"
    >
      <CardActionArea>
        <Box color="primary.contrastText">
          {selected ? (
            <RadioButtonCheckedRounded
              className={classes.check}
            ></RadioButtonCheckedRounded>
          ) : (
            <RadioButtonUncheckedRounded
              className={classes.check}
            ></RadioButtonUncheckedRounded>
          )}
        </Box>

        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.name}
        />
        {selected && <Box className={classes.overlay}></Box>}
      </CardActionArea>
      <Box
        bgcolor={
          selected ? "primary.main" : raised ? "grey.300" : "background.paper"
        }
        color={selected ? "primary.contrastText" : "black"}
        align="center"
      >
        <CardContent>
          <Box minHeight={40}>{t(props.name)}</Box>
        </CardContent>
      </Box>
    </Card>
  );
}
