import {
  createTheme,
  Grid,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { createContext, useState, useEffect, useContext } from "react";
import HeaderAppBar from "../components/HeaderAppBar";
import NotificationBar from "../components/sub-components/NotificationBar";
import BeeHomeMenu from "../components/sub-components/BeeHomeMenu";
import { useLocation } from "react-use";
import UIContext from "./UIContext";
import themeOptions from "../services/theme-default";
import deepmerge from "deepmerge";

export const LayoutContext = createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc( 100% - 67px )",
    // components: {
    "&.MuiFormHelperText-root": {
      styleOverrides: {
        root: {
          textAlign: "end",
        },
        // },
      },
    },
  },
  mobiMenu: {
    [theme.breakpoints.down("md")]: {
      left: 0,
      position: "absolute",
      right: 0,
      transition: "all .2s ease-in-out",
      zIndex: "9",
    },
  },
  mobiMenuOn: {
    top: "67px",
  },
  mobiMenuOff: {
    top: "-150%",
  },

  loginRoot: {
    backgroundImage: "url('/images/loginbg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  page: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3),
    },
  },
}));

export const LayoutProvider = ({ children }) => {
  let location = useLocation();
  const classes = useStyles();
  const [notificationBarOpen, setNotificationBarOpen] = useState(false);
  const [notificationBarText, setNotificationBarText] = useState("");
  const [defaultBackground, setDefaultBackground] = useState(false);
  const [ui] = useContext(UIContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const newTheme = createTheme(
    deepmerge(theme, {
      components: {
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginLeft: 0,
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(!ownerState.disabled && {
                fontWeight: "bold",
              }),
            }),
          },
        },
      },
    })
  );

  useEffect(() => {
    setDefaultBackground(
      location.pathname.indexOf("login") > -1 ||
        location.pathname.indexOf("signup") > -1 ||
        location.pathname.indexOf("outofseason") > -1 ||
        location.pathname.indexOf("soldout") > -1 ||
        location.pathname.indexOf("register") > -1 ||
        location.pathname.indexOf("setpassword") > -1 ||
        location.pathname.indexOf("resetpassword") > -1 ||
        location.pathname.indexOf("passwordsuccess") > -1
    );
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location]);
  useEffect(() => {
    setNotificationBarOpen(
      notificationBarText.length,
      notificationBarText.length > 0 ? true : false
    );
  }, [notificationBarText]);

  const clearText = () => {
    setNotificationBarText("");
  };

  return (
    <LayoutContext.Provider value={setNotificationBarText}>
      <ThemeProvider theme={newTheme}>
        <HeaderAppBar></HeaderAppBar>
        <Grid container direction="row" className={classes.root}>
          {ui.username && (
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              className={`${isMobile ? classes.mobiMenu : ""} ${
                ui.menuOpen ? classes.mobiMenuOn : classes.mobiMenuOff
              }`}
            >
              <BeeHomeMenu />
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            md={ui.username ? 10 : 12}
            alignItems={defaultBackground ? "center" : "flex-start"}
            className={defaultBackground ? classes.loginRoot : ""}
            justifyContent="center"
            sx={{
              backgroundColor:
                location.pathname === "/"
                  ? themeOptions.palette.primary.contrastText
                  : "",
            }}
          >
            <Grid
              item
              container
              xs={12}
              md={ui.username ? 12 : 10}
              alignItems="center"
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
        <NotificationBar
          message={notificationBarText}
          open={notificationBarOpen}
          clearText={clearText}
        />
      </ThemeProvider>
    </LayoutContext.Provider>
  );
};
