import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DiscountToolTip(props) {
  const { t } = useTranslation();

  return (
    <Typography
      variant="caption"
      align="left"
      color="textSecondary"
      sx={{ fontStyle: "italic" }}
    >
      {t("463", {
        priceReduction: Number(props.priceReduction).toFixed(2),
        currency: props.currency,
      })}
    </Typography>
  );
}
