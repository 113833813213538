import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import API from "../../services/api";
import { useTranslation } from "react-i18next";
import AddressModal from "../modals/AddressModal";
import UserContext from "../../contexts/UserContext";
import WBPAddressText from "./WBPAddressText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AddressList(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const loadAccountData = props.loadAccountData;
  const contact = props.contact;
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [user] = useContext(UserContext);

  const openModal = (address) => {
    setEditAddress(address);
    setModalOpenStatus(true);
  };

  const closeModal = () => {
    setModalOpenStatus(false);
    loadAccountData();
  };

  const newAddress = () => {
    const addressData = {
      first_name: contact.first_name,
      last_name: contact.last_name,
    };

    if (props.addresses.length === 0) {
      addressData.tags = ["BILLING"];
    }

    openModal(addressData);
  };

  const editItem = (ev, row) => {
    openModal(row);
  };

  const deleteItem = (ev, rowID) => {
    API.delete(`me/addresses/` + rowID).then(loadAccountData);
  };

  const isDefault = (row) => {
    if (row.tags.indexOf("BILLING") > -1) {
      return true;
    }
    return false;
  };

  const setDefault = (ev, row) => {
    let data = {
      country_code: row.country_code,
      tags: ["BILLING"],
    };
    API.put(`me/addresses/` + row.id, data).then(loadAccountData);
  };

  return (
    <div>
      <h2>{t("Addresses")}</h2>
      <List className={classes.root}>
        {props.addresses &&
          props.addresses.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;
            return (
              <ListItem key={value.id} role={undefined} dense>
                <ListItemIcon>
                  {isDefault(value) && (
                    <IconButton edge="end" aria-label="is default" size="large">
                      <RadioButtonCheckedIcon color="primary" />
                    </IconButton>
                  )}
                  {!isDefault(value) && (
                    <IconButton
                      edge="end"
                      aria-label="set default"
                      onClick={(event) => setDefault(event, value)}
                      size="large"
                    >
                      <RadioButtonUncheckedIcon />
                    </IconButton>
                  )}
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <WBPAddressText address={value} />
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    color="secondary"
                    onClick={(event) => editItem(event, value)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <span
                    hidden={
                      user.billing_address &&
                      Number(user.billing_address.id) === Number(value.id)
                    }
                  >
                    <br />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(event) => deleteItem(event, value.id)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        <ListItem>
          <Button
            id="button-addresslist-addanewaddress"
            variant="contained"
            color="primary"
            edge="end"
            aria-label={t("Add a new address")}
            onClick={newAddress}
            disableElevation
            fullWidth
          >
            {t("Add a new address")}
          </Button>
        </ListItem>
      </List>
      <AddressModal
        address={editAddress}
        organisationId={user.organisation ? user.organisation.id : ""}
        open={modalOpenStatus}
        onClose={closeModal}
      />
    </div>
  );
}
