import { useState, useEffect, useContext, useRef } from "react";
import history from "../services/history";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  Divider,
  IconButton,
  Hidden,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { StartpopSettingsContext } from "../contexts/SettingsContext";
import StartpopOrderContext from "../contexts/StartpopOrderContext";
import { Trans, useTranslation } from "react-i18next";
import WBPPage from "./sub-components/WBPPage";
import { Add, ChevronLeft, ChevronRight, Remove } from "@mui/icons-material";
import StartpopOrderSideBar from "./StartpopOrderSideBar";
import WBPInfoBox from "./sub-components/WBPInfoBox";
import { LayoutContext } from "../contexts/LayoutContext";
import CornutaWarningModal from "./modals/CornutaWarningModal";
import references from "../services/references";
import UserContext from "../contexts/UserContext";
import themeOptions from "../services/theme-default";
import HeaderTextDropdowns from "./sub-components/HeaderTextDropdowns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  textfield: {
    id: "outlined-basic",
    label: "Outlined",
    variant: "outlined",
    minWidth: 50,
    maxWidth: 279.28,
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  divider: {
    height: "3.5em",
    marginLeft: 14,
  },
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
  },
  adornmentButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:disabled": {
      color: theme.palette.disabled.contrastText,
      backgroundColor: theme.palette.disabled.default,
    },
    "&:hover": {
      backgroundColor: "#F1B4207C",
    },
  },
}));

const Startpops = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const settings = useContext(StartpopSettingsContext);
  const [startpopOrder, setStartpopOrder] = useContext(StartpopOrderContext);
  const setNotificationMessage = useContext(LayoutContext);
  const [user] = useContext(UserContext);

  const [tubes_count, setTubesCount] = useState(startpopOrder.amount);
  const [displayError, setDisplayError] = useState({});
  const [showMinimumPriceError, setShowMinimumPriceError] = useState(false);
  const [displayedPrice, setDisplayedPrice] = useState(settings.shopifyPrice);
  const [displayedText, setDisplayedText] = useState({});
  const [warningModalOpenStatus, setWarningModalOpenStatus] = useState(false);
  const [maxAmount, setMaxAmount] = useState(0);
  const [displayNumbers, setDisplayNumbers] = useState(references.numbers.de);
  const [warningId, setWarningId] = useState();

  const minAmount = references.defaultOrders.startpop.minimum_amount;
  const minimumPrice = references.defaultOrders.startpop.minimum_price;
  const allTexts = references.defaultOrders.startpop.priceText;

  const updatedValue = useRef("");
  const priceRef = useRef();
  const amountRef = useRef(minAmount);
  const submitRef = useRef(null);

  const warningTextObject = {
    424: {
      title: "423",
      warningText: "424",
      acceptButtonCloseText: "425",
      backButtonCloseText: "Cancel",
      urlLink: "426",
    },
  };

  const updatePrice = (newPrice) => {
    const prevError = { ...displayError };
    delete prevError.price;
    setDisplayError(prevError);
    setDisplayedPrice(newPrice);
    /*const variantPrice = settings.shopify.products.variants.find(
      (compareVariant) => {
        let compareVariantPrice = compareVariant.price;
        if (
          typeof compareVariantPrice === "object" &&
          compareVariantPrice.amount
        ) {
          compareVariantPrice = compareVariantPrice.amount;
        }
        return Number(compareVariantPrice) === Number(newPrice);
      }
    );*/
    setStartpopOrder((oldOrder) => ({
      ...oldOrder,
      // variant: variantPrice,
      price: newPrice,
      total_price:
        newPrice >= minimumPrice
          ? parseFloat(oldOrder.amount * newPrice).toFixed(2)
          : 0,
    }));
  };

  useEffect(() => {
    const amount = new Number(startpopOrder.amount);
    if (!amountRef.current.value && amount > minAmount - 1) {
      return;
    }

    if (
      Number(amountRef.current.value) !== Number(startpopOrder.amount) ||
      startpopOrder.validated_auto_vouchers.length > 0
    ) {
      changeHandler(amountRef.current.value);
    }
  }, [amountRef.current.value, startpopOrder.validated_auto_vouchers]);

  useEffect(() => {
    let mAmount = user.houses.length + Number(settings.max_units);
    if (startpopOrder.validated_auto_vouchers.length > mAmount) {
      mAmount = startpopOrder.validated_auto_vouchers.length;
    }

    setMaxAmount(mAmount);
  }, [user.houses, settings.max_units, startpopOrder.validated_auto_vouchers]);

  useEffect(() => {
    if (user.id && user.vouchers && settings.osmiaTypeSeason) {
      const voucherType =
        settings.osmiaTypeSeason == "bicornis" ? "STARTPOP_BC" : "STARTPOP_OC";
      const validatedAutoVouchers = user.vouchers.filter(
        (voucher) =>
          voucher.client_id === user.id &&
          voucher.is_active === true &&
          (voucher.type === "STARTPOP" || voucher.type === voucherType)
      );

      setStartpopOrder((oldOrder) => ({
        ...oldOrder,
        validated_auto_vouchers: validatedAutoVouchers,
      }));
    }
  }, [user.id, user.vouchers, settings.osmiaTypeSeason]);

  useEffect(() => {
    let titleTranslations = {
      mainTitle: t("StartpopOrder"),
      voucherTitle: t("Vouchers"),
      donationTitle: t("Donation"),
    };

    const lang = i18n.language.substring(0, 2);
    setDisplayNumbers(references.numbers[lang]);
    if (settings.shopify) {
      titleTranslations.voucherTitle = t("491");
      if (settings.shopify.products) {
        if (settings.shopify.products.title) {
          titleTranslations.mainTitle =
            lang === "fr"
              ? t(settings.osmiaTypeSeason === "bicornis" ? "95" : "401")
              : settings.shopify.products.title;
        }
        if (lang !== "fr" && settings.shopify.donationOptions.title) {
          titleTranslations.donationTitle =
            settings.shopify.donationOptions.title;
        }
      }
    }

    setStartpopOrder((oldOrder) => ({
      ...oldOrder,
      titleTranslations: titleTranslations,
    }));
  }, [i18n.language, settings.shopify]);

  useEffect(() => {
    if (settings.price_is_suggestion === false) {
      setStartpopOrder((oldOrder) => ({
        ...oldOrder,
        price: settings.shopifyPrice,
      }));
    }
  }, [settings.price_is_suggestion, settings.shopifyPrice, setStartpopOrder]);

  useEffect(() => {
    if (settings.osmiaTypeSeason) {
      setDisplayedText(allTexts[settings.osmiaTypeSeason]);
    }
  }, [settings.osmiaTypeSeason, settings.shopifyPrice]);

  useEffect(() => {
    if (settings.shopify && settings.shopify.variant) {
      setStartpopOrder((oldOrder) => ({
        ...oldOrder,
        variant: settings.shopify.variant,
        order_date: moment.utc().format("YYYY-MM-DD"),
      }));
    }
  }, [settings.shopify]);

  useEffect(() => updatePrice(settings.shopifyPrice), [settings.shopifyPrice]);

  const updateAmount = (event, calculationType) => {
    event.preventDefault();
    amountRef.current.value = Number(startpopOrder.amount) + calculationType;
    changeHandler(amountRef.current.value);
  };

  const changeHandler = (newAmount) => {
    setNotificationMessage("");
    const prevError = { ...displayError };
    delete prevError.tubes_count;
    setDisplayError(prevError);

    if (newAmount > maxAmount) {
      setNotificationMessage(t("405"));
      newAmount = maxAmount;
      amountRef.current.value = newAmount;
    } else if (newAmount < minAmount) {
      setNotificationMessage(t("Less than the minimum value"));
    }

    const newDiscountVouchers =
      newAmount < 1
        ? []
        : startpopOrder.validated_auto_vouchers.slice(0, newAmount);

    setTubesCount(newAmount);
    setStartpopOrder((prevStartpopOrder) => ({
      ...prevStartpopOrder,
      amount: newAmount,
      discount_vouchers: newDiscountVouchers,
      total_price:
        prevStartpopOrder.price >= minimumPrice && newAmount >= minAmount
          ? parseFloat(
              newAmount * prevStartpopOrder.price -
                newDiscountVouchers.length * prevStartpopOrder.price
            ).toFixed(2)
          : 0,
    }));
  };

  const helperText = () => {
    let txt = {};
    if (tubes_count < 1) {
      txt.tubes_count = t("This field is compulsory");
    }

    if (
      displayedPrice === "" &&
      settings.price_is_suggestion &&
      tubes_count > 0
    ) {
      txt.price = t("This field is compulsory");
    }
    setDisplayError(txt);
    return txt;
  };

  const nextPage = (e) => {
    e.preventDefault();

    if (!startpopOrder.variant) {
      setWarningModalOpenStatus(true);
      setWarningId(424);
      return;
    }

    const hText = helperText();
    if (!("tubes_count" in hText) && !("price" in hText)) {
      history.push("/startpops/delivery-date");
    }
  };

  return (
    <StartpopOrderSideBar step={0}>
      {!isNaN(maxAmount) &&
        (maxAmount ? (
          <WBPPage
            title={t(settings.osmiaTypeSeason === "bicornis" ? "93" : "399")}
            description={
              <Trans
                i18nKey={settings.osmiaTypeSeason === "bicornis" ? "94" : "400"}
                components={{
                  lnk: (
                    <Link
                      href={t("400-url")}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
                values={{
                  currency: settings.currency,
                  minimumPrice: minimumPrice,
                }}
              />
            }
          >
            <form onSubmit={nextPage}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 2 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  xl={startpopOrder.validated_auto_vouchers.length > 0 ? 3 : 5}
                >
                  <Typography variant="h6">
                    <Trans
                      i18nKey={startpopOrder.titleTranslations.mainTitle}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  xl={startpopOrder.validated_auto_vouchers.length > 0 ? 6 : 7}
                >
                  <Box
                    whiteSpace="nowrap"
                    display="flex"
                    justifyContent={{ xs: "center", sm: "end" }}
                    style={{
                      textAlignLast: "center",
                    }}
                  >
                    <TextField
                      className={classes.textfield}
                      disabled={maxAmount < 1}
                      error={
                        displayError.tubes_count ||
                        (amountRef.current.value !== "" &&
                          amountRef.current.value < minAmount)
                          ? true
                          : false
                      }
                      id="tubes_count"
                      name="tubes_count"
                      size="medium"
                      type="number"
                      variant="outlined"
                      inputRef={amountRef}
                      inputProps={{
                        max: maxAmount,
                        min: minAmount,
                      }}
                      defaultValue={
                        startpopOrder.amount ? startpopOrder.amount : minAmount
                      }
                      InputProps={{
                        classes: { input: classes.inputStyle },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              className={classes.adornmentButton}
                              aria-label="toggle password visibility"
                              onClick={(event) => updateAmount(event, -1)}
                              edge="start"
                              color="primary"
                              size="small"
                              disabled={startpopOrder.amount <= minAmount}
                            >
                              <Remove />
                            </IconButton>
                            <Divider orientation="vertical" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider orientation="vertical" />
                            <IconButton
                              aria-label="toggle password visibility"
                              className={classes.adornmentButton}
                              onClick={(event) => updateAmount(event, +1)}
                              edge="end"
                              color="primary"
                              size="small"
                              disabled={startpopOrder.amount >= maxAmount}
                            >
                              <Add />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        event.preventDefault();
                        changeHandler(Number(event.target.value));
                      }}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Hidden xlDown>
                  {startpopOrder.validated_auto_vouchers.length > 0 && (
                    <Grid item xs textAlign="start">
                      <Typography variant="body2">
                        <Trans
                          i18nKey={
                            startpopOrder.validated_auto_vouchers.length > 1
                              ? "478-plural"
                              : "478-singular"
                          }
                          values={{
                            noOfValidatedVouchers:
                              startpopOrder.validated_auto_vouchers.length > 10
                                ? startpopOrder.validated_auto_vouchers.length
                                : displayNumbers[
                                    startpopOrder.validated_auto_vouchers.length
                                  ],
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}
                </Hidden>
              </Grid>
              <Hidden xlUp>
                {startpopOrder.validated_auto_vouchers.length > 0 && (
                  <Grid
                    container
                    pt={2}
                    justifyContent={{ xs: "space-evenly", sm: "end" }}
                  >
                    <Hidden smDown>
                      <Grid container item xs={4}>
                        &nbsp;
                      </Grid>
                    </Hidden>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={0}
                      display="flex"
                      justifyContent="end"
                      // maxWidth={279.28}
                      style={{ maxWidth: 279.28 }}
                    >
                      <Grid
                        item
                        xs
                        sm
                        textAlign={{ xs: "center", sm: "start" }}
                      >
                        <Typography variant="body2">
                          <Trans
                            i18nKey={
                              startpopOrder.validated_auto_vouchers.length > 1
                                ? "478-plural"
                                : "478-singular"
                            }
                            values={{
                              noOfValidatedVouchers:
                                startpopOrder.validated_auto_vouchers.length >
                                10
                                  ? startpopOrder.validated_auto_vouchers.length
                                  : displayNumbers[
                                      startpopOrder.validated_auto_vouchers
                                        .length
                                    ],
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Hidden>
              {settings.price_is_suggestion && (
                <Box my={2}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6">
                        {t(displayedText.makePrice, {
                          currency: settings.currency,
                          price: settings.shopifyPrice,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="price"
                        type="number"
                        className={classes.textfield}
                        variant="outlined"
                        name="price"
                        inputRef={priceRef}
                        defaultValue={
                          displayedPrice
                            ? Number(displayedPrice).toFixed(2)
                            : ""
                        }
                        onChange={(e) => {
                          e.preventDefault();
                          let newPrice = e.target.value;
                          setShowMinimumPriceError(false);
                          if (!e.target.value) {
                            setShowMinimumPriceError(true);
                            updatedValue.current = e.target.value;
                          } else {
                            newPrice = Math.round(Number(e.target.value));
                            if (newPrice < minimumPrice) {
                              setShowMinimumPriceError(true);
                            } else if (newPrice > 99) {
                              newPrice = 99;
                            }
                            updatedValue.current = Number(newPrice).toFixed(2);
                          }
                          updatePrice(newPrice);
                        }}
                        onBlur={(e) => {
                          e.preventDefault();
                          if (e.target.value) {
                            e.target.value = Number(
                              Math.round(Number(e.target.value))
                            ).toFixed(2);
                          }
                        }}
                        onFocus={(e) => {
                          if (!e.target.value) {
                            const price = Number(
                              displayedPrice
                                ? displayedPrice
                                : settings.shopifyPrice
                            ).toFixed(2);
                            e.target.value = price;
                            updatePrice(price);
                          }
                        }}
                        placeholder={parseFloat(settings.shopifyPrice)
                          .toFixed(2)
                          .toString()}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {settings.currency}
                              <Divider
                                orientation="vertical"
                                className={classes.divider}
                              />
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                            inputMode: "decimal",
                            onKeyPress: (event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                submitRef.current.focus();
                              }
                            },
                          },
                        }}
                        helperText={
                          displayError.price
                            ? displayError.price
                            : showMinimumPriceError
                            ? t("429", {
                                currency: settings.currency,
                                price: minimumPrice,
                              })
                            : "(" +
                              t("430", {
                                currency: settings.currency,
                                minimumPrice: minimumPrice,
                              }) +
                              ")"
                        }
                        error={
                          displayError.price || showMinimumPriceError
                            ? true
                            : false
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {t(displayedText.otherPeopleGave, {
                          currency: settings.currency,
                          price: settings.shopifyPrice,
                          minimumPrice: minimumPrice,
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {/*<Box mt={4} hidden={settings.osmiaTypeSeason === "cornuta"}>*/}
              <Box mt={4} hidden={settings.osmiaTypeSeason === "cornuta"}>
                <WBPInfoBox
                  customColor={themeOptions.palette.primary.main}
                  customPadding="8px 32px"
                  roundedBorderRadius
                >
                  <HeaderTextDropdowns translationId="479" />
                </WBPInfoBox>
              </Box>
              <Box mt={4} hidden={settings.osmiaTypeSeason === "bicornis"}>
                <WBPInfoBox customPadding="0px 32px" customColor="transparent">
                  <Trans
                    i18nKey={"407"}
                    components={{
                      header: <Typography variant="h6" />,
                      ul: <ul className="bullets" />,
                      li: <li />,
                    }}
                  />
                </WBPInfoBox>
              </Box>
              <Box mb={4}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid>
                    <Button
                      id="button-startpopsmore-back"
                      variant="outlined"
                      color="primary"
                      onClick={history.goBack}
                      startIcon={<ChevronLeft />}
                    >
                      {t("404")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="button-startpopsmore-submit"
                      variant="contained"
                      type="submit"
                      ref={submitRef}
                      color="primary"
                      endIcon={<ChevronRight />}
                      //price field has a value there are item in the order and tubes_count field has a value
                      disabled={
                        displayError.tubes_count ||
                        displayError.price ||
                        showMinimumPriceError === true
                          ? true
                          : false
                      }
                      disableElevation
                    >
                      {t("406")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </WBPPage>
        ) : (
          <WBPPage title={t("224")}>
            <WBPInfoBox>
              <Trans i18nKey="225" />
            </WBPInfoBox>
            <Box mb={4}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid>
                  <Button
                    id="button-startpopsmore-back"
                    variant="outlined"
                    color="primary"
                    onClick={history.goBack}
                    startIcon={<ChevronLeft />}
                  >
                    {t("404")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="button-startpopsmore-next"
                    variant="contained"
                    onClick={nextPage}
                    color="primary"
                    endIcon={<ChevronRight />}
                    //price field has a value there are item in the order and tubes_count field has a value
                    disabled={startpopOrder.amount < 1}
                    disableElevation
                  >
                    {t("406")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </WBPPage>
        ))}
      <CornutaWarningModal
        warningTextObject={warningTextObject[warningId]}
        open={warningModalOpenStatus}
        onClose={() => {
          setWarningModalOpenStatus(false);
        }}
      />
    </StartpopOrderSideBar>
  );
};

export default Startpops;
