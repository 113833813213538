import React from "react";
import { Typography, Box } from "@mui/material";

export default function WBPAddressText({ address }) {
  let name = "";
  if (address) {
    name = (
      (address.first_name || "") +
      " " +
      (address.last_name || "")
    ).trim();
  }
  return (
    <Box py={1}>
      {address ? (
        <Typography variant="body1">
          {name}
          {name && address.company_name ? <br /> : ""}
          {address.company_name}
          <br />
          {address.address_1}
          <br />
          {address.postal_code + " " + address.town}
        </Typography>
      ) : (
        <span>-</span>
      )}
    </Box>
  );
}
