import React, { useContext, useState, useEffect, Fragment } from "react";
import history from "../services/history";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  OutlinedInput,
  FormControl,
  FormHelperText,
  IconButton,
  Popover,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import API from "../services/api";
import { StartpopSettingsContext } from "../contexts/SettingsContext";
import { useMount } from "react-use";
import {
  ArrowForwardIosOutlined,
  Delete,
  HelpOutline,
} from "@mui/icons-material";
import StartpopOrderSideBar from "./StartpopOrderSideBar";
import WBPPage from "./sub-components/WBPPage";
import UserContext from "../contexts/UserContext";
import UIContext from "../contexts/UIContext";
import DonationSummary from "./sub-components/DonationSummary";
import Client from "shopify-buy";
import references from "../services/references";
import CornutaWarningModal from "./modals/CornutaWarningModal";
import StartpopOrderContext from "../contexts/StartpopOrderContext";
import {
  DonationSelector,
  SummaryAddress,
} from "./sub-components/SummaryComponents";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  formRoot: { width: "100%" },
  btnSize: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "10px",
      width: "100%",
    },
  },
  padless: {
    padding: 7,
  },
  countColumn: {
    textAlign: "center",
  },
  itemColumn: {},
  priceColumn: {
    textAlign: "end",
  },
  deleteColumn: {
    textAlign: "end",
  },
  mobiWidth: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      fontSize: theme.typography.h5.fontSize,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  divider: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  deleteIconButton: {
    padding: 0,
  },
}));

const StartpopOrderSummary = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [voucherCode, setVoucherCode] = useState("");
  const [user] = useContext(UserContext);
  const [, , loader] = useContext(UIContext);
  const [voucherError, setVoucherError] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(references.defaultOrders.beecare.price);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const settings = useContext(StartpopSettingsContext);
  const [acceptedWarning, setAcceptedWarning] = useState(false);
  const [warningModalOpenStatus, setWarningModalOpenStatus] = useState(false);
  const [startpopOrder, setStartpopOrder] = useContext(StartpopOrderContext);
  const warningTexts = references.defaultOrders.startpop.warningTexts;
  const titleTranslations = startpopOrder.titleTranslations;

  // Initializing a client to return content in the store's primary language
  const shopifyClient = Client.buildClient({
    domain: new URL(
      JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[i18n.language]
    ).host,
    storefrontAccessToken: JSON.parse(
      process.env.REACT_APP_SHOPIFY_ACCESSTOKEN
    )[i18n.language],
    language: i18n.language,
  });

  useMount(() => {
    if (!startpopOrder.amount) {
      history.push("/startpops");
    }
  });

  useEffect(() => {
    setCurrency(settings ? settings.currency : "");
  }, [settings.currency]);

  useEffect(() => {
    if (startpopOrder.price) {
      setPrice(Number(startpopOrder.price));
    }
  }, [startpopOrder.variant]);

  const sendData = () => {
    loader.addTask("ORDER");
    loader.addTask("CHECKOUT");
    shopifyClient.checkout
      .create()
      .then((checkout) => {
        const input = {
          customAttributes: [
            {
              key: "deliveryDate",
              value: moment
                .parseZone(startpopOrder.delivery_on)
                .format("DD-MM-YYYY"),
            },
            {
              key: "seasonType",
              value: "startpops",
            },
          ],
        };

        shopifyClient.checkout
          .updateAttributes(checkout.id, input)
          .then((checkout) => {
            const selectedAddress = user.addresses.find(
              (address) => address.id === startpopOrder.address_id
            );
            const shippingAddress = {
              address1: selectedAddress.address_1,
              address2: selectedAddress.address_2,
              city: selectedAddress.town,
              company: selectedAddress.company_name,
              country: selectedAddress.country_code,
              firstName: selectedAddress.first_name,
              lastName: selectedAddress.last_name,
              phone: null,
              province: null,
              zip: selectedAddress.postal_code,
            };

            shopifyClient.checkout
              .updateShippingAddress(checkout.id, shippingAddress)
              .then((checkout) => {
                const lineItemsToAdd = [];
                const quantity =
                  Number(startpopOrder.amount) -
                  startpopOrder.discount_vouchers.length;
                lineItemsToAdd.push({
                  variantId: startpopOrder.variant.id,
                  quantity: quantity,
                });
                const vouchersCustomAttributes = [
                  {
                    key: "vouchers",
                    value: startpopOrder.discount_vouchers
                      .map((voucher) => voucher.code)
                      .join(", "),
                  },
                ];

                lineItemsToAdd.push({
                  variantId: settings.shopify.products.variants.find(
                    (variant) => {
                      let freePrice = variant.price;
                      if (typeof freePrice === "object" && freePrice.amount) {
                        freePrice = freePrice.amount;
                      }
                      return Number(freePrice) === 0 ? true : false;
                    }
                  ).id,
                  quantity: startpopOrder.discount_vouchers.length,
                  customAttributes: vouchersCustomAttributes,
                });

                if (startpopOrder.donation > 0) {
                  lineItemsToAdd.push({
                    variantId: settings.shopify.donationOptions.variants.find(
                      (variant) =>
                        variant.sku === "donation-" + startpopOrder.donation
                    ).id,
                    quantity: 1,
                  });
                }

                shopifyClient.checkout
                  .addLineItems(
                    checkout.id,
                    lineItemsToAdd.filter((item) => item.quantity > 0)
                  )
                  .then((checkout) => {
                    let checkoutURL = checkout.webUrl;
                    if (i18n.language === "fr-CH") {
                      checkoutURL += "&locale=fr";
                    }
                    gotoShopifyCheckout(checkoutURL);
                  })
                  .catch(shopifyError);
              })
              .catch(shopifyError);
          })
          .catch(shopifyError);
      })
      .catch(shopifyError);
    null;
  };

  const gotoShopifyCheckout = (checkoutURL) => {
    API.get(`me/shopify-login?return_to=` + checkoutURL)
      .then((response) => {
        loader.clearTask("CHECKOUT");
        window.open(response.data.url, "_self");
      })
      .catch(shopifyError);
  };

  const shopifyError = (error) => {
    loader.clearTask("CHECKOUT");
    console.warn(error);
  };

  const calculateTotals = (donation = 0, discount_vouchers = 0) => {
    const totalPrice =
      settings && startpopOrder.amount
        ? startpopOrder.amount * price -
          discount_vouchers.length * price +
          donation
        : 0;
    setStartpopOrder((oldOrder) => ({
      ...oldOrder,
      total_price: totalPrice,
    }));
  };

  const deleteAllDiscounts = (event) => {
    event.preventDefault();
    setStartpopOrder((oldOrder) => ({
      ...oldOrder,
      discount_vouchers: oldOrder.validated_auto_vouchers,
    }));
    calculateTotals(
      startpopOrder.donation,
      startpopOrder.validated_auto_vouchers
    );
  };

  // toggleDonations is what we use to differentiate toggleable donation buttons from the custom donation
  const submitDonation = (event, donationValue = 0, toggleDonation = false) => {
    event.preventDefault();
    const newDonation =
      toggleDonation && startpopOrder.donation === donationValue
        ? 0
        : donationValue;
    setStartpopOrder((oldOrder) => ({
      ...oldOrder,
      donation: newDonation,
    }));
    calculateTotals(newDonation, startpopOrder.discount_vouchers);
  };

  const addVoucherHandler = (event) => {
    event.preventDefault();
    setVoucherError("");
    setVoucherCode(event.target.value.toUpperCase().trim());
  };

  const submitVoucherCode = (event) => {
    event.preventDefault();
    setVoucherError("");
    if (voucherCode.toUpperCase().indexOf("FREECARE") < 0) {
      if (
        !startpopOrder.discount_vouchers.some(
          (voucher) => voucher.code === voucherCode
        )
      ) {
        loader.addTask("VOUCHER");
        const voucherType =
          settings.osmiaTypeSeason === "cornuta"
            ? "STARTPOP_OC"
            : settings.osmiaTypeSeason === "bicornis"
            ? "STARTPOP_BC"
            : "STARTPOP";
        API.post(`me/vouchers`, { code: voucherCode, type: voucherType })
          .then((res) => {
            loader.clearTask("VOUCHER");
            const discount_vouchers = [...startpopOrder.discount_vouchers];
            discount_vouchers.push(res.data);
            setVoucherCode("");
            setStartpopOrder((oldOrder) => ({
              ...oldOrder,
              discount_vouchers: discount_vouchers,
            }));
            calculateTotals(startpopOrder.donation, discount_vouchers);
          })
          .catch((error) => {
            loader.clearTask("VOUCHER");
            console.warn(error);
            let errorText = error.response.data.detail;
            switch (error.response.data.status) {
              case 403:
                errorText = "495";
                break;
              case 400:
                if (errorText === "Invalid code") {
                  errorText = "501";
                } else if (errorText === "Invalid Beecare code") {
                  errorText = "502";
                } else if (errorText === "Voucher is for STARTPOP_OC") {
                  errorText = "504";
                } else if (errorText === "Voucher is for STARTPOP_BC") {
                  errorText = "503";
                }
                break;
            }
            setVoucherError(t(errorText));
          });
      } else {
        loader.clearTask("VOUCHER");
        setVoucherError(t("Voucher code has already been entered"));
      }
    } else {
      loader.clearTask("VOUCHER");
      setVoucherError(t("Invalid code"));
    }
  };
  function areSameDay(date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const today = new Date();
    if (
      startpopOrder.deliveryChosenOn &&
      !areSameDay(startpopOrder.deliveryChosenOn, today)
    ) {
      window.location.href = "/startpop";
      return;
    }
    if (acceptedWarning === false && settings.osmiaTypeSeason === "cornuta") {
      setWarningModalOpenStatus(true);
      return;
    }

    if (startpopOrder.order_date !== moment.utc().format("YYYY-MM-DD")) {
      location.reload();
    } else {
      sendData(Number(startpopOrder.total_price) === 0 ? true : false);
    }
  };

  return (
    <div>
      <StartpopOrderSideBar step={3} showSummary>
        <WBPPage
          title={t("490")}
          description={t("496", {
            voucherText:
              startpopOrder.discount_vouchers.length !==
                Number(startpopOrder.amount) &&
              settings.osmiaTypeSeason === "bicornis"
                ? t("485")
                : "",
          })}
        >
          <Box mt={-1} mb={2}>
            <Grid container alignItems="center">
              <SummaryAddress
                shippingAddressID={startpopOrder.address_id}
                billingHeader="486"
                alternativeHeader="486"
                showBillingAddress={false}
              />
              <Grid container direction="row" pt={1}>
                <Typography variant="h6">{t("Order summary")}</Typography>
              </Grid>
              <Grid container direction="row" pt={1} alignItems="flex-start">
                <Grid item xs={1} className={classes.countColumn}>
                  <Typography variant="body1">
                    {startpopOrder.amount}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.itemColumn}>
                  <Typography variant="body1">
                    <Trans i18nKey={titleTranslations.mainTitle} />
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {"(" +
                      t(
                        settings.osmiaTypeSeason === "bicornis"
                          ? "Osmia bicornis"
                          : "Osmia cornuta"
                      ) +
                      ")"}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.priceColumn}>
                  <Typography variant="body1">
                    {currency +
                      " " +
                      parseFloat(startpopOrder.amount * price).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.deleteColumn}>
                  &nbsp;
                </Grid>
              </Grid>

              <Grid container direction="row" py={1}>
                <Grid item xs={1} className={classes.countColumn}>
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.itemTextColumn}
                  alignSelf="center"
                ></Grid>
              </Grid>
              {startpopOrder.discount_vouchers.length > 0 && (
                <Grid
                  container
                  item
                  direction="row"
                  py={1}
                  alignItems="flex-start"
                >
                  <Grid item xs={1} className={classes.countColumn}>
                    <Typography variant="body1">
                      {startpopOrder.discount_vouchers.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} className={classes.itemColumn}>
                    <Typography variant="body1">
                      {titleTranslations.voucherTitle}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("489")}
                    </Typography>
                    {startpopOrder.discount_vouchers.map((voucher, key) => {
                      return (
                        <Typography
                          key={key}
                          variant="body2"
                          color="textSecondary"
                        >
                          {voucher.code}
                        </Typography>
                      );
                    })}
                  </Grid>
                  <Grid item xs={3} className={classes.priceColumn}>
                    <Typography variant="body1">
                      {"- " +
                        currency +
                        " " +
                        parseFloat(
                          startpopOrder.discount_vouchers.length * price
                        ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.deleteColumn}>
                    {startpopOrder.discount_vouchers.filter(
                      (voucher) => voucher.client_id === null
                    ).length > 0 && (
                      <IconButton
                        className={classes.deleteIconButton}
                        onClick={deleteAllDiscounts}
                        size="small"
                      >
                        <Delete></Delete>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
              {startpopOrder.donation > 0 && (
                <Grid container direction="row" py={1} alignItems="center">
                  <Grid item xs={1} className={classes.countColumn}>
                    <Typography variant="body1">1</Typography>
                  </Grid>
                  <Grid item xs={7} className={classes.itemColumn}>
                    <Typography variant="body1">
                      {titleTranslations.donationTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.priceColumn}>
                    <Typography variant="body1">
                      {currency +
                        " " +
                        Number(startpopOrder.donation).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.deleteColumn}>
                    <IconButton
                      className={classes.deleteIconButton}
                      onClick={submitDonation}
                      size="small"
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              <Divider className={classes.divider} />
              <Grid container direction="row" py={1}>
                <Grid item xs={1} className={classes.countColumn}>
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={classes.itemTextColumn}
                  alignSelf="center"
                >
                  <Typography variant="body1">{t("Total incl VAT")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={classes.priceColumn}
                  alignSelf="center"
                >
                  <Typography className={classes.totalPrice}>
                    {currency +
                      " " +
                      Number(startpopOrder.total_price).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <form
                onSubmit={submitVoucherCode}
                className={classes.formRoot}
                hidden={
                  Number(startpopOrder.amount) <=
                    startpopOrder.discount_vouchers.length ||
                  settings.osmiaTypeSeason === "cornuta"
                }
              >
                <Grid
                  container
                  direction="row"
                  xs={12}
                  pt={1}
                  spacing={{ xs: 1 }}
                  justifyContent={{ xs: "flex-start", sm: "center" }}
                  alignItems={"flex-start"}
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.itemColumn}
                    alignSelf={{ xs: "center", sm: "flex-start" }}
                  >
                    <Typography variant="body1">
                      {t("488")}
                      <Tooltip
                        title={
                          <h3>
                            <Trans i18nKey="500" />
                          </h3>
                        }
                        arrow
                      >
                        <IconButton
                          edge="end"
                          aria-label="info"
                          color="primary"
                          size="small"
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.priceColumn}>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="ticket_code"
                        variant="outlined"
                        name="ticket_code"
                        onChange={addVoucherHandler}
                        placeholder={t("489")}
                        value={voucherCode}
                        fullWidth
                        error={voucherError ? true : false}
                        classes={{ input: classes.padless }}
                      />
                      <FormHelperText
                        error={
                          voucherError && voucherError !== "" ? true : false
                        }
                      >
                        {voucherError && voucherError !== ""
                          ? voucherError
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.deleteColumn}>
                    <Button
                      id="button-sendinboxstep3-addvoucher"
                      variant="contained"
                      color="primary"
                      type="submit"
                      classes={{ input: classes.padless }}
                      disableElevation
                      fullWidth
                    >
                      {t("106")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Box>
          <form>
            <Box py={2}>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12} sm={3} md={5}>
                  <Typography variant="body1">
                    {t("Support us")}
                    {isMobile && (
                      <>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="primary"
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                          }}
                          size="large"
                        >
                          <HelpOutline />
                        </IconButton>
                        <Popover
                          id={popoverId}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                          onClick={() => {
                            setAnchorEl(null);
                          }}
                          anchorReference="anchorPosition"
                          anchorPosition={{ top: 25, left: 400 }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <DonationSummary showClose />
                        </Popover>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction={"column"}
                  xs={12}
                  sm={9}
                  md={7}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <DonationSelector
                      currency={currency}
                      selectedDonation={startpopOrder.donation}
                      submitDonation={submitDonation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
          <Box my={4}>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={5}>
                <Button
                  id="button-sendinboxstep3-back"
                  className={`${classes.btnSize} ${classes.mobiWidth}`}
                  color="primary"
                  onClick={history.goBack}
                  variant="outlined"
                >
                  {t("404")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  id="button-sendinboxstep3-submitwithpayment"
                  className={`${classes.btnSize} ${classes.mobiWidth}`}
                  color="primary"
                  onClick={submitHandler}
                  variant="contained"
                  disableElevation
                >
                  {t("349")}
                  <ArrowForwardIosOutlined fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </WBPPage>
      </StartpopOrderSideBar>
      <CornutaWarningModal
        warningTextObject={warningTexts}
        osmiaType={
          settings.osmiaTypeSeason === "cornuta"
            ? "Osmia cornuta"
            : "Osmia bicornis"
        }
        changeAcceptValue={() => {
          setAcceptedWarning(true);
        }}
        open={warningModalOpenStatus}
        onClose={() => {
          setWarningModalOpenStatus(false);
        }}
      />
    </div>
  );
};

export default StartpopOrderSummary;
