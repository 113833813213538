import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: {
      "de-CH": ["de-DE", "de"],
      "de-DE": ["de-CH", "de"],
      "de-AT": ["de-AT", "de"],
      "fr-CH": ["fr-FR", "fr"],
      "fr-FR": ["fr-CH", "fr"],
      fr: ["de", "fr-FR", "fr-CH"],
      de: ["fr", "de-CH", "de-FR", "de-AT"],
      en: ["de", "de-CH", "de-DE", "de-AT"],

      //these 2 translations don't exist and must fallback to their respective language and country_code CH
      "de-FR": ["de-CH", "de"],
      "fr-DE": ["fr-CH", "fr"],

      default: ["de-CH"],
    },
    supportedLngs: ["de-CH", "de-DE", "de-AT", "fr-CH", "fr-FR", "de", "fr"],
    nonExplicitSupportedLngs: true,
    // fallbackLng: 'de',
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
