import React, { useCallback, useContext, useEffect } from "react";
import { Route } from "react-router-dom";

import "firebase/auth";
import AuthContext from "../contexts/AuthContext";
import SettingsContext from "../contexts/SettingsContext";
import history from "../services/history";
import UserContext from "../contexts/UserContext";

const ProtectedRoute = ({
  onlyInStartpopSeason,
  onlyInBeecareSeason,
  housesCheck,
  checkClosed,
  checkStartpopSoldOut,
  component: Component,
  ...rest
}) => {
  const { authData, setProtect } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const [user] = useContext(UserContext);
  const protectionCheck = useCallback(() => {
    if (
      checkClosed &&
      user.billing_contact &&
      user.billing_contact.organisation_id === 8
    ) {
      history.push("/closed");
      return;
    }

    if (settings.startpop && onlyInStartpopSeason) {
      if (user.country_id === 4) {
        history.push({
          pathname: "/outofseason",
          state: {
            section: "startpop",
            status: "austriaOutOfSeason",
          },
        });
        return;
      } else if (settings.startpop.status !== "inSeason") {
        history.push({
          pathname: "/outofseason",
          state: {
            section: "startpop",
            status: settings.startpop.status,
          },
        });
        return;
      } else if (
        checkStartpopSoldOut &&
        settings.startpop.max_units < 1 &&
        settings.startpop.osmiaTypeSeason === "cornuta"
      ) {
        history.push("/soldout");
        return;
      }
    }

    if (
      settings.beecare &&
      settings.beecare.status !== "inSeason" &&
      onlyInBeecareSeason
    ) {
      history.push({
        pathname: "/outofseason",
        state: {
          section: "beecare",
          status: settings.beecare.status,
        },
      });
      return;
    }

    if (!user.houses.length && housesCheck) {
      history.push({
        pathname: "/nohouses",
      });
      return;
    }

    return;
  }, [
    user.billing_contact,
    checkClosed,
    housesCheck,
    onlyInBeecareSeason,
    onlyInStartpopSeason,
    settings.beecare,
    settings.startpop,
    user.houses.length,
  ]);

  useEffect(() => {
    protectionCheck();

    setProtect(true);
    return () => {
      setProtect(false);
    };
  }, [
    onlyInStartpopSeason,
    onlyInBeecareSeason,
    setProtect,
    settings,
    user,
    protectionCheck,
  ]);

  return (
    <>
      {authData && (
        <Route
          {...rest}
          render={(props) => <Component {...rest} {...props} />}
        />
      )}
    </>
  );
};

export default ProtectedRoute;
