import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Paper, Grid, Hidden, Typography } from "@mui/material";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import UIContext from "../contexts/UIContext";
import { ShoppingCartOutlined, StoreOutlined } from "@mui/icons-material";
import AuthContext from "../contexts/AuthContext";
import UserContext from "../contexts/UserContext";
import BeehomeModal from "./modals/BeehomeModal";
import BeeHomeList from "./sub-components/BeeHomeList";
import {
  BeeCareSettingsContext,
  StartpopSettingsContext,
} from "../contexts/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  whiteLink: {
    fontFamily: "inherit",
    fontSize: "inherit",
    color: theme.palette.primary.contrastText,
  },
}));

export default function BeeHomes() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authData } = useContext(AuthContext);
  const [user, setUser, getUser] = useContext(UserContext);
  const [, setUIOptions, loader] = useContext(UIContext);
  const startpopSettings = useContext(StartpopSettingsContext);
  const beecareSettings = useContext(BeeCareSettingsContext);
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [nextStepButtonText, setNextStepButtonText] =
    useState("Order startpops");
  const [nextStepPath, setNextStepPath] = useState();

  useEffect(() => {
    if (startpopSettings && startpopSettings.status === "inSeason") {
      setNextStepPath({
        pathname: "/startpops",
        state: { from: history.location.pathname, data: user.houses },
      });
      setNextStepButtonText("Order startpops");
    } else if (beecareSettings && beecareSettings.status === "inSeason") {
      setNextStepPath("/sendinbox");
      setNextStepButtonText("Send in");
    }
  }, [startpopSettings, beecareSettings, user.houses]);

  const loadData = (displayLoader = true) => {
    if (!authData) {
      loader.clearTask("houses");
      return;
    }

    if (displayLoader) {
      loader.addTask("houses");
    }

    getUser().finally(() => {
      loader.clearTask("houses");
    });

    return () => {
      loader.clearTask("houses");
    };
  };
  useEffect(loadData, [setUIOptions, authData, setUser, loader]);

  const openNewBeeHome = () => {
    setModalOpenStatus(true);
  };
  const gotoNextStep = () => {
    history.push(nextStepPath);
  };

  const closeModal = () => {
    setModalOpenStatus(false);
    loadData(false);
  };

  return (
    <Box px={0} m={3} className={classes.root}>
      <BeehomeModal house={{}} open={modalOpenStatus} onClose={closeModal} />
      {/* Desktop version: */}
      <Hidden mdDown>
        <Paper elevation={0} square={true}>
          <Box p={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm>
                <Typography variant="h2">{t("40")}</Typography>
              </Grid>
              <Grid item>
                <Button
                  id="button-beehome-addabeehome"
                  variant="outlined"
                  color="primary"
                  onClick={openNewBeeHome}
                  className={classes.rightMargin}
                  startIcon={<StoreOutlined />}
                >
                  {t("Add a BeeHome")}
                </Button>
                {((startpopSettings &&
                  startpopSettings.status === "inSeason" &&
                  startpopSettings.osmiaTypeSeason !== null) ||
                  (beecareSettings &&
                    beecareSettings.status === "inSeason")) && (
                  <Button
                    id="button-beehome-gotonextstep"
                    variant="contained"
                    color="primary"
                    onClick={gotoNextStep}
                    disableElevation
                    startIcon={<ShoppingCartOutlined />}
                  >
                    {t(nextStepButtonText)}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Box hidden={!user.houses.length} mt={4}>
              <BeeHomeList rows={user.houses} reloadData={loadData} />{" "}
            </Box>
          </Box>
        </Paper>
      </Hidden>
      {/* Mobile version */}
      <Hidden mdUp>
        <Box hidden={!user.houses.length} align="center">
          <Typography variant="h2">{t("40")}</Typography>
        </Box>
        <Paper elevation={0} className="fullHeight" m={2}>
          <Box p={0}>
            <BeeHomeList rows={user.houses} reloadData={loadData} />
            <Box px={4} py={2}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                {((startpopSettings &&
                  startpopSettings.status === "inSeason") ||
                  (beecareSettings &&
                    beecareSettings.status === "inSeason")) && (
                  <Grid item xs={12}>
                    <Button
                      id="button-beehome-gotonextstep"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={gotoNextStep}
                      disableElevation
                      startIcon={<ShoppingCartOutlined />}
                    >
                      {t(nextStepButtonText)}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    id="button-beehome-addabeehome"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={openNewBeeHome}
                    className={classes.rightMargin}
                    startIcon={<StoreOutlined />}
                  >
                    {t("Add a BeeHome")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Hidden>
    </Box>
  );
}
