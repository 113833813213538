import {
  Grid,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";
import { VisibilityOutlined } from "@mui/icons-material";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiBadge-badge": {
      backgroundColor: theme.palette.background.default,
    },
  },
  homeImage: {
    maxWidth: 150,
  },
  imageHolder: {
    [theme.breakpoints.down("md")]: { marginTop: "24px" },
    cursor: "pointer",
    maxWidth: 150,
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
    position: "absolute",
    width: "150px",
    height: "150px",
  },
}));

export default function BeeCareStatusTubes({
  value,
  displayStepDetails,
  setModalOpenStatus,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [overlayStyle, setOverlayStyle] = useState({ display: "none" });
  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return value.beecare.step2 ? (
    <>
      <WBPBeeCareStatusInfoBox
        className={classes.root}
        selectedStep={value.beecare.selectedStep}
        displayStepDetails={changeSelectedStep}
        title={t("251")}
        secondary
      >
        <Grid container justifyContent={isMobile ? "center" : "space-between"}>
          <Grid item xs={12} md={value.beecare.snapshots.length > 0 ? 8 : 12}>
            <Typography variant="h6">{t("251")}</Typography>
            <Typography variant="body1" paragraph>
              <Trans
                i18nKey="253"
                components={{
                  lnk: (
                    <Link
                      href={t("Statistics Link")}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </Grid>
          {value.beecare.snapshots.length > 0 && (
            <Grid
              container
              item
              xs
              md
              justifyContent={isMobile ? "center" : "flex-end"}
              className={classes.imageHolder}
              onMouseEnter={() => {
                setOverlayStyle({ display: "flex" });
              }}
              onMouseLeave={() => {
                setOverlayStyle({ display: "none" });
              }}
            >
              <Grid
                container
                item
                direction="column"
                justifyContent="center"
                className={classes.overlay}
                style={overlayStyle}
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpenStatus(true);
                }}
              >
                <Grid container item direction="row" justifyContent="center">
                  <Fab size="small" aria-label="zoom" color="primary">
                    <VisibilityOutlined />
                  </Fab>
                </Grid>
              </Grid>

              <img
                alt="house snapshot"
                src={value.beecare.snapshots[0].replace(
                  "gs://",
                  process.env.REACT_APP_GOOGLE_STORAGE
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpenStatus(true);
                }}
                className={`${classes.homeImage}`}
              />
            </Grid>
          )}
        </Grid>
      </WBPBeeCareStatusInfoBox>
      {value.lastSeason.voucher_code !== null && (
        <WBPBeeCareStatusInfoBox
          selectedStep={value.beecare.selectedStep}
          displayStepDetails={changeSelectedStep}
          title={t("254")}
          secondary
        >
          <Grid container>
            <Grid item xs={12} md={8}>
              {t("255")}
            </Grid>
            <Grid
              container
              item
              xs
              md
              justifyContent={isMobile ? "flex-start" : "flex-end"}
              style={{ marginTop: isMobile ? "24px" : 0 }}
            >
              <Typography
                variant="button"
                color="textSecondary"
                align={isMobile ? "left" : "right"}
              >
                {t("Ticket")}:
                <br />
                <strong>
                  {value.lastSeason.voucher_code
                    ? value.lastSeason.voucher_code.toUpperCase()
                    : ""}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </WBPBeeCareStatusInfoBox>
      )}
    </>
  ) : (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      secondary
      title={t("259")}
    >
      <Trans
        i18nKey="260"
        components={{
          lnk: (
            <Link
              href={t("Statistics Link")}
              rel="noreferrer"
              target="_blank"
            />
          ),
        }}
      />
    </WBPBeeCareStatusInfoBox>
  );
}
