import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import themeOptions from "../../services/theme-default";

const useStyles = makeStyles(() => ({
  root: themeOptions.custom.Button.facebook,
  inner: themeOptions.custom.Button.inner,
}));

export default function FacebookLoginButton() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const labelText = t("Sign in with {{provider}}", {
    provider: "Facebook",
  });

  const loginWithFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      redirect_uri: process.env.REACT_APP_BASE_URL + "/loginverify",
    });

    firebase.auth().languageCode = i18n.language;
    firebase
      .auth()
      .signInWithRedirect(provider)
      .catch((e) => {
        console.warn(e.code);
      });
  };

  return (
    <Box
      id="button-facebookloginbutton"
      className={classes.root}
      px={4}
      py={2}
      border={1}
      onClick={loginWithFacebook}
    >
      <Grid
        id="button-facebookloginbutton"
        className={classes.inner}
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        spacing={0}
      >
        <Grid container justifyContent="flex-start" item xs={1}>
          <FacebookIcon
            id="button-facebookloginbutton"
            style={{ marginLeft: "-18px" }}
            size={24}
          />
        </Grid>
        <Grid item xs={10} zeroMinWidth>
          <Typography id="button-facebookloginbutton" color="inherit" noWrap>
            {labelText}
          </Typography>
        </Grid>
        <Grid item xs={1} zeroMinWidth>
          <Typography id="button-facebookloginbutton" color="inherit" noWrap>
            &nbsp;
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
