import API from "../services/api";
import { SettingsActionsContext } from "../contexts/SettingsContext";
import { useContext, useCallback } from "react";
import UIContext from "../contexts/UIContext";
import Client from "shopify-buy";
import { useTranslation } from "react-i18next";
import history from "../services/history";

const useSettings = () => {
  const [setSettings, setStartpopSettings, setBeeCareSettings] = useContext(
    SettingsActionsContext
  );
  const [ui, , loader] = useContext(UIContext);
  const { i18n } = useTranslation();

  const getSettings = useCallback(async () => {
    loader.addTask("settings");
    try {
      const response = await API.get(`me/settings`);
      if (response && response.data) {
        const settings = response.data;
        const startpop = settings.startpop;
        const beecare = settings.beecare;
        const today = new Date();

        i18n.language =
          ui.language +
          "-" +
          (settings.allowed_countries.indexOf("CH") > -1 ? "CH" : "DE");

        // Initializing a client to return content in the store's primary language
        const shopifyClient = Client.buildClient({
          domain: new URL(
            JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[i18n.language]
          ).host,
          storefrontAccessToken: JSON.parse(
            process.env.REACT_APP_SHOPIFY_ACCESSTOKEN
          )[i18n.language],
          language: i18n.language,
        });

        const donationProducts = await shopifyClient.product.fetchByHandle(
          "freiwilliger-beitrag"
        );

        if (startpop) {
          startpop.shopify = { products: {}, donationOptions: {} };
          const start_date = new Date(startpop.start_date);
          const end_date = new Date(startpop.end_date);
          startpop.status = "inSeason";
          if (today < start_date) {
            startpop.status = "preSeason";
          } else if (today > end_date) {
            startpop.status = "postSeason";
          }

          startpop.shopify.donationOptions = {
            title: donationProducts.title,
            image: donationProducts.images[0].src,
            variants: donationProducts.variants,
          };

          startpop.osmiaTypeSeason = null;
          const bicornis_start_date = new Date(startpop.bicornis_start_date);
          let startpopHandle = "startpopulation";
          if (today >= start_date && today < bicornis_start_date) {
            startpop.osmiaTypeSeason = "cornuta";
            startpopHandle = "startpopulation-osmia-cornuta";
          } else if (today >= bicornis_start_date && today < end_date) {
            startpop.osmiaTypeSeason = "bicornis";
          }

          const startpopProduct = await shopifyClient.product.fetchByHandle(
            startpopHandle
          );
          startpop.shopify.products = {
            title: startpopProduct.title,
            image:
              startpopProduct.images && startpopProduct.images.length > 0
                ? startpopProduct.images[0].src
                : "/images/startpopulation.png", //startpopulation.png",
            variants: startpopProduct.variants,
          };

          startpop.shopify.variant = startpopProduct.variants.find(
            (compareVariant) => {
              let compareVariantPrice = compareVariant.price;
              if (
                typeof compareVariantPrice === "object" &&
                compareVariantPrice.amount
              ) {
                compareVariantPrice = compareVariantPrice.amount;
              }
              if (Number(compareVariantPrice) !== 0) {
                startpop.shopifyPrice = Number(compareVariantPrice);
                startpop.shopifyPrice = Number(compareVariantPrice);
                return true;
              }
              return false;
            }
          );
        }

        if (beecare) {
          beecare.shopify = { products: {}, donationOptions: {} };
          beecare.status = "inSeason";
          if (today < new Date(beecare.start_date)) {
            beecare.status = "preSeason";
          } else if (today > new Date(beecare.end_date)) {
            beecare.status = "postSeason";
          }
          beecare.shopify.donationOptions = {
            title: donationProducts.title,
            image: donationProducts.images[0].src,
            variants: donationProducts.variants,
          };

          const beecareProduct = await shopifyClient.product.fetchByHandle(
            "beehome-beecare"
          );
          beecare.shopify.products = {
            title: beecareProduct.title,
            image:
              beecareProduct.images && beecareProduct.images.length > 0
                ? beecareProduct.images[0].src
                : "/images/houses/sendinbox/beehome_one.png", //startpopulation.png",
            variants: beecareProduct.variants,
          };
          beecare.shopifyPrice = Number(beecareProduct.variants[0].price);
        }

        startpop.price_is_suggestion = false;
        setSettings(settings);
        setStartpopSettings(startpop);
        setBeeCareSettings(beecare);
        loader.clearTask("settings");
        return settings;
      } else {
        loader.clearTask("settings");
        return { error: response.error };
      }
    } catch (error) {
      console.warn(error);
      history.push("/logout");
    } finally {
      loader.clearTask("settings");
    }
  }, [setSettings, setStartpopSettings, setBeeCareSettings, loader]);
  return { getSettings };
};

export default useSettings;
