import React from "react";
import {
  Box,
  Paper,
  Typography,
  Dialog,
  IconButton,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  imageIcon: {
    display: "inline-block",
    width: "100%",
    margin: "auto",
  },
}));

export default function ImageModal(propsData) {
  const classes = useStyles();

  const closeModal = (e) => {
    e.preventDefault();
    propsData.onClose(false);
  };

  return (
    <Dialog
      open={propsData.open}
      onClose={(e) => closeModal(e)}
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
    >
      <Paper className={classes.root}>
        <Box p={4}>
          <Typography variant="h3">
            <Grid container spacing={2}>
              <Grid container item xs justifyContent="flex-end">
                <IconButton
                  id="button-imagemodal-close"
                  size="small"
                  aria-label="zoom"
                  color="primary"
                  onClick={(e) => closeModal(e)}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Typography>
          <Box pt={4}>
            <img
              alt=""
              className={classes.imageIcon}
              src={propsData.imageSrc}
            />
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
