import React, { useState, useEffect, Fragment } from "react";
import { Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function NotificationBar(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open ? true : false);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.clearText();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={props.message}
        action={
          <Fragment>
            <IconButton
              id="button-notificationbar-close"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
    </div>
  );
}
