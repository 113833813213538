import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Box,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback, useContext, useEffect, useState } from "react";
import references from "../../services/references";
import UIContext from "../../contexts/UIContext";
import API from "../../services/api";
import SettingsContext from "../../contexts/SettingsContext";
import { makeStyles } from "@mui/styles";
import { LayoutContext } from "../../contexts/LayoutContext";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    maxHeight: "calc( 100vh - 100px )",
    display: "block",
  },
}));

export default function AddressModal(propsData) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [townList, setTownList] = useState([]);
  const setNotificationMessage = useContext(LayoutContext);
  const [dirtyFields, setDirtyFields] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [postalCodeLength, setPostalCodeLength] = useState(4);
  const [pageTitle] = useState("New Address");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, , loader] = useContext(UIContext);
  const settings = useContext(SettingsContext);
  const [postalCodeRegEx, setPostalCodeRegEx] = useState(
    references.allowedRegex.postalCode
  );
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [addressError, setAddressError] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [address, setAddress] = useState({
    company_name: "",
    address_1: "",
    first_name: "",
    last_name: "",
    country_code: "",
    postal_code: "",
    town: "",
  });

  useEffect(() => {
    /// TODO Reabetsoe - Austria
    let countryCode = i18n.language.substring(3, 5);
    setDefaultCountry(countryCode);
    setAddress((prevAddress) => ({
      ...prevAddress,
      country_code: countryCode,
    }));
  }, [propsData.organisationId]);

  useEffect(() => {
    if (propsData.address) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        ...propsData.address,
        company_name: propsData.address.company_name
          ? propsData.address.company_name
          : "",
        first_name: propsData.address.first_name
          ? propsData.address.first_name
          : "",
        last_name: propsData.address.last_name
          ? propsData.address.last_name
          : "",
      }));
    }
  }, [propsData.address]);
  const validateField = useCallback(
    (fieldTobeValidated, validationField = "standard") => {
      let valid = false;
      if (
        references.allowedRegex[validationField].test(
          address[fieldTobeValidated]
        )
      ) {
        valid = true;

        const prevErrorFields = { ...errorFields };
        delete prevErrorFields[fieldTobeValidated];
        setErrorFields(prevErrorFields);
      } else {
        setErrorFields((previousErrors) => ({
          ...previousErrors,
          /// TODO: Reabetsoe this error should say something link "Please enter a valid {{field}}"
          [fieldTobeValidated]: t("This field is compulsory"),
        }));
      }
      return valid;
    },
    [address, errorFields, t]
  );

  useEffect(() => {
    if (address.town.trim()) {
      validateField("town");
    }
  }, [address.town]);

  function regexFromString(string) {
    var match = /^\/(.*)\/([a-z]*)$/.exec(string);
    return new RegExp(match[1], match[2]);
  }

  useEffect(() => {
    for (let i in references.countries) {
      const country = references.countries[i];
      if (country.code === address.country_code) {
        setPostalCodeLength(country.length);
        const regexString = "/^[0-9]{" + country.length + "}$/";
        setPostalCodeRegEx(regexFromString(regexString));
        break;
      }
    }
  }, [address.country_code]);

  const updateTownList = (fieldValue, field) => {
    loader.addTask("TOWNS");
    setDisableSubmit(false);
    let params = { [field]: fieldValue };
    if (field === "country_code") {
      params.postal_code = address.postal_code;
    } else {
      params.country_code = address.country_code;
    }
    API.get(`me/towns`, {
      params: params,
    })
      .then((data) => {
        loader.clearTask("TOWNS");
        const prevErrorFields = { ...errorFields };
        delete prevErrorFields.town;
        delete prevErrorFields.postal_code;
        setErrorFields(prevErrorFields);
        const towns = [...new Set(data.data.map((item) => item.town))];
        setTownList(towns);
        if (towns.length === 1) {
          setAddress((previousAddress) => ({
            ...previousAddress,
            ...towns[0],
          }));
        }
      })
      .catch((error) => {
        loader.clearTask("TOWNS");
        setAddress((prevAddress) => ({ ...prevAddress, town: "" }));
        if (error.response.data.status) {
          if (error.response.data.status === 400) {
            setErrorFields((previousErrors) => ({
              ...previousErrors,
              postal_code: t("Postal code {postal_code} is excluded / invalid"),
            }));
          } else {
            setErrorFields((previousErrors) => ({
              ...previousErrors,
              postal_code: error.response.data.detail,
            }));
          }
        }
      });
  };

  const changeHandler = (e) => {
    let countryCode =
      e.target.name === "country_code" ? e.target.value : address.country_code;
    if (
      !postalCodeHelperText().hasError &&
      !references.allowedRegex.streetNumber.test(address.address_1) &&
      !references.allowedRegex.address1[countryCode].test(address.address_1)
    ) {
      setDisableSubmit(false);
    }
    setDirtyFields([...dirtyFields, e.target.name]);
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (Object.keys(errorFields).length === 0) {
      if (postalCodeHelperText().hasError || addressError !== "") {
        setDisableSubmit(true);
      } else {
        setIsSubmitting(true);
        let data = {
          company_name: address.company_name,
          address_1: address.address_1,
          country_code: address.country_code,
          first_name: address.first_name,
          last_name: address.last_name,
          postal_code: address.postal_code,
          town: address.town,
        };

        if (!address.company_name) {
          data.company_name = null;
        }
        if (!address.first_name) {
          data.first_name = null;
        }
        if (!address.last_name) {
          data.last_name = null;
        }

        if (propsData.address.tags) {
          data.tags = propsData.address.tags;
        }

        loader.addTask("SAVE_ADDRESS");
        if (propsData.address.id) {
          API.put(`me/addresses/` + propsData.address.id, data)
            .then(() => {
              loader.clearTask("SAVE_ADDRESS");
              closeModal();
            })
            .catch((error) => {
              loader.clearTask("SAVE_ADDRESS");
              setNotificationMessage(t(error.response.data.detail));
            });
        } else {
          API.post(`me/addresses`, data)
            .then(() => {
              loader.clearTask("SAVE_ADDRESS");
              closeModal();
            })
            .catch((error) => {
              loader.clearTask("SAVE_ADDRESS");
              setNotificationMessage(t(error.response.data.detail));
            });
        }
      }
    }
  };

  const validateStreet = () => {
    let valid = false;
    if (
      references.allowedRegex.streetNumber.test(address.address_1) &&
      references.allowedRegex.address1[address.country_code].test(
        address.address_1
      )
    ) {
      valid = true;
      setAddressError("");
    } else {
      setAddressError(t("This field is compulsory"));
    }
    return valid;
  };

  const closeModal = () => {
    clearFields();
    propsData.onClose();
  };

  const clearFields = () => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      ...{
        company_name: "",
        address_1: "",
        first_name: "",
        last_name: "",
        country_code: defaultCountry,
        postal_code: "",
        town: "",
      },
    }));
    setDirtyFields([]);
    setTownList([]);
    setErrorFields({});
    setAddressError("");
    setIsSubmitting(false);
  };

  const updateTown = (newTown) => {
    setAddress({ ...address, town: newTown });
    setDirtyFields([...dirtyFields, "town"]);
  };

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const postalCodeHelperText = () => {
    let txt = "";
    let hasError = true;
    if (address.postal_code === "") {
      txt = t("This field is compulsory");
    } else if (!postalCodeRegEx.test(address.postal_code)) {
      txt = t("Postal code {postal_code} is excluded / invalid");
    } else if (errorFields.postal_code) {
      txt = t(errorFields.postal_code);
    } else {
      hasError = false;
    }
    return { text: txt, hasError: hasError };
  };

  return (
    <Dialog
      open={propsData.open}
      onClose={closeModal}
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <Paper className={classes.root}>
        <Box p={isMobile ? 2 : 4} pb={isMobile ? 10 : 4}>
          <form onSubmit={!isSubmitting ? submitHandler : undefined}>
            <Box mb={2}>
              <Typography variant="h3">{t(pageTitle)}</Typography>
            </Box>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <FormControl className="select" fullWidth>
                  <InputLabel id="country-code-label">
                    {t("Country")}
                  </InputLabel>
                  <Select
                    name="country_code"
                    variant="outlined"
                    labelId="country-code-label"
                    label={t("Country")}
                    id="country_code"
                    value={address.country_code}
                    onChange={(event) => {
                      event.preventDefault();
                      changeHandler(event);
                      const country_code = event.target.value;
                      if (
                        address.postal_code &&
                        address.postal_code.length > 3 &&
                        country_code
                      ) {
                        updateTownList(country_code, "country_code");
                      }
                    }}
                    error={
                      dirtyFields.includes("postal_code") &&
                      disableSubmit &&
                      postalCodeHelperText().hasError
                    }
                  >
                    {references.countries.map(
                      (country) =>
                        settings.allowed_countries &&
                        settings.allowed_countries.includes(country.code) && (
                          <MenuItem key={country.code} value={country.code}>
                            {t(country.country)}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="first_name"
                  label={t("First Name")}
                  variant="outlined"
                  name="first_name"
                  hinttext={t("First Name")}
                  floatinglabeltext="FirstName"
                  onChange={changeHandler}
                  onBlur={(e) => {
                    if (e.target.value.trim()) {
                      validateField("first_name");
                    }
                  }}
                  value={address.first_name}
                  placeholder={t("First Name")}
                  required={!address.company_name}
                  helperText={t(
                    !address.first_name &&
                      !address.company_name &&
                      dirtyFields.includes("first_name")
                      ? "This field is compulsory"
                      : "first_name" in errorFields
                      ? errorFields.first_name
                      : ""
                  )}
                  error={
                    (!address.first_name &&
                      !address.company_name &&
                      dirtyFields.includes("first_name")) ||
                    "first_name" in errorFields
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="last_name"
                  label={t("Last Name")}
                  variant="outlined"
                  name="last_name"
                  hinttext={t("Last Name")}
                  floatinglabeltext="LastName"
                  value={address.last_name}
                  onChange={changeHandler}
                  onBlur={(e) => {
                    if (e.target.value.trim()) {
                      validateField("last_name");
                    }
                  }}
                  placeholder={t("Last Name")}
                  required={!address.company_name}
                  helperText={t(
                    !address.last_name &&
                      !address.company_name &&
                      dirtyFields.includes("last_name")
                      ? "This field is compulsory"
                      : "last_name" in errorFields
                      ? errorFields.last_name
                      : ""
                  )}
                  error={
                    (!address.last_name &&
                      !address.company_name &&
                      dirtyFields.includes("last_name")) ||
                    "last_name" in errorFields
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="company_name"
                  label={t("Company")}
                  variant="outlined"
                  name="company_name"
                  hinttext={t("Company")}
                  floatinglabeltext="CompanyName"
                  value={address.company_name}
                  onChange={changeHandler}
                  onBlur={(e) => {
                    if (e.target.value.trim()) {
                      validateField("company_name");
                    }
                  }}
                  placeholder={t("Company")}
                  required={!address.first_name}
                  helperText={t(
                    !address.first_name &&
                      !address.company_name &&
                      dirtyFields.includes("company_name")
                      ? "This field is compulsory"
                      : "company_name" in errorFields
                      ? errorFields.company_name
                      : ""
                  )}
                  error={
                    (!address.first_name &&
                      !address.company_name &&
                      dirtyFields.includes("company_name")) ||
                    "company_name" in errorFields
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address_1"
                  label={t("Street/No")}
                  variant="outlined"
                  name="address_1"
                  hinttext={t("Adress")}
                  floatinglabeltext={t("Adress")}
                  onChange={changeHandler}
                  onBlur={validateStreet}
                  placeholder={t("Adress")}
                  value={address.address_1}
                  required={true}
                  error={addressError ? true : false}
                  helperText={addressError}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="postal_code"
                  label={t("Zip")}
                  variant="outlined"
                  inputProps={{ maxLength: postalCodeLength }}
                  onInput={(e) => onlyNumbers(e)}
                  name="postal_code"
                  hinttext={t("Zip")}
                  floatinglabeltext={t("Zip")}
                  onChange={(event) => {
                    event.preventDefault();
                    changeHandler(event);
                    const postal_code = event.target.value;
                    if (
                      postal_code &&
                      postal_code.length > 3 &&
                      address.country_code
                    ) {
                      updateTownList(postal_code, "postal_code");
                    }
                  }}
                  onBlur={() => {
                    if (postalCodeHelperText().hasError) {
                      setDisableSubmit(true);
                    } else {
                      setDisableSubmit(false);
                    }
                  }}
                  placeholder={t("Zip")}
                  value={address.postal_code}
                  required={true}
                  helperText={
                    dirtyFields.includes("postal_code") &&
                    disableSubmit &&
                    postalCodeHelperText().text
                  }
                  error={
                    dirtyFields.includes("postal_code") &&
                    disableSubmit &&
                    postalCodeHelperText().hasError
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mt={-1}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="town"
                      freeSolo={true}
                      value={address.town}
                      defaultValue={() => {
                        return address.town;
                      }}
                      onInputChange={(event, newTown) => {
                        updateTown(newTown);
                      }}
                      options={townList.map((option) => option)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Town")}
                          margin="normal"
                          variant="outlined"
                          required={true}
                          helperText={t(
                            !address.town && dirtyFields.includes("town")
                              ? t("This field is compulsory")
                              : "town" in errorFields
                              ? errorFields.town
                              : ""
                          )}
                          error={
                            (!address.town && dirtyFields.includes("town")) ||
                            "town" in errorFields
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  id="button-addressmodal-cancel"
                  variant="outlined"
                  color="primary"
                  onClick={closeModal}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  id="button-addressmodal-submit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    ((!address.company_name &&
                      !address.first_name &&
                      !address.last_name) ||
                    (!address.company_name &&
                      address.first_name &&
                      !address.last_name) ||
                    (!address.company_name &&
                      !address.first_name &&
                      address.last_name) ||
                    !address.address_1 ||
                    !address.country_code ||
                    !address.town ||
                    addressError
                      ? true
                      : false || isSubmitting) ||
                    disableSubmit ||
                    Object.keys(errorFields).length > 0
                  }
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Dialog>
  );
}
