import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
  Link,
} from "@mui/material";
import { ThumbUpOutlined } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import history from "../services/history";
import WBPInfoBox from "./sub-components/WBPInfoBox";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    paddingBottom: "100%",
  },
  snacks: {
    flexGrow: 1,
  },
  scrollBox: {
    overflow: "scroll",
  },
  SlidingList: {
    minWidth: theme.breakpoints.values.sm,
  },
}));

export default function PaymentSuccess() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const buttonStatusText = (productName) => {
    return !t(productName) ||
      t(productName) === productName ||
      t(productName) === "plant products arent online right now"
      ? { text: t("Coming Soon"), disabled: true }
      : { text: t("Buy Now"), disabled: false };
  };

  return (
    <Paper elevation={0} square={true} className="fullHeight" p={6}>
      <Box p={6}>
        <Grid container alignItems="center" spacing={2} justifyContent="center">
          <Grid item style={{ width: 110 }}>
            <ThumbUpOutlined
              fontSize="large"
              color="primary"
              className="largeIcon"
            ></ThumbUpOutlined>
          </Grid>
          <Grid item xs={12} sm>
            <Typography
              variant="h2"
              color="primary"
              align={isMobile ? "center" : "left"}
            >
              {t("Thank you!")}
            </Typography>
            <Typography
              variant="subtitle1"
              align={isMobile ? "center" : "left"}
            >
              {t(
                "We have sent you an email with the order confirmation Your bees are looking forward to meeting you"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2}>
          <WBPInfoBox title="Next steps" secondary>
            <ul className="bullets">
              <li>
                <Trans
                  i18nKey="Make sure your BeeHome is placed in the <lnk>optimal spot</lnk>"
                  components={{
                    lnk: (
                      <Link
                        href={t("optimal-spot")}
                        rel="noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </li>
              <li>
                {t("Make sure you bring the bees outside the day of delivery")}
              </li>
              <li>
                {t("Follow the instruction manual delivered with the bees")}
              </li>
              <li>{t("Be patient, wait for the bees to hatch and enjoy")}</li>
            </ul>
          </WBPInfoBox>
        </Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Button
              id="button-paymentsuccess-ordermorebees"
              variant="contained"
              color="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              {t("Order more bees")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="button-paymentsuccess-exploretheworldofbees"
              variant="outlined"
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                window.location.href = t("explore-the-wildbee-world");
              }}
            >
              {t("Explore the wildbee world")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box p={6} bgcolor="primary.main" color="primary.contrastText">
        <Box mb={6}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            style={{ color: "#FFFFFF" }}
          >
            {t("Here is how you can greet the bees")}
          </Typography>
        </Box>
        <Box className={isMobile ? classes.scrollBox : ""}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            alignContent="center"
            className={isMobile ? classes.SlidingList : ""}
          >
            <Grid item sm={4} xs={4}>
              <Card elevation={0} square={true}>
                <CardMedia
                  image={t("376")}
                  title={t("372")}
                  className={classes.media}
                ></CardMedia>
                <Box bgcolor="background.paper" align="center">
                  <CardContent>
                    <Typography variant="h6">{t("372")}</Typography>
                    {/*<Typography variant="body2">
                          CHF 120.00
                        </Typography>*/}
                    <Button
                      id="button-paymentsuccess-container1link"
                      variant="contained"
                      color="secondary"
                      disabled={buttonStatusText("320").disabled}
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = t("320");
                      }}
                    >
                      {buttonStatusText("320").text}
                    </Button>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={4} xs={4} className={classes.snacks}>
              <Card elevation={0} square={true}>
                <CardMedia
                  image={t("377")}
                  title={t("373")}
                  className={classes.media}
                ></CardMedia>
                <Box bgcolor="background.paper" align="center">
                  <CardContent>
                    <Typography variant="h6">{t("373")}</Typography>
                    {/*<Typography variant="body2">
                        CHF 120.00
                      </Typography>*/}
                    <Button
                      id="button-paymentsuccess-container2link"
                      variant="contained"
                      color="secondary"
                      disabled={buttonStatusText("321").disabled}
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = t("321");
                      }}
                    >
                      {buttonStatusText("321").text}
                    </Button>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Card elevation={0} square={true}>
                <CardMedia
                  image={t("378")}
                  title={t("374")}
                  className={classes.media}
                ></CardMedia>
                <Box bgcolor="background.paper" align="center">
                  <CardContent>
                    <Typography variant="h6">{t("374")}</Typography>
                    {/*<Typography variant="body2">
                        CHF 120.00
                      </Typography>*/}
                    <Button
                      id="button-paymentsuccess-container3link"
                      variant="contained"
                      color="secondary"
                      disabled={buttonStatusText("Container 3 Link").disabled}
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = t("Container 3 Link");
                      }}
                    >
                      {buttonStatusText("Container 3 Link").text}
                    </Button>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
