import { useEffect } from "react";
import history from "../services/history";
import firebase from "firebase/app";
import "firebase/auth";

export default function Logout() {
  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const returnTo = urlParams.get("return_to");

    firebase
      .auth()
      .signOut()
      .then(() => {
        if (returnTo) {
          window.location.href = returnTo;
        } else {
          history.push("/login");
        }
      });
  });

  return <></>;
}
