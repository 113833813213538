import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const resignin = (email, reason) => {
  const { sendLoginLinkEmail } = useContext(AuthContext);
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.removeItem("token");
      localStorage.setItem("goto", "/account");
      sendLoginLinkEmail(email, reason);
    });
};

export default resignin;
