import { Grid, Paper, Box, Link, Typography, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../contexts/AuthContext";
import history from "../services/history";

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%",
  },
  bee: {
    backgroundImage: "url('images/yellow-bee.jpg')",
    height: "100%",
    width: "100%",
    flexGrow: 1,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "500px",
  },
  smiley: {
    maxWidth: "52px",
  },
  preline: {
    whiteSpace: "pre-line",
  },
}));

export default function LoginConfirmation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authData, setProtect } = useContext(AuthContext);

  useEffect(() => {
    history.push("/");
  }, [authData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      gotoNextPage();
    }, 5000);
    return () => clearTimeout(timer);
  }, [history.location.state]);

  const gotoNextPage = () => {
    setProtect(true);
  };

  return (
    <div className="fullWidth">
      <Grid container className="basePage">
        <Grid item xs={12} sm={6} bgcolor="white">
          <Box textAlign="center" className="fullHeight">
            <Paper elevation={0} square={true} className="fullHeight">
              <Box p={5} color="primary">
                <img
                  src="images/smiley.png"
                  className={classes.smiley}
                  alt="happy face"
                />
                <Typography variant="body1" color="textSecondary">
                  {t("446")}
                </Typography>
                <Box mt={4}>
                  <Link
                    id="button-passwordchangedconfirmation-gotologin"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      gotoNextPage();
                    }}
                  >
                    {t("447")}
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6} className={classes.bee}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
}
