import React, { createContext, useState } from "react";

const SettingsContext = createContext();
export const StartpopSettingsContext = createContext();
export const BeeCareSettingsContext = createContext();
export const SettingsActionsContext = createContext();

export default SettingsContext;

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ loading: true });
  const [startpopSettings, setStartpopSettings] = useState({ loading: true });
  const [beeCareSettings, setBeeCareSettings] = useState({ loading: true });

  return (
    <SettingsActionsContext.Provider
      value={[setSettings, setStartpopSettings, setBeeCareSettings]}
    >
      <SettingsContext.Provider value={settings}>
        <StartpopSettingsContext.Provider value={startpopSettings}>
          <BeeCareSettingsContext.Provider value={beeCareSettings}>
            {children}
          </BeeCareSettingsContext.Provider>
        </StartpopSettingsContext.Provider>
      </SettingsContext.Provider>
    </SettingsActionsContext.Provider>
  );
};
