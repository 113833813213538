import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import history from "./history";

const signout = (checkReturnTo = null) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  let loginUrl = "/login";
  if (checkReturnTo !== null) {
    loginUrl += "?return_to=" + checkReturnTo;
  }

  firebase
    .auth()
    .signOut()
    .then(() => {
      history.push(loginUrl);
    });
};

export default signout;
