import React, { useEffect, useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import SettingsContext from "../contexts/SettingsContext";
import { ChevronRight } from "@mui/icons-material";
import history from "../services/history";
import WBPPage from "./sub-components/WBPPage";
import references from "../services/references";

const useStyles = makeStyles((theme) => ({
  beeIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  headingMarginBottom: {
    marginBottom: theme.spacing(3),
  },
  mobiWidth: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  paddingTRB: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  paddingTRBL: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  paddingTBL: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  marginFix: {
    margin: theme.spacing(3),
  },
}));

const SendInBox = () => {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const settings = useContext(SettingsContext);
  const [price, setPrice] = useState(references.defaultOrders.beecare.price);
  const [currency, setCurrency] = useState(
    references.defaultOrders.beecare.currency
  );
  const [displayPrice, setDisplayPrice] = useState();

  useEffect(() => {
    if (settings.beecare.shopifyPrice) {
      setPrice(settings.beecare.shopifyPrice);
    }
  }, [settings.beecare.shopifyPrice]);

  useEffect(() => {
    if (settings.beecare.currency) {
      setCurrency(settings.beecare.currency);
    }
  }, [settings.beecare.currency]);

  useEffect(() => {
    setDisplayPrice(currency + " " + price);
  }, [currency, price]);

  useEffect(() => {
    if (user.houses && user.houses) {
      user.houses.filter(
        (value) => value.inSeason && value.inSeason.isSentIn === true
      ).length > 0 && history.push("/sendinbox/return-houses");
    }
  }, [user.houses]);

  const startBeeCareSteps = () => {
    history.push({
      pathname: "/sendinbox/return-houses",
      state: { data: user.houses },
    });
  };

  return (
    <WBPPage title={t("We take care of your wild bees")} description={t("284")}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        onClick={startBeeCareSteps}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Grid item>
            <img
              src="images/sendinbox/step1.svg"
              alt="Wildbiene + Partner"
              className={classes.beeIcon}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              className={classes.headingMarginBottom}
            >
              {t("Select boxes")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t(
                "Select the boxes you would like to send in If you have several BeeHomes you can easily pack them in a box together Use whatever carton you have at home"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid item>
            <img
              src="images/sendinbox/step2.svg"
              alt="Wildbiene + Partner"
              className={classes.beeIcon}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              className={classes.headingMarginBottom}
            >
              {t("Confirm address")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t(
                "Enter or check the delivery address where you want us to return your box to after the care"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid item>
            <img
              src="images/sendinbox/step3.svg"
              alt="Wildbiene + Partner"
              className={classes.beeIcon}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              className={classes.headingMarginBottom}
            >
              {t("Choose payment method")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t(
                "The BeeCare is optional If you do send it in we charge {{price}}- You can pay directly online or receive an invoice via email later on If you have a care ticket you can enter the code and no invoice will be sent to you",
                { price: displayPrice }
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid item>
            <img
              src="images/sendinbox/print4.svg"
              alt="Wildbiene + Partner"
              className={classes.beeIcon}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              className={classes.headingMarginBottom}
            >
              {t("291")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t("292")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          pt={5}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button
            id="button-sendinbox-sendin"
            className={classes.mobiWidth}
            variant="contained"
            color="primary"
            onClick={startBeeCareSteps}
            disableElevation
            endIcon={<ChevronRight />}
          >
            {t("Send in your BeeHomes")}
          </Button>
        </Grid>
      </Grid>
    </WBPPage>
  );
};

export default SendInBox;
