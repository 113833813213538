import React, { useState, useEffect, useContext } from "react";
import references from "../services/references";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  Grid,
  Box,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import API from "../services/api";
import history from "../services/history";
import AddressList from "./sub-components/AddressList";
import { useTranslation } from "react-i18next";
import WBPPage from "./sub-components/WBPPage";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { HomeRounded } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import { useMount } from "react-use";
import UIContext from "../contexts/UIContext";
import AlertDialog from "./sub-components/AlertDialog";
import signout from "../services/signout";
import firebase from "firebase/app";
import "firebase/auth";
import InfoIcon from "@mui/icons-material/Info";
import ChangeEmailModal from "./modals/ChangeEmailModal";
import { makeStyles } from "@mui/styles";
import AuthContext from "../contexts/AuthContext";
import { StackItem } from "./sub-components/StackItem";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  fieldChangesLink: {
    paddingTop: "-1px",
  },
}));

export default function Account() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [user, , getUser, setLocale] = useContext(UserContext);
  const [ui, setUI, loader] = useContext(UIContext);
  const { setPasswordEmail } = useContext(AuthContext);
  const [addresses, setAddresses] = useState([]);
  const [dirtyFields, setDirtyFields] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [hasPassword, setHasPassword] = useState(false);
  const [modalOpenStatus, setModalOpenStatus] = useState(false);

  const [displayError, setDisplayError] = useState({});

  //values need to be defaulted in order for material ui not to complain. We should consider implementing models.
  const [contact, setContact] = useState({
    language: i18n.language.substring(0, 2),
    first_name: "",
    last_name: "",
    email: "",
    phone_mobile: "",
  });

  useEffect(() => {
    setEmailAddress(contact.email);
    if (contact.email) {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(contact.email)
        .then(function (methods) {
          setHasPassword(methods && methods.indexOf("password") > -1);
        });
    }
  }, [contact.email]);

  const openModal = () => {
    setModalOpenStatus(true);
  };

  const closeModal = () => {
    setModalOpenStatus(false);
  };

  const getBillingContactDetails = (contacts) => {
    if (!contacts || contacts.length === 0) {
      return;
    }
    let billingContact = {};
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].tags.indexOf("BILLING") > -1) {
        billingContact = contacts[i];
        break;
      }
    }
    setContact(billingContact);
    setEmailAddress(billingContact.email);
  };

  useEffect(() => {
    if (user.contacts) {
      getBillingContactDetails(user.contacts);
    }
    if (user.addresses) {
      setAddresses(user.addresses);
    }
  }, [user]);

  const submitHandler = (event) => {
    event.preventDefault();
    submitRegularChanges(true);
  };

  const submitRegularChanges = (reload) => {
    /// TODO: Reabetsoe this is where to add the new api call

    let data = { email: emailAddress };
    const contactFields = [
      "language",
      "first_name",
      "last_name",
      "phone_mobile",
      "birthday",
    ];

    Object.keys(contact).map((key) => {
      if (
        (contact[key] && contactFields.indexOf(key) > -1) ||
        key === "birthday"
      ) {
        data[key] = contact[key];
        if (key === "birthday" && !contact[key]) {
          data[key] = null;
        }
      }
      return contact[key];
    });

    loader.addTask("CONTACT");
    return API.put(`me/contacts/` + contact.id, data)
      .then((res) => {
        ///TODO add notofication bar and ensure that values returned by the server are propigated to the interface
        /// TODO Reabetsoe - Austria
        const lang = res.data.language + i18n.language.substring(2, 5);
        setLocale(lang);
        const returnToUrl = new URLSearchParams(history.location.search).get(
          "return_to"
        );

        if (returnToUrl) {
          window.open(returnToUrl, "_self");
        } else if (reload) {
          location.reload();
        }
        return true;
      })
      .catch((error) => {
        //TODO add notofication bar
        if (error.response.status === 403) {
          return true;
        } else if (error.response.status === 409) {
          setDisplayError({
            email: t("227"),
          });
          throw new Error(t("227"));
        }
      })
      .finally(() => {
        loader.clearTask("CONTACT");
      });
  };

  const updateContact = (e) => {
    setDirtyFields([...dirtyFields, e.target.name]);
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  useMount(getUser);

  return (
    <div>
      <WBPPage title={t("My account")} description={t("152")}>
        <form onSubmit={submitHandler}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="first_name"
                label={t("First Name")}
                variant="outlined"
                name="first_name"
                hinttext={t("First Name")}
                floatinglabeltext="FirstName"
                value={contact.first_name}
                onChange={updateContact}
                placeholder={t("First Name")}
                fullWidth
                error={
                  !contact.first_name && dirtyFields.includes("first_name")
                }
                required
                helperText={
                  !contact.first_name ? t("This field is compulsory") : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="last_name"
                label={t("Last Name")}
                variant="outlined"
                name="last_name"
                hinttext={t("Last Name")}
                floatinglabeltext="LastName"
                value={contact.last_name}
                placeholder={t("Last Name")}
                onChange={updateContact}
                error={!contact.last_name}
                required
                helperText={
                  !contact.last_name ? t("This field is compulsory") : ""
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone_mobile"
                label={t("Phone")}
                variant="outlined"
                name="phone_mobile"
                hinttext={t("phone")}
                floatinglabeltext={t("Phone")}
                placeholder={t("Phone")}
                value={contact.phone_mobile || ""}
                onChange={updateContact}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="birthday"
                type="date"
                label={t("Birthday")}
                variant="outlined"
                hinttext={t("birthday")}
                floatinglabeltext={t("Birthday")}
                placeholder={t("Birthday")}
                value={contact.birthday || ""}
                onChange={updateContact}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Grid item xs>
                <TextField
                  id="email"
                  label={t("Email")}
                  variant="outlined"
                  name="email"
                  hinttext={t("Email")}
                  floatinglabeltext={t("Email")}
                  value={emailAddress}
                  onChange={(event) => {
                    setDisplayError({});
                    const emailText = event.target.value.trim().toLowerCase();
                    event.target.value = emailText;
                    setEmailAddress(emailText);
                  }}
                  helperText={"email" in displayError && displayError.email}
                  error={"email" in displayError}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Grid
                container
                item
                xs
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  container
                  item
                  className={classes.fieldChangesLink}
                  xs={12}
                  lg={6}
                >
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={hasPassword === false ? 10 : 12}>
                      <Button
                        id="button-account-setpassword"
                        variant="outlined"
                        onClick={(e) => {
                          e.preventDefault();
                          const request_type =
                            hasPassword === false
                              ? "setpassword"
                              : "resetpassword";
                          setPasswordEmail(emailAddress, request_type);
                        }}
                        underline="hover"
                        style={{ whiteSpace: "nowrap" }}
                        fullWidth
                      >
                        {t(hasPassword === false ? "431" : "432")}
                      </Button>
                    </Grid>
                    {hasPassword === false && (
                      <Grid
                        item
                        xs
                        sx={{ textAlign: { xs: "right", lg: "start" } }}
                      >
                        <Tooltip title={t("448")} arrow>
                          <IconButton
                            edge="end"
                            aria-label="info"
                            color="primary"
                            size="small"
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={classes.fieldChangesLink}
                  xs={12}
                  lg={6}
                  hidden={false}
                >
                  <Button
                    id="button-account-changeEmail"
                    variant="outlined"
                    onClick={(e) => {
                      e.preventDefault();
                      openModal();
                    }}
                    underline="hover"
                    style={{ whiteSpace: "nowrap" }}
                    fullWidth
                  >
                    {t("464")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="language-label">{t("Language")}</InputLabel>
                <Select
                  name="language"
                  labelId="language-label"
                  label={t("Language")}
                  variant="outlined"
                  value={contact.language}
                  fullWidth
                  required
                  onChange={updateContact}
                >
                  {references.languages.map((language) => {
                    if (
                      !user.organisation ||
                      (user.organisation &&
                        (user.organisation.id !== 3 ||
                          (user.organisation.id === 3 && language.id !== 2)))
                    ) {
                      return (
                        <MenuItem key={language.code} value={language.code}>
                          {t(language.name)}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <AddressList
            contact={contact}
            addresses={addresses}
            loadAccountData={getUser}
          />
          <Divider />
          <Box mt={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="space-between"
            >
              <StackItem>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 2 }}
                  justifyContent="start"
                >
                  <StackItem>
                    <Button
                      id="button-account-submit"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        !contact.first_name ||
                        !contact.last_name ||
                        !contact.language
                      }
                      style={{ whiteSpace: "nowrap" }}
                      fullWidth
                      disableElevation
                    >
                      {t("Update info")}
                    </Button>
                  </StackItem>
                  <StackItem>
                    <Button
                      id="button-account-deleteaccount"
                      variant="outlined"
                      color="primary"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenConfirmDialog(true);
                      }}
                      disableElevation
                      style={{ whiteSpace: "nowrap" }}
                      fullWidth
                    >
                      {t("Delete account")}
                    </Button>
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem>
                <Button
                  id="button-account-homepage"
                  variant="contained"
                  color="primary"
                  startIcon={<HomeRounded />}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/");
                  }}
                  disableElevation
                  style={{ whiteSpace: "nowrap" }}
                  fullWidth
                >
                  {t("498")}
                </Button>
              </StackItem>
            </Stack>
          </Box>
        </form>
      </WBPPage>
      <AlertDialog
        type="confirm"
        title={t("Delete")}
        message={t("Are you sure you want to permanently delete your account?")}
        open={openConfirmDialog}
        onTrueHandle={() => {
          loader.addTask("DELETE ACCOUNT");
          setOpenConfirmDialog(false);
          API.delete(`me`)
            .then(() => {
              firebase
                .auth()
                .currentUser.delete()
                .catch((e) => {
                  console.error("error", e);
                })
                .finally(() => {
                  loader.clearTask("DELETE ACCOUNT");
                  setUI({ ...ui, username: null });
                  signout();
                });
            })
            .catch((error) => {
              loader.clearTask("DELETE ACCOUNT");
              console.error(error.response);
            });
        }}
        onFalseHandle={() => {
          setOpenConfirmDialog(false);
        }}
      />
      <ChangeEmailModal
        emailAddress={contact.email}
        contactId={contact.id}
        open={modalOpenStatus}
        onClose={closeModal}
      />
    </div>
  );
}
