import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import WBPPage from "./sub-components/WBPPage";

const useStyles = makeStyles(() => ({
  bees: {
    width: "100%",
    maxWidth: 800,
  },
}));

export default function ErrorPage({ title }) {
  const classes = useStyles();

  return (
    <WBPPage>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6}>
          <img
            src="/images/away-with-the-bees.png"
            alt={title}
            className={classes.bees}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box ml={4}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </WBPPage>
  );
}
