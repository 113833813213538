import {
  Button,
  FormControl,
  Grid,
  TextField,
  Box,
  FormGroup,
  FormHelperText,
  Paper,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import references from "../../services/references";
import UIContext from "../../contexts/UIContext";
import API from "../../services/api";
import SettingsContext from "../../contexts/SettingsContext";
import HouseCard from "../sub-components/HouseCard";
import AlertDialog from "../sub-components/AlertDialog";
import UserContext from "../../contexts/UserContext";
import { LayoutContext } from "../../contexts/LayoutContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  houseList: {
    minWidth: "700px",
  },
  hcFinder: {
    width: "100%",
  },
  listHolder: {
    width: "100%",
    overflowX: "auto",
    overFlowY: "hidden",
    display: "block",
    margin: "10px 0",
    paddingRight: theme.spacing(2),
  },
  congratsBox: {
    background: "#000000dd",
    zIndex: 99,
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
    },
    [theme.breakpoints.down("md")]: {
      bottom: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)})`,
      left: theme.spacing(2),
      position: "fixed",
      right: theme.spacing(2),
      top: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
    },
  },
  imageIcon: {
    display: "inline-block",
    width: 120,
    margin: "auto",
  },
  congrats: {
    color: "#F1B420",
    fontSize: "34px",
    textAlign: "center",
  },
  whiteText: {
    color: "#FFFFFF",
    textAlign: "center",
  },
}));

export default function BeehomeModal(propsData) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [beehomeError, setBeehomeError] = useState({});
  const setNotificationMessage = useContext(LayoutContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [houseTypes, setHouseTypes] = useState([]);
  const [saveClose, setSaveClose] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [pageTitle] = useState(t("Choose your BeeHome model"));
  const [showCongrats, setShowCongrats] = useState(false);

  const settings = useContext(SettingsContext);
  const [, , loader] = useContext(UIContext);
  const [user] = useContext(UserContext);

  const [house, setHouse] = useState({
    type: "",
    code: "",
    disable: false,
  });

  const [houseCodeRegEx, setHouseCodeRegEx] = useState(
    references.house_types.find((ref) => ref.isDefault).regex
  );
  const [displayError, setDisplayError] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (settings.house_types) {
      const displayHouseTypes = [];
      const enabledHouseTypes = [];
      /// TODO Reabetsoe - Austria
      let countryHouseTypes = references.countries.find(
        (country) => country.code === i18n.language.substring(3, 5)
      );
      countryHouseTypes =
        countryHouseTypes && countryHouseTypes.house_types.length > 0
          ? countryHouseTypes.house_types
          : settings.house_types;

      for (let i = 0; i < references.house_types.length; i++) {
        const houseType = references.house_types[i];
        if (countryHouseTypes.indexOf(houseType.code) > -1) {
          displayHouseTypes[settings.house_types.indexOf(houseType.code)] =
            houseType;
        } else if (houseType.isEnabled) {
          enabledHouseTypes.push(houseType);
        }
      }
      setHouseTypes([...displayHouseTypes, ...enabledHouseTypes]);
    }
  }, [settings, loader]);

  useEffect(() => {
    if (propsData.house && propsData.house.id) {
      setHouse({ ...propsData.house, disable: true });
    }
  }, [propsData.house, t]);

  const setClose = (close) => {
    if (close) {
      const timer = setTimeout(() => {
        closeModal();
        setShowCongrats(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      clearFields();
    }
  };

  const submitHandler = (event, saveType, force = false) => {
    if (event) {
      event.preventDefault();
    }

    const hText = helperText();
    if (!("housecode" in hText) && !("type" in hText)) {
      if (house.type && house.type.length > 0) {
        let data =
          house.type === "UNKNOWN" ? { type: null } : { type: house.type };
        data["name"] = house.name ? house.name : null;

        if (house.id) {
          API.put(`me/houses/` + house.id, data)
            .then(() => {
              setShowCongrats(true);
              setClose(saveType);
            })
            .catch((error) => {
              setClose(false);
              setNotificationMessage(t(error.response.data.detail));
            });
        } else {
          data["code"] = house.code;
          API.post(`me/houses?force=` + force, data)
            .then(() => {
              setShowCongrats(true);
              setClose(saveType);
            })
            .catch((error) => {
              const errorData = error.response.data;
              if (errorData.status === 409) {
                setBeehomeError(errorData.detail);
                setOpenConfirmDialog(true);
              } else {
                setNotificationMessage(t(errorData.detail));
              }
            });
        }
      } else {
        setNotificationMessage(t("No house type selected"));
      }
    }
  };

  const selectedHouseType = (event) => {
    delete displayError.type;
    setDisplayError(displayError);
    const houseType = references.house_types.find(
      (ref) => ref.code === event.code
    );
    setHouse({ ...house, type: houseType.code });
    setHouseCodeRegEx(houseType.regex);
  };

  const changeHandler = (e) => {
    delete displayError.housecode;
    setDisplayError(displayError);
    if (e.target.name === "code") {
      let hCode = e.target.value.toUpperCase().trim();
      let houses = user.houses;
      let houseExists = false;
      for (let i = 0; i < houses.length; i++) {
        if (houses[i].code === hCode) {
          houseExists = true;
          break;
        }
      }
      const previousErrors = errorFields;
      if (houseExists) {
        setErrorFields({
          ...previousErrors,
          housecode: t("63"),
        });
      } else {
        delete previousErrors.housecode;
      }
      setHouse({ ...house, [e.target.name]: hCode });
    } else {
      setHouse({ ...house, [e.target.name]: e.target.value });
    }
  };

  const dialogTrueHandleClose = () => {
    submitHandler(null, saveClose, true);
  };

  const dialogFalseHandleClose = () => {
    setOpenConfirmDialog(false);
  };

  const helperText = () => {
    let txt = {};
    if (house.code === "") {
      txt.housecode = t("This field is compulsory Please enter a valid value");
    } else if (!houseCodeRegEx.test(house.code)) {
      txt.housecode = t("Please enter a valid House code");
    } else if (errorFields.housecode) {
      txt.housecode = errorFields.housecode;
    }

    if (!house.type) {
      txt.type = t("Please select a house type");
    }

    setDisplayError(txt);
    return txt;
  };

  const clearFields = () => {
    setHouse({
      type: "",
      code: "",
      disable: false,
    });
    setOpenConfirmDialog(false);
    setDisplayError({});
    setBeehomeError({});
  };

  const closeModal = () => {
    clearFields();
    propsData.onClose();
  };

  return (
    <>
      <Dialog
        open={propsData.open}
        onClose={closeModal}
        onBackdropClick={closeModal}
        fullWidth={true}
        maxWidth="lg"
        fullScreen={isMobile}
        scroll="paper"
      >
        <Paper className={classes.root}>
          <Box p={isMobile ? 2 : 4} pb={isMobile ? 10 : 4}>
            <Typography variant="h3">{t(pageTitle)}</Typography>
            <FormControl
              error={"type" in displayError}
              required
              className={classes.listHolder}
            >
              <FormGroup className={classes.listHolder}>
                <Box py={2}>
                  <Grid container spacing={2} className={classes.houseList}>
                    {houseTypes.map((type) => (
                      <Grid item xs={2} key={type.name}>
                        <HouseCard
                          name={type.name}
                          code={type.code}
                          image={"/" + type.image}
                          houseType={house.type}
                          onChange={selectedHouseType}
                          error={true}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </FormGroup>
              <FormHelperText>{displayError.type}</FormHelperText>
            </FormControl>
            <form
              onSubmit={(event) => {
                setSaveClose(true);
                submitHandler(event, true);
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <img
                    src={t("hcFinderImage")}
                    alt={t("locate code")}
                    className={classes.hcFinder}
                  />
                </Grid>
                <Grid container item spacing={1} xs={12} sm={8}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="code"
                      label={t("House code")}
                      variant="outlined"
                      name="code"
                      hinttext={t("House code")}
                      floatinglabeltext={t("House code")}
                      value={house.code}
                      onChange={changeHandler}
                      placeholder={t("House code")}
                      disabled={house.disable}
                      helperText={
                        ("housecode" in displayError &&
                          displayError.housecode) ||
                        " "
                      }
                      fullWidth
                      error={"housecode" in displayError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="name"
                      label={t("House name")}
                      variant="outlined"
                      name="name"
                      hinttext={t("House name")}
                      floatinglabeltext={t("House name")}
                      value={house.name}
                      onChange={changeHandler}
                      placeholder={t("House name")}
                      helperText={t(
                        "Name your house optionally eg Vegetable garden"
                      )}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={4}>
                <Grid container justifyContent="space-between">
                  <Button
                    id="button-beehomemodal-cancel"
                    variant="outlined"
                    color="secondary"
                    onClick={closeModal}
                  >
                    {t("Cancel")}
                  </Button>
                  <Box>
                    <Button
                      id="button-beehomemodal-submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        "housecode" in displayError || "type" in displayError
                      }
                      type="submit"
                    >
                      {t("Save Changes")}
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </form>
          </Box>
          {showCongrats && (
            <Grid
              container
              direction="row"
              className={classes.congratsBox}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box mb={2} display="flex">
                  <img
                    alt=""
                    className={classes.imageIcon}
                    src="/images/icon_congrats-beehome-added.svg"
                  />
                </Box>
                <Box mb={1}>
                  <Typography variant="h3" className={classes.congrats}>
                    {t("Congratulations")}
                  </Typography>
                </Box>
                <Typography variant="body1" className={classes.whiteText}>
                  {t("BeeHome saved successfully")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Dialog>
      <AlertDialog
        type="confirm"
        title={t("68")}
        message={
          <Trans
            i18nKey="69"
            values={{
              name: beehomeError.first_name,
              hiddenEmail: beehomeError.email,
            }}
          />
        }
        yesButtonText={t("71")}
        noButtonText={t("70")}
        open={openConfirmDialog}
        onTrueHandle={dialogTrueHandleClose}
        onFalseHandle={dialogFalseHandleClose}
      />
    </>
  );
}
