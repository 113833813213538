const themeOptions = {
  palette: {
    primary: {
      main: "#F1B420",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ef8628",
      contrastText: "#fff",
    },
    background: {
      default: "#F0EEE6",
      gold: "#948A55",
    },
    disabled: {
      default: "#e0e0e0",
      contrastText: "#fff",
    },
  },
  white: "#fff",
  typography: {
    fontFamily: [
      "Fabrikat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiStepperRoot: {
      padding: 0,
    },
    MuiListItem: {
      button: {
        "&.Mui-selected": {
          "& > .MuiListItemText-root > .MuiTypography-root": {
            color: "#F1B420",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "#000000",
          lineHeight: 40,
        },
        h2: {
          fontSize: 42,
          fontWeight: "Bold",
          color: "#000000",
        },
        h3: {
          fontSize: 34,
          fontWeight: "Bold",
          color: "#000000",
        },
        h4: {
          fontSize: 22,
          fontWeight: "Bold",
          color: "#000000",
        },
        h5: {
          color: "#000000",
        },
        h6: {
          color: "#666666",
          fontSize: "17px",
        },
        subtitle1: {
          fontSize: 22,
          fontWeight: "Bold",
          color: "#666666",
        },
        body1: {
          color: "#666666",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { flexDirection: "row-reverse", padding: 0 },
      },
    },
    MuiPaper: {
      root: {
        color: "#666666",
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#958A5499",
      },
    },
  },
  custom: {
    Button: {
      root: {
        cursor: "pointer",
        borderRadius: "4px",
      },
      inner: {
        height: "8px",
      },
      facebook: {
        cursor: "pointer",
        borderRadius: "4px",
        color: "white",
        borderColor: "#1877F2",
        backgroundColor: "#1877F2",
      },
    },
    preLoggedInPages: {
      root: {
        border: "5px solid white",
        height: "100%",
        padding: 0,
        flexGrow: 1,
      },
      hcFinder: {
        width: "90%",
        maxWidth: "200px",
      },
      beeIcon: {
        maxWidth: "40px",
      },
      divider: {
        backgroundColor: "white",
        padding: "4px",
        color: "#666",
      },
      rule: {
        marginTop: "-8px",
        color: "#666",
      },
      listIcon: {
        backgroundColor: "transparent",
        color: "#F1B420",
      },
      listText: {
        color: "rgba(0, 0, 0, 0.54)",
      },
      textWrapper: {
        backgroundColor: "rgba(255,255,255,.85)",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      generalText: {
        textAlign: "start",
      },
      smiley: {
        maxWidth: "52px",
      },
      preline: {
        whiteSpace: "pre-line",
      },
    },
  },
};

export default themeOptions;
