import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Paper, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import history from "../services/history";
import references from "../services/references";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "5px solid white",
  },
  // material ui not picking up attribute
  justifyContent: {
    justifyContent: "center",
  },
  beeIcon: {
    maxWidth: "40px",
  },
  flexWidth: {
    flexGrow: "1",
  },
  fullWidth: {
    width: "100%",
  },
  verticalCenterFix: {
    backgroundColor: "white",
    padding: "20% 0",
  },
  mobiMargin: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
}));

const OutOfSeason = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayText, setDisplayText] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    if (props && props.section) {
      setDisplayText(
        references.out_of_season_messages[props.section][props.status]
      );
    } else if (history.location.state && history.location.state.section) {
      setDisplayText(
        references.out_of_season_messages[history.location.state.section][
          history.location.state.status
        ]
      );
    }
  }, [props]);

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      justifyContent="center"
      className={`${classes.mobiMargin} fullHeight`}
    >
      <Grid item sm={12} md={10} className={classes.flexWidth}>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={12} md={6}>
            <Box textAlign="center" className="fullHeight">
              <Paper
                elevation={0}
                square={true}
                className={`${classes.verticalCenterFix} fullHeight`}
              >
                <form>
                  <Box p={4}>
                    <div>
                      <img
                        src="images/beecare-small-icon.png"
                        alt="Wildbiene + Partner"
                        className={classes.beeIcon}
                      />
                      <Typography
                        variant="h3"
                        color="primary"
                        className="preLine"
                      >
                        {t(displayText.title)}
                      </Typography>
                    </div>
                    <br />
                    <div>
                      <Typography variant="body1" className="preLine">
                        <Trans
                          i18nKey={displayText.message}
                          components={{
                            lnk: (
                              <Link
                                href={t(displayText.urlName)}
                                rel="noreferrer"
                                target="_blank"
                              />
                            ),
                            lnkb: (
                              <Link
                                href={t(displayText.urlNameB)}
                                rel="noreferrer"
                                target="_blank"
                              />
                            ),
                          }}
                        />
                      </Typography>
                    </div>
                  </Box>
                </form>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutOfSeason;
