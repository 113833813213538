import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Hidden,
  Grid,
  Divider,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { AccountCircle, ExpandMore, HomeRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import signout from "../services/signout";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UIContext from "../contexts/UIContext";
import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: "20px",
    lineHeight: "40px",
    textDecoration: "none",
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  logo: {
    maxHeight: "60px",
    padding: theme.spacing(1),
  },
  accountIcon: {
    fontSize: "2.5rem",
    cursor: "pointer",
  },
  homeButton: {
    marginTop: "-8px",
  },
  dBlock: {
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "center",
    },
  },
  zIndex: {
    zIndex: "999",
  },
}));

export default function HeaderAppBar() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState();
  const [ui, setUI] = useContext(UIContext);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const localeArray = [
    { shortCode: "de-DE", language: "Deutsch", country: "Deutschland" },
    { shortCode: "fr-CH", language: "Français", country: "Suisse" },
    { shortCode: "de-CH", language: "Deutsch", country: "Schweiz" },
    { shortCode: "de-AT", language: "Deutsch", country: "Österreich" },
  ];

  useEffect(() => {
    setFirebaseUser(firebase.auth().currentUser);
  }, [firebase.auth().currentUser]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoAccount = () => {
    setAnchorEl(null);
    history.push("/account");
  };

  const goHome = (e) => {
    e.preventDefault();
    if (firebaseUser !== null && firebaseUser.emailVerified === true) {
      history.push("/");
    } else {
      logout();
    }
  };

  const logout = () => {
    setAnchorEl(null);
    setUI({ ...ui, username: null });
    signout();
  };

  return (
    <>
      <AppBar
        color="inherit"
        className={classes.zIndex}
        position="relative"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" justifyContent="center">
            {isMobile && ui.username && firebaseUser !== null && (
              <Grid
                item
                xs={1}
                sm={1}
                onClick={() => {
                  setUI({ ...ui, menuOpen: !ui.menuOpen });
                }}
              >
                <img
                  alt="menu toggle"
                  src={ui.menuOpen ? "/images/open.svg" : "/images/closed.svg"}
                />
              </Grid>
            )}
            <Grid item xs={3} sm={3} md={2} lg={2}>
              <a
                href={t("logoLink")}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.dBlock}
              >
                {isMobile ? (
                  <img
                    src={t("smallLogoImage")}
                    alt="Wildbiene + Partner"
                    className={classes.logo}
                  />
                ) : (
                  <img
                    src={t("logoImage")}
                    alt="Wildbiene + Partner"
                    className={classes.logo}
                  />
                )}
              </a>
            </Grid>

            <Grid item xs={4} sm={4}>
              <Hidden mdDown>
                <IconButton
                  onClick={goHome}
                  className={classes.homeButton}
                  size="large"
                >
                  <HomeRounded color="primary" />
                </IconButton>
              </Hidden>
              <Link
                to=""
                variant="h1"
                className={classes.title}
                color="primary"
                onClick={goHome}
              >
                {t("My Beehome")}
              </Link>
            </Grid>
            <Grid
              item
              xs
              container
              alignItems="center"
              spacing={2}
              justifyContent="flex-end"
            >
              {ui.username && firebaseUser !== null && (
                <Grid
                  item
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Hidden mdDown>
                    <Grid item>
                      <Typography variant="h6">{ui.username}</Typography>
                    </Grid>
                  </Hidden>
                  <Grid item>
                    <AccountCircle
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="primary"
                      className={classes.accountIcon}
                    />
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        id="button-headerappbar-myaccount"
                        onClick={gotoAccount}
                      >
                        {t("My account")}
                      </MenuItem>
                      <MenuItem
                        id="button-headerappbar-logout"
                        onClick={logout}
                      >
                        {t("Log out")}
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              )}
              {(!ui.username || firebaseUser === null) && (
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{i18n.language}</Typography>
                  </Grid>
                  <Grid item>
                    <ExpandMore
                      aria-label="lang menu"
                      aria-controls="lang-menur"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="primary"
                      className={classes.accountIcon}
                    />

                    <Menu
                      id="lang-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {localeArray.map((currentLocale, key) => {
                        return (
                          <span
                            key={key}
                            id={
                              "button-headerappbar-menuitem-" +
                              currentLocale.shortCode.toLowerCase()
                            }
                          >
                            <MenuItem
                              id={
                                "button-headerappbar-menuitem-" +
                                currentLocale.shortCode.toLowerCase()
                              }
                              onClick={() => {
                                i18n.changeLanguage(currentLocale.shortCode);
                                setAnchorEl(null);
                                location.reload();
                              }}
                            >
                              <Grid
                                id={
                                  "button-headerappbar-menuitem-" +
                                  currentLocale.shortCode.toLowerCase()
                                }
                                container
                                spacing={0}
                                align="center"
                              >
                                <Grid
                                  id={
                                    "button-headerappbar-menuitem-" +
                                    currentLocale.shortCode.toLowerCase()
                                  }
                                  item
                                  xs={12}
                                >
                                  <Typography
                                    id={
                                      "button-headerappbar-menuitem-" +
                                      currentLocale.shortCode.toLowerCase()
                                    }
                                    variant="h6"
                                    style={{ lineHeight: 0.4 }}
                                    justify="center"
                                  >
                                    {currentLocale.language}
                                  </Typography>
                                </Grid>
                                <Grid
                                  id={
                                    "button-headerappbar-menuitem-" +
                                    currentLocale.shortCode.toLowerCase()
                                  }
                                  item
                                  xs={12}
                                >
                                  <Typography
                                    id={
                                      "button-headerappbar-menuitem-" +
                                      currentLocale.shortCode.toLowerCase()
                                    }
                                    variant="caption"
                                  >
                                    ({currentLocale.country})
                                  </Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                            {key < localeArray.length - 1 && <Divider />}
                          </span>
                        );
                      })}
                    </Menu>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
