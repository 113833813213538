import React, { useEffect, useState, useContext, Fragment } from "react";
import UserContext from "../contexts/UserContext";
import {
  Grid,
  List,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useFormatter } from "../hooks/formatter.hooks";
import BeeCareStatusProgressBar from "./sub-components/BeeCareStatusProgressBar";
import { useMount } from "react-use";
import BeeCareStatusInbound from "./sub-components/beecare/Inbound";
import BeeCareStatusTubes from "./sub-components/beecare/Tubes";
import BeeCareStatusCocoons from "./sub-components/beecare/Cocoons";
import BeeCareStatusRefill from "./sub-components/beecare/Refill";
import BeeCareStatusInvoice from "./sub-components/beecare/Invoice";
import BeeCareStatusOnItsWayBack from "./sub-components/beecare/OnItsWayBack";
import ImageModal from "./modals/ImageModal";
import WBPPage from "./sub-components/WBPPage";

const useStyles = makeStyles((theme) => ({
  homeImage: {
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      maxWidth: 60,
      marginTop: theme.spacing(1),
    },
  },
  listitemText: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "3px",
    },
  },
  cellsUpperRow: {
    border: "none",
  },
  rowBorder: {
    padding: 0,
  },
  collapsers: { cursor: "pointer" },
}));

const BeeCareStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [user, , getUser] = useContext(UserContext);
  const [beecareItems, setBeecareItems] = useState();
  const { getTypeImage, getTypeName } = useFormatter();
  const theme = useTheme();
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const setCollapsedClick = (key) => {
    const items = [...beecareItems];
    items[key].collapsed = !items[key].collapsed;
    setBeecareItems(items);
  };
  const displayStepDetails = (houseIndex, stepIndex) => {
    const items = [...beecareItems];
    items[houseIndex].beecare.selectedStep = stepIndex;
    items[houseIndex].collapsed = true;
    setBeecareItems(items);
  };

  useEffect(() => {
    if (user.houses.length > 0 && user.houses[0].lastSeason) {
      setBeecareItems(user.houses);
    }
  }, [user.houses]);

  useMount(getUser);

  return (
    <WBPPage title={t("BeeCare Status")}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        item
        xs={12}
      >
        <TableContainer>
          <Table aria-label="houses table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {beecareItems &&
                beecareItems.map((value, key) => {
                  let currentStep = value.beecare.activeStep;
                  const completedSteps = value.beecare.completedSteps;
                  const selectedStep = value.beecare.selectedStep;
                  return (
                    <Fragment key={key}>
                      <TableRow role="checkbox" tabIndex={-1} key={value.code}>
                        <TableCell
                          align="left"
                          className={classes.cellsUpperRow}
                          width={7}
                        >
                          <img
                            src={getTypeImage(value.type)}
                            alt={"image of " + value.type}
                            className={`${classes.homeImage}`}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cellsUpperRow}
                          align="left"
                          width={23}
                        >
                          <List component="div">
                            <Tooltip
                              title={t("House code")}
                              placement="bottom"
                              arrow
                            >
                              <ListItemText
                                className={`${classes.listitem} ${classes.listTitle}`}
                                primary={value.name ? value.name : value.code}
                                secondary={
                                  value.name
                                    ? value.code
                                    : getTypeName(value.type)
                                }
                              />
                            </Tooltip>
                          </List>
                        </TableCell>
                        <TableCell
                          className={classes.cellsUpperRow}
                          align="left"
                          width={70}
                        >
                          {!isMobile && (
                            <BeeCareStatusProgressBar
                              step={currentStep}
                              completedSteps={completedSteps}
                              selectedStep={selectedStep}
                              displayStepDetails={(stepIndex) => {
                                displayStepDetails(key, stepIndex);
                              }}
                            ></BeeCareStatusProgressBar>
                          )}
                        </TableCell>
                        <TableCell
                          id="button-beecarestatus-houseexpandcollapse"
                          className={classes.cellsUpperRow}
                          align="right"
                          onClick={() => setCollapsedClick(key, currentStep)}
                          width={5}
                        >
                          {value.collapsed ? (
                            <ExpandLess
                              id="button-beecarestatus-houseexpandcollapse"
                              className={classes.collapsers}
                            />
                          ) : (
                            <ExpandMore
                              id="button-beecarestatus-houseexpandcollapse"
                              className={classes.collapsers}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {isMobile && (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className={classes.cellsUpperRow}
                          >
                            <BeeCareStatusProgressBar
                              step={currentStep}
                              completedSteps={completedSteps}
                              selectedStep={selectedStep}
                              displayStepDetails={(stepIndex) => {
                                displayStepDetails(key, stepIndex);
                              }}
                            ></BeeCareStatusProgressBar>
                          </TableCell>
                        </TableRow>
                      )}
                      {value.collapsed && (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className={classes.cellsUpperRow}
                          >
                            {value.beecare.selectedStep === 0 && (
                              <BeeCareStatusInbound
                                id="button-beecarestatus-beecarestatusinbound"
                                value={value}
                                completedSteps={completedSteps}
                                displayStepDetails={(stepIndex) => {
                                  displayStepDetails(key, stepIndex);
                                }}
                              />
                            )}
                            {value.beecare.selectedStep === 1 && (
                              <>
                                <BeeCareStatusTubes
                                  value={value}
                                  displayStepDetails={(stepIndex) => {
                                    displayStepDetails(key, stepIndex);
                                  }}
                                  setModalOpenStatus={setModalOpenStatus}
                                />
                                {value.beecare.snapshots &&
                                  value.beecare.snapshots.length > 0 && (
                                    <ImageModal
                                      imageSrc={value.beecare.snapshots[0].replace(
                                        "gs://",
                                        process.env.REACT_APP_GOOGLE_STORAGE
                                      )}
                                      open={modalOpenStatus}
                                      onClose={setModalOpenStatus}
                                    />
                                  )}
                              </>
                            )}
                            {value.beecare.selectedStep === 2 && (
                              <BeeCareStatusCocoons
                                value={value}
                                displayStepDetails={(stepIndex) => {
                                  displayStepDetails(key, stepIndex);
                                }}
                              />
                            )}
                            {value.beecare.selectedStep === 3 && (
                              <BeeCareStatusRefill
                                value={value}
                                displayStepDetails={(stepIndex) => {
                                  displayStepDetails(key, stepIndex);
                                }}
                              />
                            )}
                            {value.beecare.selectedStep === 4 && (
                              <BeeCareStatusOnItsWayBack
                                value={value}
                                displayStepDetails={(stepIndex) => {
                                  displayStepDetails(key, stepIndex);
                                }}
                              />
                            )}
                            {value.beecare.selectedStep === 5 && (
                              <BeeCareStatusInvoice
                                value={value}
                                displayStepDetails={(stepIndex) => {
                                  displayStepDetails(key, stepIndex);
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          className={classes.rowBorder}
                        ></TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </WBPPage>
  );
};

export default BeeCareStatus;
