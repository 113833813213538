import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";

export default function BeeCareStatusRefill({ value, displayStepDetails }) {
  const { t } = useTranslation();

  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return value.beecare.step4 ? (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      title={t("Replacing the tubes in your inbox")}
      secondary
    >
      <Typography variant="body1">
        {t(
          "To provide new nesting opportunities for bees we refilled the inbox with new tubes"
        )}
      </Typography>
    </WBPBeeCareStatusInfoBox>
  ) : (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      title={t("Here's what's happening in - refill")}
      secondary
    >
      {t("264")}
    </WBPBeeCareStatusInfoBox>
  );
}
