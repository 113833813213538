import React from "react";
import { Box, Grid, Typography, IconButton, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "32px 5px",
    "&.MuiGrid-grid-xs-true": {
      textAlign: "end",
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  bodyCopy: {
    marginTop: theme.spacing(1),
    whiteSpace: "pre-line",
  },
}));

export default function WBPBeeCareStatusInfoBox({
  children,
  title,
  secondary,
  selectedStep,
  displayStepDetails,
  showPreviousButton = true,
  showNextButton = true,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const changeSelectedStep = (event, direction) => {
    event.preventDefault();
    displayStepDetails(
      direction === "up" ? selectedStep + 1 : selectedStep - 1
    );
  };

  return (
    <Box
      className={classes.root}
      p={4}
      mb={4}
      bgcolor={secondary ? theme.palette.background.default : "grey.300"}
    >
      <Grid container direction="row" alignItems="center" spacing={0}>
        <Grid container item xs={1} justifyContent="flex-end">
          {showPreviousButton && (
            <IconButton
              id="button-wbpbeecarestatusinfobox-changestepleft"
              hidden={Number(selectedStep) === 0}
              aria-label="down"
              onClick={(event) => changeSelectedStep(event, "down")}
              className={classes.button}
              size="large"
            >
              <ChevronLeft />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10}>
          {title && <Typography variant="h6">{t(title)}</Typography>}
          {children}
        </Grid>
        <Grid item xs={1} style={{ textAlign: "right" }}>
          {showNextButton && (
            <IconButton
              id="button-wbpbeecarestatusinfobox-changestepright"
              aria-label="up"
              onClick={(event) => changeSelectedStep(event, "up")}
              className={classes.button}
              size="large"
            >
              <ChevronRight />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
