import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
} from "@mui/material";
import API from "../services/api";
import references from "../services/references";
import history from "../services/history";
import signout from "../services/signout";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import UIContext from "../contexts/UIContext";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LayoutContext } from "../contexts/LayoutContext";
import UserContext from "../contexts/UserContext";
import themeOptions from "../services/theme-default";
import AuthContext from "../contexts/AuthContext";

const useStyles = makeStyles(() => themeOptions.custom.preLoggedInPages);

export default function SignUp() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [tcAgree, setTCAgree] = useState(false);
  const [newsletterAgree, setNewsletterAgree] = useState(true);
  const [, , loader] = useContext(UIContext);
  const [, , , setLocale] = useContext(UserContext);
  const [dirtyFields, setDirtyFields] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [firstnameHolder, setFirstnameHolder] = useState("");
  const { sendLoginLinkEmail } = useContext(AuthContext);
  let existingEmail = "";
  if (history.location.state) {
    if (history.location.state.email) {
      existingEmail = history.location.state.email;
    }
  }

  const [signupData, setSignupData] = useState({
    country: "",
    language: "de",
    email: existingEmail,
    tokenEmail: existingEmail,
  });
  const setNotificationMessage = useContext(LayoutContext);

  useEffect(() => {
    if (signupData.country) {
      const countryCode =
        signupData.country.toUpperCase().trim() === "LI"
          ? "CH"
          : signupData.country;
      const languageCode =
        countryCode === "CH" ? i18n.language.substring(0, 3) : "de-";
      setLocale(languageCode.concat(countryCode));
    }
  }, [signupData.country]);

  const submitHandler = (event) => {
    event.preventDefault();
    let data = {
      first_name: signupData.first_name,
      last_name: signupData.last_name,
      country_code: signupData.country,
      language: i18n.language.substring(0, 2),
    };
    if (signupData.email) {
      data["email"] = signupData.email;
    }
    //check if the user is logged in to firebase:
    if (!firebase.auth().currentUser) {
      localStorage.setItem("signupData", JSON.stringify(data));
      sendLoginLinkEmail(data.email);
    } else {
      loader.addTask("SIGNUP");
      API.post(`me/signup`, data)
        .then((res) => {
          firebase
            .auth()
            .updateCurrentUser(firebase.auth().currentUser)
            .then(() => {
              loader.clearTask("SIGNUP");
              history.push({
                pathname: "/",
                state: {
                  data: res.data,
                }, // your data array of objects
              });
            });
        })
        .catch((error) => {
          loader.clearTask("SIGNUP");
          if (error && error.response && error.response.data.status === 400) {
            signout();
          } else {
            setNotificationMessage(t(error.response.data.detail));
          }
        });
    }
  };
  const updateSignupData = (e) => {
    const fieldData = e.target.value;
    setDirtyFields([...dirtyFields, e.target.name]);
    if (fieldData) {
      setSignupData({ ...signupData, [e.target.name]: fieldData });
      setErrorFields(errorFields.filter((field) => field !== e.target.name));
    } else {
      setErrorFields([...errorFields, e.target.name]);
    }
    if (e.target.name === "first_name") {
      setFirstnameHolder(fieldData === null ? "" : fieldData);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={6} bgcolor="white">
        <Box textAlign="center" className="fullHeight">
          <Paper elevation={0} square={true} className="fullHeight">
            <Box p={4}>
              <img
                src="images/bee-icon.png"
                alt="Wildbiene + Partner"
                className={classes.beeIcon}
              />
              <Typography variant="h3" color="primary" className="preLine">
                {t("453")}
              </Typography>
              <form onSubmit={submitHandler}>
                <Box py={2} px={4}>
                  <TextField
                    id="first_name"
                    label={t("First Name")}
                    variant="outlined"
                    name="first_name"
                    hinttext={t("First Name")}
                    floatinglabeltext={t("First Name")}
                    onChange={updateSignupData}
                    placeholder={t("First Name")}
                    value={firstnameHolder}
                    required={true}
                    helperText={
                      errorFields.includes("first_name") &&
                      dirtyFields.includes("first_name")
                        ? t("This field is compulsory")
                        : ""
                    }
                    error={
                      errorFields.includes("first_name") &&
                      dirtyFields.includes("first_name")
                    }
                    fullWidth
                  />
                </Box>
                <Box px={4} py={2}>
                  <TextField
                    id="last_name"
                    label={t("Last Name")}
                    variant="outlined"
                    name="last_name"
                    hinttext={t("Last Name")}
                    floatinglabeltext={t("Last Name")}
                    onChange={updateSignupData}
                    placeholder={t("Last Name")}
                    required={true}
                    helperText={
                      errorFields.includes("last_name") &&
                      dirtyFields.includes("last_name")
                        ? t("This field is compulsory")
                        : ""
                    }
                    error={
                      errorFields.includes("last_name") &&
                      dirtyFields.includes("last_name")
                    }
                    fullWidth
                  />
                </Box>
                <Box
                  px={4}
                  py={2}
                  display={signupData.tokenEmail ? "none" : ""}
                >
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    name="email"
                    hinttext="email"
                    floatinglabeltext="Email"
                    onChange={updateSignupData}
                    placeholder="Email"
                    defaultValue={signupData.email}
                    required={signupData.tokenEmail ? false : true}
                    helperText={
                      errorFields.includes("email") &&
                      dirtyFields.includes("email")
                        ? t("This field is compulsory")
                        : ""
                    }
                    error={
                      errorFields.includes("email") &&
                      dirtyFields.includes("email")
                    }
                    fullWidth
                  />
                </Box>
                <Box py={2} px={4} textAlign="left">
                  <FormControl
                    className="select"
                    required={true}
                    error={!signupData.country && tcAgree}
                    fullWidth
                  >
                    <InputLabel id="country-code-label">
                      {t("Country")}
                    </InputLabel>
                    <Select
                      name="country"
                      variant="outlined"
                      labelId="country-code-label"
                      label={t("Country")}
                      id="country"
                      value={signupData.country}
                      onChange={updateSignupData}
                    >
                      {references.countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {t(country.country)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box px={4} textAlign="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tcAgree}
                        onChange={(event) => {
                          setTCAgree(event.target.checked);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Trans
                        i18nKey="I agree with the <lnk>data protection policy</lnk>"
                        components={{
                          lnk: (
                            <Link
                              href={t("204")}
                              rel="noreferrer"
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    }
                  />
                </Box>
                <Box px={4} textAlign="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newsletterAgree}
                        onChange={(event) => {
                          setNewsletterAgree(event.target.checked);
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={t("454")}
                  />
                </Box>
                <Box px={4} py={2}></Box>
                <Box mb={4}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid>
                      <Button
                        id="button-signup-cancel"
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/login");
                        }}
                        startIcon={<ChevronLeft />}
                      >
                        {t("Cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        id="button-signup-submit"
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={<ChevronRight />}
                        disabled={
                          !tcAgree ||
                          !signupData.country ||
                          !signupData.language ||
                          errorFields.includes("first_name") ||
                          errorFields.includes("last_name") ||
                          errorFields.includes("email")
                        }
                      >
                        {t("Sign up")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
