import React, { useContext, useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import UIContext from "../contexts/UIContext";
import GoogleLoginButton from "./sub-components/GoogleLoginButton";
import { useMount } from "react-use";
import FacebookLoginButton from "./sub-components/FacebookLoginButton";
import resignin from "../services/resignin";
import AuthContext from "../contexts/AuthContext";
import themeOptions from "../services/theme-default";

const useStyles = makeStyles(() => themeOptions.custom.preLoggedInPages);

export default function Login() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const emailRef = useRef("");
  const [, , loader] = useContext(UIContext);
  const { setReturnTo } = useContext(AuthContext);
  const emailRegEx = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)+[\w+-]{2,}$/;
  const [displayError, setDisplayError] = useState({});
  const urlParams = new URLSearchParams(
    window.location.search ? window.location.search : history.location.search
  );
  const locale = urlParams.get("locale");

  useMount(() => {
    loader.addTask("Check Login");
    emailRef.current.value = urlParams.get("email");
    if (locale) {
      i18n.changeLanguage(locale);
    }

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var pendingCred = JSON.parse(localStorage.getItem("pendingCredential"));
        if (!pendingCred) {
          goToApp(result.user);
        } else {
          const fbCred = firebase.auth.FacebookAuthProvider.credential({
            accessToken: pendingCred.oauthAccessToken,
          });
          result.user
            .linkWithCredential(fbCred)
            .then(function (usercred) {
              // Facebook account successfully linked to the existing Firebase user.
              goToApp(usercred.user);
            })
            .catch((error) => {
              console.error("error", error);
              localStorage.removeItem("pendingCredential");
            });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        /*var errorCode = error.code;
			var errorMessage = error.message;
			// The email of the user's account used.
			var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			var credential = error.credential;*/
        console.error("error", error);

        if (error.code === "auth/account-exists-with-different-credential") {
          // Step 2.
          // User's email already exists.
          // The pending Facebook credential.
          var pendingCred = error.credential;
          // The provider account's email address.
          var email = error.email;
          // Get sign-in methods for this email.
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then(function (methods) {
              localStorage.setItem(
                "pendingCredential",
                JSON.stringify(pendingCred)
              );
              // Step 3.
              // If the user has several sign-in methods,
              // the first method in the list will be the "recommended" method to use.
              if (methods[0] === "emailLink") {
                resignin(email, "sensitiveaction");
              } else if (methods[0] === "google.com") {
                const provider = new firebase.auth.GoogleAuthProvider();
                provider.setCustomParameters({ login_hint: email });
                firebase.auth().signInWithRedirect(provider);
              }
            });
        }
        localStorage.removeItem("pendingCredential");
        loader.clearTask("Check Login");
        // ...
      });
  });

  const goToApp = (user) => {
    loader.clearTask("Check Login");
    if (user) {
      setReturnTo(urlParams.get("return_to"));
      history.push("/");
    }
  };

  const continueWithEmail = (e) => {
    e.preventDefault();
    const hText = helperText(emailRef.current.value);
    if (!("email" in hText)) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(emailRef.current.value)
            .then((methods) => {
              const returnTo = urlParams.get("return_to");
              const stateData = {
                email: emailRef.current.value,
                returnTo: returnTo,
              };
              setReturnTo(returnTo);
              if (methods.length === 0) {
                history.push({
                  pathname: "/register",
                  state: stateData,
                });
              } else {
                stateData.loginMethods = methods;
                history.push({
                  pathname: "/logincontinued",
                  state: stateData,
                });
              }
            });
        });
    }
  };
  const helperText = (emailText) => {
    let txt = {};
    if (emailText !== "" && !emailRegEx.test(emailText)) {
      txt = { email: t("Email format is invalid") };
    }
    setDisplayError(txt);
    return txt;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={6} bgcolor="white">
        <Box textAlign="center" className="fullHeight">
          <Paper elevation={0} square={true} className="fullHeight">
            <form onSubmit={continueWithEmail}>
              <Box p={4}>
                <img
                  src="images/bee-icon.png"
                  alt="Wildbiene + Partner"
                  className={classes.beeIcon}
                />
                <Typography variant="h3" color="primary" className="preLine">
                  {t("3")}
                </Typography>
                <div>&nbsp;</div>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ textAlign: "start" }} xs sm={12} md={9}>
                    <Typography variant="body1" color="textSecondary">
                      {t("458")}
                    </Typography>
                  </Grid>
                </Grid>
                <Box py={2}>
                  <FormControl fullWidth>
                    <TextField
                      inputRef={emailRef}
                      label={t("Email")}
                      variant="outlined"
                      onChange={(e) => {
                        e.preventDefault();
                        setDisplayError({});
                      }}
                      helperText={"email" in displayError || displayError.email}
                      error={"email" in displayError || displayError.email}
                    />
                  </FormControl>
                </Box>
                <Box my={3}>
                  <Button
                    id="button-login-emaillogin"
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={
                      "email" in displayError ||
                      displayError.email ||
                      !emailRef.current.value
                        ? true
                        : false
                    }
                    size="large"
                    disableElevation
                  >
                    {t("406")}
                  </Button>
                  <br />
                  <Box mt={1} mb={3}>
                    <span className={classes.divider}>{t("OR")}</span>
                    <hr className={classes.rule}></hr>
                  </Box>
                </Box>
                <Box my={3}>
                  <GoogleLoginButton />
                </Box>
                <Box my={3}>
                  <FacebookLoginButton />
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
