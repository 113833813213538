import React, { useContext } from "react";
import UIContext from "../contexts/UIContext";
import { Box, CircularProgress } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loaderBox: {
    display: "flex",
    flexWrap: "wrap",
    position: "fixed",
    width: "100%",
    height: "100%",
    background: "#44331199",
    top: 0,
    left: 0,
    zIndex: "9999",
  },
  "& > *": {
    width: "500px",
    height: "500px",
  },
});

export default function Loader() {
  const classes = useStyles();
  const [UI] = useContext(UIContext);
  return (
    <div>
      {UI.taskList.length > 0 && (
        <Box
          className={classes.loaderBox}
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex">
            <CircularProgress size={"200px"} color={"primary"} />
          </Box>
        </Box>
      )}
    </div>
  );
}
