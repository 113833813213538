import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Paper,
  Grid,
  Hidden,
  Typography,
  Stack,
  CardContent,
  CardActionArea,
  Card,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { StackItem } from "./sub-components/StackItem";
import history from "../services/history";
import SettingsContext from "../contexts/SettingsContext";
import UserContext from "../contexts/UserContext";
import theme from "../services/theme-default";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  customCard: {
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      borderColor: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

const CustomCard = ({ gotoPage, imageSrc, title }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const imageSize = { xs: "calc( 100vw - 100px )", md: "calc( 30vw - 100px )" };

  return (
    <Card
      id={"dashboard-menuitem-" + title}
      variant="outlined"
      className={classes.customCard}
      elevation={0}
      onClick={() => history.push(gotoPage)}
      sx={{
        background:
          "rgba(0, 0, 0, .35) url(" +
          imageSrc +
          ") no-repeat center center / cover",
        backgroundBlendMode: "darken",
        width: imageSize,
        height: imageSize,
        borderRadius: 0,
      }}
    >
      <CardActionArea sx={{ height: "100%" }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h4"
            textAlign="center"
            component="div"
            color={theme.palette.primary.contrastText}
          >
            {t(title)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
const DashBoardMenuList = () => {
  const { i18n } = useTranslation();
  const settings = useContext(SettingsContext);
  const [user] = useContext(UserContext);
  const shopifyImagePath = process.env.REACT_APP_SHOPIFY_IMAGE_PATH;

  let inSeasonProcess = null;
  if (settings.startpop && settings.startpop.status === "inSeason") {
    inSeasonProcess = "startpops";
  } else if (settings.beecare && settings.beecare.status === "inSeason") {
    inSeasonProcess = "beecare";
  }
  return (
    <Stack
      direction={
        inSeasonProcess === null
          ? { xs: "column-reverse", md: "row-reverse" }
          : { xs: "column", md: "row" }
      }
      spacing={{ xs: 1, md: 2 }}
      alignItems="center"
      justifyContent="center"
      paddingY={{ xs: 2, md: 0 }}
    >
      <StackItem>
        <Stack
          direction={
            inSeasonProcess === "beecare"
              ? { xs: "column-reverse", md: "row-reverse" }
              : { xs: "column", md: "row" }
          }
          spacing={{ xs: 1, md: 2 }}
        >
          <StackItem>
            {/*startpops*/}
            <CustomCard
              gotoPage="/startpops"
              imageSrc={shopifyImagePath + "startpop.jpg"}
              title="493"
            />
          </StackItem>
          {user.organisation && Number(user.organisation.id) === 1 && (
            <StackItem>
              {/*beecare*/}
              <CustomCard
                gotoPage="/sendinbox"
                imageSrc={shopifyImagePath + "beecare.jpg"}
                title="494"
              />
            </StackItem>
          )}
        </Stack>
      </StackItem>
      <StackItem>
        {/*my beehome*/}
        <CustomCard
          gotoPage="/mybeehomes"
          imageSrc={
            i18n.language.indexOf("CH") > -1
              ? shopifyImagePath + "CH_mybeehomes.png"
              : shopifyImagePath + "DE_mybeehomes.jpg"
          }
          title="237"
        />
      </StackItem>
    </Stack>
  );
};

export default function DashBoard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const pageTitle = t("492");

  return (
    <Box px={0} m={3} className={classes.root}>
      {/* Desktop version: */}
      <Hidden mdDown>
        <Paper elevation={0} square={true}>
          <Box p={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm>
                <Typography variant="h2">{pageTitle}</Typography>
              </Grid>
            </Grid>
            <Box mt={4}>
              <DashBoardMenuList />
            </Box>
          </Box>
        </Paper>
      </Hidden>
      {/* Mobile version */}
      <Hidden mdUp>
        <Box align="center">
          <Typography variant="h2">{pageTitle}</Typography>
        </Box>
        <Paper elevation={0} className="fullHeight" m={2}>
          <Box p={0}>
            <DashBoardMenuList />
          </Box>
        </Paper>
      </Hidden>
    </Box>
  );
}
