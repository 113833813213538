import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
  MobileStepper,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import history from "../services/history";
import WBPInfoBox from "./sub-components/WBPInfoBox";
import { makeStyles, useTheme } from "@mui/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  border: {
    backgroundColor: "transparent",
    margin: theme.spacing(3),
  },
  bgColor: {
    backgroundColor: "white",
  },
  desktopPadding: {
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(15),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  mb: {
    marginBottom: theme.spacing(1.5),
    minHeight: "53.33px",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0.5),
      minHeight: "81.49px",
    },
  },
  media: {
    width: "100%",
    paddingBottom: "100%",
    cursor: "pointer",
  },
  snacks: {
    flexGrow: 1,
  },
  SlidingList: {
    width: "100%",
    marginLeft: "0",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      minWidth: theme.breakpoints.values.sm,
    },
  },
  mobileStepper: {
    width: "100%",
    justifyContent: "center",
  },
}));

export default function SendInBoxComplete() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const carouselDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const shareTheLoveObject = {
    1: {
      picture: 376,
      link: 320,
      name: 372,
    },
    2: {
      picture: 377,
      link: 321,
      name: 373,
    },
    3: {
      picture: 378,
      link: 322,
      name: 374,
    },
  };

  const buttonStatusText = (productName) => {
    return !t(productName) ||
      t(productName) === productName ||
      t(productName) === "plant products arent online right now"
      ? { text: t("Coming Soon"), disabled: true }
      : { text: t("Buy Now"), disabled: false };
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Object.keys(shareTheLoveObject).length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    const timer = setTimeout(() => {
      setActiveStep(step);
    }, 2000);
    return () => clearTimeout(timer);
  };

  return (
    <Box p={3} style={{ width: "100%" }}>
      <Box className={`${classes.bgColor} ${classes.desktopPadding}`}>
        <Grid container alignItems="center" spacing={2} justifyContent="center">
          <Grid item style={{ width: 110 }}>
            <ThumbUpOutlined
              fontSize="large"
              color="primary"
              className="largeIcon"
            ></ThumbUpOutlined>
          </Grid>
          <Grid item xs={12} sm>
            <Typography
              variant="h2"
              color="primary"
              align={isMobile ? "center" : "left"}
            >
              {t("Thank you")}
            </Typography>
            <Typography
              variant="subtitle1"
              align={isMobile ? "center" : "left"}
            >
              {t(
                "for sending in your BeeHome We will take good care of the bees"
              )}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2}>
          <WBPInfoBox title="Next Steps" secondary>
            <Trans
              i18nKey={"317"}
              components={{
                ul: <ul className="bullets" />,
                li: <li />,
              }}
            />
          </WBPInfoBox>
        </Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Button
              id="button-sendinboxcomplete-tobeecarestatus"
              variant="contained"
              color="primary"
              onClick={() => {
                history.push("/beecarestatus");
              }}
              disableElevation
            >
              {t("To BeeCare Status")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        bgcolor="primary.main"
        color="primary.contrastText"
        className={classes.desktopPadding}
      >
        <Box mb={6} style={{ color: "#FFFFFF" }}>
          <Typography variant="h3" color="inherit" paragraph>
            {t("371")}
          </Typography>
          <Typography variant="h5" color="inherit" paragraph>
            {t("319")}
          </Typography>
        </Box>
        {
          <Box>
            {carouselDisplay ? (
              <Grid
                container
                spacing={5}
                alignItems="center"
                alignContent="center"
                className={classes.SlidingList}
              >
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {[...Array(maxSteps)]
                    .map((x, i) => i + 1)
                    .map((value, index) => (
                      <div key={value}>
                        {Math.abs(activeStep - index) <= 2 && (
                          <Card elevation={0} square={true}>
                            <CardMedia
                              id={"button-sendinboxcomplete-" + value + "-link"}
                              image={t(shareTheLoveObject[value].picture)}
                              title={t(shareTheLoveObject[value].name)}
                              className={classes.media}
                            ></CardMedia>
                            <Box bgcolor="background.paper" textAlign="center">
                              <CardContent>
                                <Typography className={classes.mb} variant="h4">
                                  {t(shareTheLoveObject[value].name)}
                                </Typography>
                                <Button
                                  id={
                                    "button-sendinboxcomplete-" +
                                    value +
                                    "-link"
                                  }
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    buttonStatusText(
                                      shareTheLoveObject[value].link
                                    ).disabled
                                  }
                                  href={t(shareTheLoveObject[value].link)}
                                  target="_blank"
                                  disableElevation
                                >
                                  {
                                    buttonStatusText(
                                      shareTheLoveObject[value].link
                                    ).text
                                  }
                                </Button>
                              </CardContent>
                            </Box>
                          </Card>
                        )}
                      </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  className={classes.mobileStepper}
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                    </Button>
                  }
                />
              </Grid>
            ) : (
              <Grid
                container
                spacing={5}
                alignItems="center"
                alignContent="center"
              >
                {[...Array(maxSteps)]
                  .map((x, i) => i + 1)
                  .map((value) => {
                    const cardObject = shareTheLoveObject[value];
                    return (
                      <Grid key={value} item xs={12} sm={12} md={4}>
                        <Card elevation={0} square={true}>
                          <CardMedia
                            id={"button-sendinboxcomplete-" + value + "-link"}
                            image={t(cardObject.picture)}
                            title={t(cardObject.name)}
                            className={classes.media}
                            onClick={() => {
                              window.open(t(cardObject.link), "_blank");
                            }}
                          ></CardMedia>
                          <Box bgcolor="background.paper" textAlign="center">
                            <CardContent>
                              <Typography className={classes.mb} variant="h4">
                                {t(cardObject.name)}
                              </Typography>
                              <Button
                                id={
                                  "button-sendinboxcomplete-" + value + "-link"
                                }
                                variant="contained"
                                color="secondary"
                                disabled={
                                  buttonStatusText(cardObject.link).disabled
                                }
                                href={t(cardObject.link)}
                                target="_blank"
                                disableElevation
                              >
                                {buttonStatusText(cardObject.link).text}
                              </Button>
                            </CardContent>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Box>
        }
      </Box>
    </Box>
  );
}
