import { useState } from "react";
import references from "../services/references";

export const useBeeCareOrder = () => {
  const [variantsArray] = useState(
    references.variants.beecare.sort((a, b) => a.minimum - b.minimum)
  );

  const getPrice = (numberOfSelectedHouses, allVariants) => {
    const maxVariant = Math.max(
      ...variantsArray.map((variantObject) => variantObject.minimum)
    );
    const minVariant = Math.min(
      ...variantsArray.map((variantObject) => variantObject.minimum)
    );
    const currentVariant =
      numberOfSelectedHouses < minVariant
        ? null
        : variantsArray.reduce((variantObject, obj, currentIndex) =>
            numberOfSelectedHouses === obj.minimum ||
            numberOfSelectedHouses > maxVariant ||
            (numberOfSelectedHouses > obj.minimum &&
              numberOfSelectedHouses < variantsArray[currentIndex + 1].minimum)
              ? obj
              : variantObject
          );

    const returnVariant = allVariants.find((compareVariant) => {
      let compareVariantPrice = compareVariant.price;
      if (
        typeof compareVariantPrice === "object" &&
        compareVariantPrice.amount
      ) {
        compareVariantPrice = compareVariantPrice.amount;
      }
      return Number(compareVariantPrice) === Number(currentVariant.price);
    });

    let minVariantPrice = references.defaultOrders.beecare.price;
    const tempMinVariantPrice = variantsArray.find(
      (variantObject) => variantObject.minimum === minVariant
    ).price;
    if (tempMinVariantPrice) {
      minVariantPrice =
        typeof tempMinVariantPrice === "object" && tempMinVariantPrice.amount
          ? tempMinVariantPrice.amount
          : tempMinVariantPrice;
    }
    return { originalPrice: minVariantPrice, variant: returnVariant };
  };

  return {
    getPrice,
  };
};
