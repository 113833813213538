import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItemText,
  Checkbox,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableBody,
  Box,
  Grid,
  Button,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import history from "../services/history";
import { Trans, useTranslation } from "react-i18next";
import WBPPage from "./sub-components/WBPPage";
import BeeCareOrderSideBar from "./BeeCareOrderSideBar";
import UserContext from "../contexts/UserContext";
import BeeCareOrderContext from "../contexts/BeeCareOrderContext";
import SettingsContext from "../contexts/SettingsContext";
import { useFormatter } from "../hooks/formatter.hooks";
import { useMount } from "react-use";
import { StoreOutlined } from "@mui/icons-material";
import BeehomeModal from "./modals/BeehomeModal";
import references from "../services/references";
import { useBeeCareOrder } from "../hooks/beecare-order.hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  checkboxActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  checkHolder: {
    minWidth: 0,
  },
  houseImage: {
    maxWidth: 90,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      maxWidth: 60,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  houseRowActive: {
    cursor: "pointer",
  },
  houseRowInActive: {
    opacity: ".5",
  },
  imgSelected: {
    border: `medium solid ${theme.palette.primary.main}`,
    borderRadius: "3px",
  },
  listTitle: {
    "& .MuiListItemText-primary": {
      color: "black",
      fontWeight: "bold",
    },
    "& .MuiListItemText-secondary": {
      fontWeight: "bold",
    },
  },
  mobileMargin: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  vAlign: {
    verticalAlign: "top",
  },
}));

export default function SendInBoxStep1() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [beeCareOrder, setBeeCareOrder] = useContext(BeeCareOrderContext);
  const [user, setUser, getUser] = useContext(UserContext);
  const settings = useContext(SettingsContext).beecare;
  const { getTypeImage, getTypeName } = useFormatter();
  const { getPrice } = useBeeCareOrder();
  const [modalOpenStatus, setModalOpenStatus] = useState(false);

  const [selected, setSelected] = useState(beeCareOrder.selectedHouses);
  const isSelected = (code) => selected.indexOf(code) !== -1;
  const [price, setPrice] = useState(references.defaultOrders.beecare.price);

  const notMobile = useMediaQuery(useTheme().breakpoints.up("sm"));

  const openNewBeeHome = () => {
    setModalOpenStatus(true);
  };

  const closeModal = () => {
    setModalOpenStatus(false);
    getUser();
  };

  const handleClick = (event, house) => {
    if (!house.inSeason.isSentIn) {
      const code = house.code;
      const selectedIndex = selected.indexOf(code);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, code);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  useEffect(() => {
    if (settings.shopifyPrice) {
      setPrice(settings.shopifyPrice);
    }
  }, [settings.shopifyPrice]);

  useEffect(() => {
    if (
      user.houses.length === 0 &&
      history.location.state &&
      history.location.state.data.length > 0
    ) {
      setUser((previousUser) => ({
        ...previousUser,
        houses: history.location.state.data,
      }));
    }
  }, [setUser, user.houses.length]);

  useEffect(() => {
    setBeeCareOrder((prevBeeCareOrder) => ({
      ...prevBeeCareOrder,
      selectedHouses: selected,
      amount: selected.length,
      discount_vouchers: [],
    }));
  }, [selected, setBeeCareOrder]);

  useEffect(() => {
    setBeeCareOrder((prevBeeCareOrder) => ({
      ...prevBeeCareOrder,
      houses: selected.map((sel) => {
        return user.houses.find((house) => {
          return house.code.toUpperCase() === sel.toUpperCase();
        }).id;
      }),
    }));
  }, [selected, user.houses, setBeeCareOrder]);

  useEffect(() => {
    let usePrice = price;
    let variant = null;
    let originalPrice = references.defaultOrders.beecare.price;
    if (
      selected.length &&
      settings.shopify.products &&
      settings.shopify.products.variants
    ) {
      const returnedPriceDetails = getPrice(
        selected.length,
        settings.shopify.products.variants
      );
      originalPrice = Number(returnedPriceDetails.originalPrice);
      variant = returnedPriceDetails.variant;
      usePrice =
        typeof variant.price === "object" && variant.price.amount
          ? Number(variant.price.amount)
          : Number(variant.price);
    }

    const totalPrice = selected.length
      ? selected.length * usePrice -
        beeCareOrder.discount_vouchers.length * usePrice +
        beeCareOrder.donation
      : 0;
    setBeeCareOrder((prevBeeCareOrder) => ({
      ...prevBeeCareOrder,
      total_price: totalPrice,
      variant: variant,
      price: Number(originalPrice),
      priceReduction:
        (Number(originalPrice) - Number(usePrice)) * beeCareOrder.amount,
    }));
  }, [selected, settings.shopify.products, beeCareOrder.discount_vouchers]);

  const nextPage = (e) => {
    e.preventDefault();
    if (selected.length) {
      history.push("/sendinbox/return-address");
    }
  };

  useMount(getUser);

  const renderTableBody = () => {
    if (notMobile) {
      // Desktop
      return (
        <TableBody>
          {user.houses &&
            user.houses.length > 0 &&
            user.houses[0].inSeason &&
            user.houses.map((value) => {
              const labelId = `checkbox-list-label-${value.id}`;
              const isItemSelected = isSelected(value.code);
              const isSentIn = value.inSeason.isSentIn;
              const tracking_number = value.inSeason.tracking_number;
              const tracking_url = value.inSeason.tracking_url;
              const shipping_label = value.inSeason.shipping_label;
              return (
                <TableRow
                  hover={!isSentIn}
                  onClick={(event) => handleClick(event, value)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={value.code}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                      disabled={isSentIn}
                      className={isItemSelected ? classes.checkboxActive : ""}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={
                      isSentIn
                        ? classes.houseRowInActive
                        : classes.houseRowActive
                    }
                  >
                    <img
                      src={getTypeImage(value.type)}
                      alt={"image of " + value.type}
                      className={`${classes.houseImage} ${
                        isItemSelected ? classes.imgSelected : ""
                      }`}
                    />
                  </TableCell>
                  <TableCell
                    className={
                      isSentIn
                        ? classes.houseRowInActive
                        : classes.houseRowActive
                    }
                  >
                    <List component="div">
                      <Tooltip title={t("House code")} placement="bottom" arrow>
                        <ListItemText
                          className={`${classes.listitem} ${classes.listTitle}`}
                          primary={value.name ? value.name : value.code}
                          secondary={
                            value.name ? value.code : getTypeName(value.type)
                          }
                        />
                      </Tooltip>
                    </List>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t("298")} placement="bottom" arrow>
                      {/* Requires to be wrapped in div else tooltip throws child error */}
                      <div>
                        {tracking_number && (
                          <Link href={tracking_url} target="_blank">
                            {tracking_number}
                          </Link>
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {shipping_label && (
                      <Link href={shipping_label} target="_blank">
                        {t("301")}
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      );
    } else {
      // Mobile
      return (
        <TableBody>
          {user.houses &&
            user.houses.map((value) => {
              const labelId = `checkbox-list-label-${value.id}`;
              const isItemSelected = isSelected(value.code);
              let isSentIn = null;
              let tracking_number = null;
              let tracking_url = null;
              let shipping_label = null;
              if (value.inSeason) {
                isSentIn = value.inSeason.isSentIn;
                tracking_number = value.inSeason.tracking_number;
                tracking_url = value.inSeason.tracking_url;
                shipping_label = value.inSeason.shipping_label;
              }
              return (
                <TableRow
                  hover={!isSentIn}
                  onClick={(event) => handleClick(event, value)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={value.code}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                      disabled={isSentIn}
                      className={isItemSelected ? classes.checkboxActive : ""}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <img
                      src={getTypeImage(value.type)}
                      alt={"image of " + value.type}
                      className={`${classes.houseImage} ${
                        isItemSelected ? classes.imgSelected : ""
                      } ${
                        isSentIn
                          ? classes.houseRowInActive
                          : classes.houseRowActive
                      }`}
                    />
                  </TableCell>
                  <TableCell>
                    <List
                      component="div"
                      disablePadding={true}
                      className={
                        isSentIn
                          ? classes.houseRowInActive
                          : classes.houseRowActive
                      }
                    >
                      <Tooltip title={t("House code")} placement="bottom" arrow>
                        <ListItemText
                          className={`${classes.listitem} ${classes.listTitle}`}
                          primary={value.name ? value.name : value.code}
                          secondary={
                            value.name ? value.code : getTypeName(value.type)
                          }
                        />
                      </Tooltip>
                    </List>
                    <Tooltip title={t("298")} placement="bottom" arrow>
                      {/* Requires to be wrapped in div else tooltip throws child error */}
                      <div>
                        {tracking_number && (
                          <Link href={tracking_url} target="_blank">
                            {tracking_number}
                          </Link>
                        )}
                      </div>
                    </Tooltip>
                    {shipping_label && (
                      <Link href={shipping_label} target="_blank">
                        {t("301")}
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      );
    }
  };

  return (
    <BeeCareOrderSideBar step={0} showSummary>
      <BeehomeModal house={{}} open={modalOpenStatus} onClose={closeModal} />
      <WBPPage
        title={t("Step 1 Select boxes")}
        description={
          <Trans
            i18nKey={"296"}
            components={{
              header: <strong />,
            }}
          />
        }
      >
        <Grid container item justifyContent="flex-end">
          <Box mb={notMobile ? 0 : 2}>
            <Button
              id="button-sendinboxstep1-addabeehome"
              variant="outlined"
              color="primary"
              onClick={openNewBeeHome}
              className={classes.rightMargin}
              startIcon={<StoreOutlined />}
              disableElevation
            >
              {t("Add a BeeHome")}
            </Button>
          </Box>
        </Grid>
        <form onSubmit={nextPage}>
          <Box mb={4}>
            <TableContainer>
              <Table
                aria-label="houses table"
                padding={notMobile ? "normal" : "none"}
              >
                <TableHead>
                  {notMobile ? (
                    // notMobile
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    // !notMobile
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableHead>
                {renderTableBody()}
              </Table>
            </TableContainer>
          </Box>
          <Box component="div" mb={4}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  id="button-sendinboxstep1-back"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                  disableElevation
                >
                  {t("404")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="button-sendinboxstep1-submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!selected.length}
                  disableElevation
                >
                  {t("406")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </WBPPage>
    </BeeCareOrderSideBar>
  );
}
