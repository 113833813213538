import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { createContext, useEffect, useState } from "react";
import themeOptions from "../services/theme-default";
import { deDE, frFR } from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import AlertDialog from "../components/sub-components/AlertDialog";

const UIContext = createContext([{ loading: false }, () => {}]);
const useStyles = makeStyles(() => ({
  "@global": {
    ul: {
      listStyle: "none",
      paddingLeft: 0,
      marginLeft: 0,
    },
    "ul.bullets li": {
      marginLeft: 10,
      position: "relative",
      "&::before": {
        content: '""',
        display: "inline-block",
        width: 3,
        height: 3,
        background: "#666666",
        position: "absolute",
        left: "-10px",
        top: "10px",
      },
    },
    ".basePage": {
      height: "calc( 100vh - 200px)",
      padding: 0,
      flexGrow: 1,
      minHeight: "500px",
    },
    ".fullHeight": {
      height: "100%",
      // minHeight: 'calc( 100vh - 131px )'
    },
    ".fullWidth": {
      width: "100%",
      flexGrow: 1,
    },
    ".preLine": {
      whiteSpace: "pre-line",
    },
    ".largeIcon": {
      fontSize: "6rem",
    },
    a: {
      color: "#F1B420",
      fontWeight: 700,
    },
  },
}));

export default UIContext;

export const UIProvider = ({ children }) => {
  const [, setLocale] = useState(deDE);
  const themeDefault = createTheme(themeOptions);
  const [alertMessage, setAlertMessage] = useState("");
  useStyles();
  const [, i18n] = useTranslation();

  //When the i18n language changes, update the themes locale accordingly
  useEffect(() => {
    if (!i18n.language) return;
    const lang = i18n.language.substring(0, 2);
    switch (lang) {
      case "de":
        setLocale(deDE);
        break;
      case "fr":
        setLocale(frFR);
        break;
      default:
        setLocale(deDE);
        break;
    }
  }, [setLocale, i18n.language]);

  //
  const addTask = (task) => {
    if (!task) return;
    setUI((prevUI) => ({ ...prevUI, taskList: [...prevUI.taskList, task] }));
  };

  const clearTask = (task) => {
    if (!task) return;
    setUI((prevUI) => ({
      ...prevUI,
      taskList: prevUI.taskList.filter((item) => item !== task),
    }));
  };
  const [ui, setUI] = useState({
    loading: false,
    taskList: [],
    language: "de",
    menuOpen: false,
  });
  const [loader] = useState({ addTask: addTask, clearTask: clearTask });
  //   const loader = {addTask:addTask, clearTask:clearTask}
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeDefault}>
        <CssBaseline />
        <UIContext.Provider value={[ui, setUI, loader, setAlertMessage]}>
          {children}
          <AlertDialog
            open={alertMessage ? true : false}
            message={alertMessage}
            onTrueHandle={() => setAlertMessage("")}
          ></AlertDialog>
        </UIContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
