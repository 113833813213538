import React from "react";
import { Grid, Typography, Box, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "5px solid white",
  },
  // material ui not picking up attribute
  justifyContent: {
    justifyContent: "center",
  },
  beeIcon: {
    maxWidth: "40px",
  },
  flexWidth: {
    flexGrow: "1",
  },
  fullWidth: {
    width: "100%",
  },
  verticalCenterFix: {
    backgroundColor: "white",
    padding: "20% 0",
  },
  mobiMargin: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
}));

export default function SoldOut() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      justifyContent="center"
      className={`${classes.mobiMargin} fullHeight`}
    >
      <Grid item sm={12} md={10} className={classes.flexWidth}>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={12} md={6}>
            <Box textAlign="center" className="fullHeight">
              <Paper
                elevation={0}
                square={true}
                className={`${classes.verticalCenterFix} fullHeight`}
              >
                <form>
                  <Box p={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <img
                          src="images/beecare-small-icon.png"
                          alt="Wildbiene + Partner"
                          className={classes.beeIcon}
                        />
                        <Typography
                          variant="h3"
                          color="primary"
                          className="preLine"
                        >
                          {t("410")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className="preLine">
                          <Trans i18nKey="411" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          id="button-soldout-getsomefoodforthebees"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(t("421"), "_blank");
                          }}
                          disableElevation
                        >
                          {t("420")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
