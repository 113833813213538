import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function PaymentFail() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box px={2}>
      <Paper elevation={0} square={true}>
        <Box p={isMobile ? 1 : 6}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            justifyContent="center"
          >
            <Grid item>
              <ErrorOutline
                className="largeIcon"
                color="primary"
              ></ErrorOutline>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h3" align={isMobile ? "center" : "left"}>
                {t("Oops")}
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                {t("Something went wrong with your order Please try again")}
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                {t("Need any help Call")}&nbsp;
                <a
                  id="link-paymentfail-supportphonenumber"
                  href={"tel:" + t("support-phone-number").replace(" ", "")}
                >
                  {t("support-phone-number")}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
