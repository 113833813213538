import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import API from "../services/api";
import AuthContext from "./AuthContext";
import UIContext from "./UIContext";
import useSettings from "../hooks/Settings.hook";
import firebase from "firebase/app";
import "firebase/auth";
import signout from "../services/signout";

const UserContext = createContext([{ loading: true }, () => {}]);

export default UserContext;

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    houses: [],
    addresses: [],
    loaded: false,
  });
  const [reloadUser, setReloadUser] = useState(false);
  const { authData, returnTo } = useContext(AuthContext);
  const [, setUi, loader, setAlertMessage] = useContext(UIContext);
  const [t, i18n] = useTranslation();
  const { getSettings } = useSettings();

  const loadUserOrders = useCallback(
    (houses, settings, firebaseUserID, vouchers) => {
      if (settings) {
        const startDate = new Date(settings.start_date);
        const endDate = new Date(settings.end_date);
        loader.addTask("ORDERS");
        houses.forEach((house, houseIndex) => {
          houses[houseIndex].inSeason = {};
          houses[houseIndex].lastSeason = {};
          houses[houseIndex].beecare = {
            selectedStep: null,
            completedSteps: [],
          };
          houses[houseIndex].collapsed = true;
        });
        setUser((prevUser) => ({ ...prevUser, houses: houses }));
        if (houses.length > 0) {
          API.get(`me/orders`)
            .then((response) => {
              const orders = response.data;
              houses.forEach((house) => {
                const lastSeasonOrder =
                  orders.find(
                    (order) =>
                      order.type.toUpperCase() === "BEECARE" &&
                      order.houses &&
                      order.houses.indexOf(house.id) > -1
                  ) || {};
                const inSeasonOrder =
                  orders.find((order) => {
                    const orderDate = new Date(order.order_date);
                    return (
                      orderDate >= startDate &&
                      orderDate <= endDate &&
                      order.type.toUpperCase() === "BEECARE" &&
                      order.houses &&
                      order.houses.indexOf(house.id) > -1
                    );
                  }) || {};

                lastSeasonOrder.isSentIn = false;
                if (Object.keys(lastSeasonOrder).indexOf("id") > -1) {
                  lastSeasonOrder.isSentIn = true;
                  lastSeasonOrder.wasOrderedInMyBeehome = true;
                  // lastSeasonOrder.created_by === firebaseUserID;
                  lastSeasonOrder.voucher_code =
                    lastSeasonOrder.vouchers &&
                    lastSeasonOrder.vouchers.length > 0
                      ? vouchers.find(
                          (voucher) =>
                            voucher.id === lastSeasonOrder.vouchers[0] &&
                            voucher.type === "BEECARE"
                        ).code
                      : null;
                }

                inSeasonOrder.isSentIn = false;
                if (Object.keys(inSeasonOrder).indexOf("id") > -1) {
                  inSeasonOrder.isSentIn = true;
                  inSeasonOrder.voucher_code =
                    inSeasonOrder.vouchers && inSeasonOrder.vouchers.length > 0
                      ? vouchers.find(
                          (voucher) =>
                            voucher.id === inSeasonOrder.vouchers[0] &&
                            voucher.type === "BEECARE"
                        ).code
                      : null;
                  inSeasonOrder.wasOrderedInMyBeehome = true;
                  // inSeasonOrder.created_by === firebaseUserID;
                }
                house.lastSeason = lastSeasonOrder;
                house.inSeason = inSeasonOrder;
              });

              loader.clearTask("ORDERS");
            })
            .catch((e) => {
              console.error("error", e);
              loader.clearTask("ORDERS");
            })
            .finally(() => {
              API.get(`me/beecare?season=2024`).then((response) => {
                const beecareItems = response.data.items;
                houses.map((house) => {
                  house.beecare =
                    beecareItems.find((item) => house.id === item.house_id) ||
                    {};

                  let activeStep = 0;
                  let selectedStep = 0;
                  const completedSteps = [];
                  if (house.inSeason.isSentIn) {
                    completedSteps.push(0);
                    activeStep = 1;
                    selectedStep = 1;
                  }
                  if (house.beecare.step2) {
                    completedSteps.push(1);
                    activeStep = 2;
                    selectedStep = 2;
                  }
                  if (house.beecare.step3) {
                    completedSteps.push(2);
                    activeStep = 3;
                    selectedStep = 3;
                  }
                  if (house.beecare.step4) {
                    completedSteps.push(3);
                    activeStep = 4;
                    selectedStep = 4;
                  }
                  if (house.beecare.step4 && house.beecare.tracking_number) {
                    completedSteps.push(4);
                    activeStep = 5;
                    if (house.lastSeason.psp === "INVOICE") {
                      selectedStep = 5;
                    }
                  }

                  house.beecare.selectedStep = selectedStep;
                  house.beecare.activeStep = activeStep;
                  house.beecare.completedSteps = completedSteps;
                });
                setUser((previousUser) => ({
                  ...previousUser,
                  houses: houses,
                }));
              });
            });
        } else {
          loader.clearTask("ORDERS");
        }
      } else {
        loader.clearTask("ORDERS");
      }
    },
    [loader]
  );

  const getBillingContact = (contacts) => {
    if (!contacts || contacts.length === 0) {
      return;
    }
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].tags.indexOf("BILLING") > -1) {
        return contacts[i];
      }
    }
  };

  const setLocale = useCallback(
    (locale) => {
      i18n.changeLanguage(locale);
      firebase.auth().languageCode = locale;
    },
    [i18n]
  );

  const getUser = useCallback(async () => {
    let isMounted = true;
    if (authData && isMounted) {
      try {
        loader.addTask("USER");
        if (returnTo !== null) {
          API.get(`me/shopify-login?return_to=` + returnTo).then((response) => {
            window.location.href = response.data.url;
          });
          return;
        }

        const settings = await getSettings();
        if (settings && settings.error) {
          isMounted = false;
          loader.clearTask("USER");
          setAlertMessage({
            text: "475",
            emailAddress: "475-email",
            error: settings.error,
          });
          return false;
        } else {
          API.get(`me`)
            .then((response) => {
              const fireEmail = firebase
                .auth()
                .currentUser.email.trim()
                .toLowerCase();
              if (Object.keys(response.data).length === 0) {
                firebase
                  .auth()
                  .currentUser.delete()
                  .finally(() => {
                    loader.clearTask("USER");
                    setUi((prevUI) => ({
                      ...prevUI,
                      username: null,
                    }));
                    setAlertMessage(t("23"));
                    signout();
                  });
              } else {
                if (isMounted) {
                  const billingContact = getBillingContact(
                    response.data.contacts
                  );
                  const billingAddress = getBillingContact(
                    response.data.addresses
                  );

                  let countryCode = "CH";
                  if (response.data.organisation.id === 3) {
                    /// TODO Reabetsoe - Austria
                    countryCode = "DE";
                    if (billingAddress) {
                      countryCode === billingAddress.country_code;
                    }
                  }

                  setLocale(billingContact.language + "-" + countryCode);

                  if (fireEmail !== billingContact.email.trim().toLowerCase()) {
                    API.put(`me/contacts/` + billingContact.id, {
                      email: fireEmail,
                    })
                      .then(() => {
                        setReloadUser(true);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }

                  loader.addTask("houses");
                  API.get(`me/houses`)
                    .then((res) => {
                      API.get(`me/vouchers`)
                        .then((voucherResponse) => {
                          setUser((prevUser) => ({
                            ...prevUser,
                            vouchers: voucherResponse.data,
                          }));
                          if (settings.beecare) {
                            loadUserOrders(
                              res.data,
                              settings.beecare,
                              authData.uid,
                              voucherResponse.data
                            );
                          } else {
                            setUser((previousUser) => ({
                              ...previousUser,
                              houses: res.data,
                            }));
                          }
                        })
                        .finally(() => {
                          loader.clearTask("houses");
                        });
                    })
                    .catch(() => {
                      loader.clearTask("houses");
                    });

                  setUi((prevUI) => ({
                    ...prevUI,
                    username: billingContact.first_name,
                    language: billingContact.language,
                  }));
                  setUser((prevUser) => ({
                    ...prevUser,
                    ...response.data,
                    loaded: true,
                    billing_contact: billingContact,
                    billing_address: billingAddress,
                  }));
                  loader.clearTask("USER");
                }
              }
            })
            .catch((error) => {
              loader.clearTask("USER");
              setUser((prevUser) => ({ ...prevUser, loaded: true }));

              console.warn(error);
            });
        }
      } catch (error) {
        loader.clearTask("USER");
        setUser((prevUser) => ({ ...prevUser, loaded: true }));
        console.warn(error);
      }
    } else {
      loader.clearTask("USER");
      setUser((prevUser) => ({ ...prevUser, loaded: true }));
    }
    return () => {
      isMounted = false;
      loader.clearTask("USER");
    };
  }, [authData, setUi, i18n, loader, loadUserOrders, returnTo]);

  useEffect(() => {
    if (reloadUser) {
      getUser();
      setReloadUser(false);
    }
  }, [reloadUser, getUser]);

  useEffect(getUser, [authData, setUi, i18n, loader]);

  return (
    <UserContext.Provider value={[user, setUser, getUser, setLocale]}>
      {children}
    </UserContext.Provider>
  );
};
