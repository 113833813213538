import React, { useContext, useEffect, useState, Fragment } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Grid,
  IconButton,
  Typography,
  Hidden,
} from "@mui/material";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import BeeCareOrderContext from "../contexts/BeeCareOrderContext";
import BeeCareOrderSideBar from "./BeeCareOrderSideBar";
import { ChevronRight } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import WBPAddressText from "./sub-components/WBPAddressText";
import AddressModal from "./modals/AddressModal";
import { useMount } from "react-use";

import API from "../services/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WBPPage from "./sub-components/WBPPage";

const SendInBoxStep2 = () => {
  const { t } = useTranslation();
  const [beeCareOrder, setBeeCareOrder] = useContext(BeeCareOrderContext);
  const [user, , getUser] = useContext(UserContext);
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [editAddress, setEditAddress] = useState({});

  useEffect(() => {
    if (!beeCareOrder.address_id) {
      for (let i = 0; i < user.addresses.length; i++) {
        if (checkDefault(user.addresses[i])) {
          setBeeCareOrder((prevBeeCareOrder) => ({
            ...prevBeeCareOrder,
            address_id: user.addresses[i].id,
          }));
        }
      }
    }
  }, [user.addresses, beeCareOrder.address_id, setBeeCareOrder]);

  const checkDefault = (address) => {
    if (address.tags.indexOf("BILLING") > -1) {
      return true;
    }
    return false;
  };

  useMount(() => {
    if (beeCareOrder.houses.length === 0) {
      if (
        history.location.state &&
        history.location.state.data.beeCareOrder.houses.length > 0
      ) {
        setBeeCareOrder(history.location.state.data.beeCareOrder);
      } else {
        history.push("/sendinbox/return-houses");
      }
    }
  });

  const nextPage = (e) => {
    e.preventDefault();
    history.push("/sendinbox/order-confirmation");
  };

  const openModal = (address) => {
    setEditAddress(address);
    setModalOpenStatus(true);
  };

  const closeModal = () => {
    setModalOpenStatus(false);
    getUser();
  };

  const newAddress = () => {
    const contact = user.billing_contact;
    const addressData = {};
    if (contact) {
      addressData.first_name = contact.first_name;
      addressData.last_name = contact.last_name;
    }

    if (user.addresses.length === 0 || !user.billing_address) {
      addressData.tags = ["BILLING"];
    }

    openModal(addressData);
  };

  const editItem = (ev, row) => {
    openModal(row);
  };

  const deleteItem = (ev, rowID) => {
    API.delete(`me/addresses/` + rowID).then(getUser);
  };

  return (
    <BeeCareOrderSideBar step={1} showSummary>
      <AddressModal
        address={editAddress}
        organisationId={user.organisation ? user.organisation.id : ""}
        open={modalOpenStatus}
        onClose={closeModal}
      />
      <WBPPage title={t("Step 2 Confirm address")} description={t("332")}>
        <form onSubmit={nextPage}>
          <Box component="div" mb={4}>
            <div hidden={user.addresses.length === 0}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={6} md={6}>
                  <h2 className="pageTitle">{t("Return address")}</h2>
                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="address"
                        id="address_id"
                        name="address_id"
                        value={beeCareOrder.address_id}
                        onChange={(event) => {
                          setBeeCareOrder({
                            ...beeCareOrder,
                            address_id: Number(event.target.value),
                          });
                        }}
                      >
                        {user.addresses.map((addr, ind) => (
                          <Fragment key={ind}>
                            <Box display="flex" p={1} spacing={2}>
                              <Box p={1} flexGrow={1}>
                                <FormControlLabel
                                  key={ind}
                                  value={addr.id}
                                  control={<Radio />}
                                  label={<WBPAddressText address={addr} />}
                                  checked={
                                    Number(beeCareOrder.address_id) ===
                                    Number(addr.id)
                                  }
                                />
                              </Box>
                              <Box p={1}>
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  color="secondary"
                                  onClick={(event) => editItem(event, addr)}
                                  size="small"
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <span
                                  hidden={
                                    (user.billing_address &&
                                      Number(user.billing_address.id) ===
                                        Number(addr.id)) ||
                                    Number(beeCareOrder.address_id) ===
                                      Number(addr.id)
                                  }
                                >
                                  <br />
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(event) =>
                                      deleteItem(event, addr.id)
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Box>
                            </Box>
                          </Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <h2 className="pageTitle">{t("Billing address")}</h2>
                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="billing_address"
                        id="billing_address_id"
                        name="billing_address_id"
                        value={
                          user.billing_address ? user.billing_address.id : 0
                        }
                        onChange={(event, rowId) => {
                          event.preventDefault();
                          const country = user.addresses.find(
                            (address) => Number(address.id) === Number(rowId)
                          ).country_code;
                          let data = {
                            country_code: country,
                            tags: ["BILLING"],
                          };
                          API.put(`me/addresses/` + rowId, data).then(getUser);
                        }}
                      >
                        {user.addresses.map((addr, ind) => (
                          <Fragment key={ind}>
                            <Box display="flex" p={1} spacing={2}>
                              <Box p={1} flexGrow={1}>
                                <FormControlLabel
                                  key={ind}
                                  value={addr.id}
                                  control={<Radio />}
                                  label={<WBPAddressText address={addr} />}
                                  checked={
                                    user.billing_address &&
                                    Number(user.billing_address.id) ===
                                      Number(addr.id)
                                  }
                                />
                              </Box>
                              <Box p={1}>
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  color="secondary"
                                  onClick={(event) => editItem(event, addr)}
                                  size="small"
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <span
                                  hidden={
                                    (user.billing_address &&
                                      Number(user.billing_address.id) ===
                                        Number(addr.id)) ||
                                    Number(beeCareOrder.address_id) ===
                                      Number(addr.id)
                                  }
                                >
                                  <br />
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(event) =>
                                      deleteItem(event, addr.id)
                                    }
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Box>
                            </Box>
                          </Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div hidden={user.addresses.length > 0}>
              <Grid container alignItems="stretch" alignContent="center">
                <Typography variant="body1">
                  {t("No address registered, please add one")}
                </Typography>
              </Grid>
            </div>
            <Box my={4}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <Hidden smUp>
                  <Grid item xs={12} sm>
                    <Button
                      id="button-sendinboxstep2-addanewaddress"
                      variant="outlined"
                      color="primary"
                      onClick={newAddress}
                      disableElevation
                      fullWidth
                    >
                      {t("Add a new address")}
                    </Button>
                  </Grid>
                </Hidden>
                <Grid item xs>
                  <Button
                    id="button-sendinboxstep2-back"
                    variant="outlined"
                    color="primary"
                    onClick={history.goBack}
                    disableElevation
                  >
                    {t("404")}
                  </Button>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm>
                    <Button
                      id="button-sendinboxstep2-addanewaddress"
                      variant="outlined"
                      color="primary"
                      onClick={newAddress}
                      disableElevation
                    >
                      {t("Add a new address")}
                    </Button>
                  </Grid>
                </Hidden>
                <Grid item xs sm={3}>
                  <Button
                    id="button-sendinboxstep2-submit"
                    variant="contained"
                    color="primary"
                    endIcon={<ChevronRight />}
                    disabled={!beeCareOrder.address_id}
                    type="submit"
                    disableElevation
                    fullWidth
                  >
                    {t("406")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </WBPPage>
    </BeeCareOrderSideBar>
  );
};

export default SendInBoxStep2;
