import { Button, Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ChevronRight, PrintOutlined } from "@mui/icons-material";
import history from "../services/history";
import WBPPage from "./sub-components/WBPPage";
import React, { useContext, useEffect, useState } from "react";
import BeeCareOrderContext from "../contexts/BeeCareOrderContext";
import API from "../services/api";
import UIContext from "../contexts/UIContext";
import { makeStyles } from "@mui/styles";
import references from "../services/references";

const useStyles = makeStyles((theme) => ({
  flexBasis: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
    },
  },
  headingLineBreak: {
    flex: "1 1 100%",
  },
  flexGrow: {
    flexGrow: "1",
  },
  mobiMarginB: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  mb: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  logoImages: {
    maxWidth: 158,
    width: "100%",
    flexGrow: 1,
  },
}));

const SendInBoxStep4 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { order_id } = useParams();
  const [, setBeeCareOrder] = useContext(BeeCareOrderContext);
  const [order, setOrder] = useState({});
  const [shippingLabelCheckCount, setShippingLabelCheckCount] = useState(0);
  const [, , loader] = useContext(UIContext);

  const retryShippingLabel = () => {
    const timer = setTimeout(() => {
      setShippingLabelCheckCount((prevState) => prevState + 1);
    }, 5000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    loader.addTask("ORDER");
    API.get(`me/shopify/orders/` + order_id, { clearCacheEntry: true })
      .then((response) => {
        const returnedOrder = response.data;
        if (
          returnedOrder &&
          (returnedOrder.shipping_label !== null ||
            shippingLabelCheckCount >= 20)
        ) {
          setOrder(returnedOrder);
          loader.clearTask("ORDER");
        } else {
          retryShippingLabel();
        }
      })
      .catch(() => {
        if (shippingLabelCheckCount < 20) {
          retryShippingLabel();
        } else {
          loader.clearTask("ORDER");
        }
      });
  }, [order_id, shippingLabelCheckCount, loader]);

  useEffect(() => {
    setBeeCareOrder(references.defaultOrders.beecare);
  }, [setBeeCareOrder]);

  return (
    <WBPPage title={t("Step 4 Print label and send in the box")}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        spacing={6}
        className={classes.mb}
      >
        {/* #1 Print Label */}
        <Grid
          container
          item
          direction="column"
          className={classes.mobiMarginB}
          spacing={2}
          xs={12}
          sm={4}
          md={4}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <img
              src="/images/print.svg"
              alt={t("305")}
              className={classes.logoImages}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.flexBasis}
          >
            <Typography variant="h4" align="center">
              {t("305")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography align="center" variant="body1">
              <Trans i18nKey="306" />
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Button
              id="button-sendinboxstep4-printlabel"
              variant="contained"
              startIcon={<PrintOutlined />}
              color="secondary"
              disabled={!order.shipping_label}
              disableElevation
              target="_blank"
              href={order.shipping_label}
              fullWidth
            >
              {t("305")}
            </Button>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography align="center" variant="body1">
              <Trans
                i18nKey="470"
                components={{
                  1: <strong />,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        {/* #2 Pack and stick label on box */}
        <Grid
          container
          item
          direction="column"
          className={classes.mobiMarginB}
          spacing={2}
          xs={12}
          sm={4}
          md={4}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <img
              src="/images/step2.svg"
              alt={t("Pack and stick label on box")}
              className={classes.logoImages}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.flexBasis}
          >
            <Typography variant="h4" align="center">
              {t("Pack and stick label on box")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography align="center" variant="body1">
              <Trans
                i18nKey="308"
                components={{
                  1: <strong />,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        {/* #3 Bring it to the post office */}
        <Grid
          container
          item
          direction="column"
          className={classes.mobiMarginB}
          spacing={2}
          xs={12}
          sm={4}
          md={4}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <img
              src="/images/step3.svg"
              alt={t("Send")}
              className={classes.logoImages}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.flexBasis}
          >
            <Typography variant="h4" align="center">
              {t("Send")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography align="center" variant="body1">
              <Trans i18nKey="310" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <Button
            id="button-sendinboxstep3-finish"
            variant="contained"
            color="primary"
            endIcon={<ChevronRight />}
            onClick={(event) => {
              event.preventDefault();
              history.push("/sendinbox/order-complete");
            }}
          >
            {t("Finish")}
          </Button>
        </Grid>
      </Grid>
    </WBPPage>
  );
};

export default SendInBoxStep4;
