import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import history from "./services/history";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Account from "./components/Account";
import BeeHomes from "./components/BeeHomes";
import Startpops from "./components/Startpops";
import StartpopDeliveryDate from "./components/StartpopDeliveryDate";
import StartpopDeliveryAddress from "./components/StartpopDeliveryAddress";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentFail from "./components/PaymentFail";
import { Freshdesk } from "./components/sub-components/Freshdesk";
import "./i18n";
import { SettingsProvider } from "./contexts/SettingsContext";
import { StartPopOrderProvider } from "./contexts/StartpopOrderContext";
import { UIProvider } from "./contexts/UIContext";
import Loader from "./components/Loader";
import LoginConfirmation from "./components/LoginConfirmation";
import LoginVerify from "./components/LoginVerify";
import { AuthProvider } from "./contexts/AuthContext";
import PageNotFound from "./components/404";
import { UserProvider } from "./contexts/UserContext";
import ProtectedRoute from "./services/ProtectedRoute";
import LocaleSelector from "./components/LocaleSelector";
import TagManager from "react-gtm-module";
import Maintenance from "./components/Maintenance";
import { LayoutProvider } from "./contexts/LayoutContext";
import SendInBox from "./components/SendInBox";
import SendInBoxStep1 from "./components/SendInBoxStep1";
import OutOfSeason from "./components/OutOfSeason";
import NoHouses from "./components/NoHouses";
import SendInBoxStep2 from "./components/SendInBoxStep2";
import { BeeCareOrderProvider } from "./contexts/BeeCareOrderContext";
import SendInBoxStep3 from "./components/SendInBoxStep3";
import SendInBoxStep4 from "./components/SendInBoxStep4";
import SendInBoxComplete from "./components/SendInBoxComplete";
import BeeCareStatus from "./components/BeeCareStatus";
import Logout from "./components/Logout";
import SoldOut from "./components/SoldOut";
import LoginContinued from "./components/LoginContinued";
import SetPassword from "./components/SetPassword";
import PasswordChangedConfirmation from "./components/PasswordChangedConfirmation";
import DashBoard from "./components/DashBoard";
import StartpopOrderSummary from "./components/StartpopOrderSummary";

function App() {
  if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  }

  return (
    <UIProvider>
      <AuthProvider>
        <SettingsProvider>
          <UserProvider>
            <LayoutProvider>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/loginverify" component={LoginVerify} />
                <Route path="/signup" component={SignUp} />
                <Route path="/logincontinued" component={LoginContinued} />
                <Route path="/register" component={SetPassword} />
                <Route path="/setpassword" component={SetPassword} />
                <Route path="/resetpassword" component={SetPassword} />
                <Route
                  path="/passwordsuccess"
                  component={PasswordChangedConfirmation}
                />
                <Route
                  path="/loginconfirmation"
                  component={LoginConfirmation}
                />
                <Route path="/maintenance" component={Maintenance} />
                <Switch>
                  <ProtectedRoute path="/account" component={Account} />
                  <ProtectedRoute path="/outofseason" component={OutOfSeason} />
                  <ProtectedRoute path="/soldout" component={SoldOut} />
                  <ProtectedRoute path="/nohouses" component={NoHouses} />
                  <ProtectedRoute
                    path="/payment-success"
                    component={PaymentSuccess}
                  />
                  <ProtectedRoute
                    path="/payment-fail"
                    component={PaymentFail}
                    onlyInStartpopSeason
                  />
                  <StartPopOrderProvider>
                    <Switch>
                      <ProtectedRoute
                        path="/startpops/delivery-date"
                        component={StartpopDeliveryDate}
                        onlyInStartpopSeason
                        checkStartpopSoldOut
                      />
                      <ProtectedRoute
                        path="/startpops/delivery-address"
                        component={StartpopDeliveryAddress}
                        onlyInStartpopSeason
                        checkStartpopSoldOut
                      />
                      <ProtectedRoute
                        path="/startpops/order-summary"
                        component={StartpopOrderSummary}
                        onlyInStartpopSeason
                        checkStartpopSoldOut
                      />
                      <ProtectedRoute
                        path="/startpops"
                        component={Startpops}
                        onlyInStartpopSeason
                        checkStartpopSoldOut
                      />
                      <ProtectedRoute
                        path="/mybeehomes"
                        component={BeeHomes}
                        housesCheck
                        exact
                      />
                      <BeeCareOrderProvider>
                        <Switch>
                          <ProtectedRoute
                            path="/sendinbox/payment-success/:order_id"
                            component={SendInBoxStep4}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/sendinbox/return-houses"
                            component={SendInBoxStep1}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/sendinbox/return-address"
                            component={SendInBoxStep2}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/sendinbox/order-confirmation"
                            component={SendInBoxStep3}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/sendinbox/order-complete"
                            component={SendInBoxComplete}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/sendinbox"
                            component={SendInBox}
                            onlyInBeecareSeason
                            housesCheck
                          />
                          <ProtectedRoute
                            path="/beecarestatus"
                            component={BeeCareStatus}
                            housesCheck
                          />
                          <ProtectedRoute path="/" component={DashBoard} />
                          <Route
                            path="/:country/:lang"
                            component={LocaleSelector}
                          />
                          <Route component={PageNotFound} />
                        </Switch>
                      </BeeCareOrderProvider>
                    </Switch>
                  </StartPopOrderProvider>
                </Switch>
              </Switch>
              <Freshdesk />
              <Loader />
            </LayoutProvider>
          </UserProvider>
        </SettingsProvider>
      </AuthProvider>
    </UIProvider>
  );
}

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);
