import React, { createContext, useState } from "react";
import references from "../services/references";

const BeeCareOrderContext = createContext([{ loading: true }, () => {}]);

export default BeeCareOrderContext;

export const BeeCareOrderProvider = ({ children }) => {
  const orderState = useState(references.defaultOrders.beecare);

  return (
    <BeeCareOrderContext.Provider value={orderState}>
      {children}
    </BeeCareOrderContext.Provider>
  );
};
