import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Grid, Link, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ShoppingCartOutlined, StoreOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import BeehomeModal from "./modals/BeehomeModal";
import UserContext from "../contexts/UserContext";
import history from "../services/history";
import UIContext from "../contexts/UIContext";
import { StartpopSettingsContext } from "../contexts/SettingsContext";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(3),
  },
  aligner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function NoHouses() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [user, , getUser] = useContext(UserContext);
  const [ui] = useContext(UIContext);
  const startpopSettings = useContext(StartpopSettingsContext);

  const closeModal = async () => {
    setModalOpenStatus(false);
    getUser();
  };

  useEffect(() => {
    if (user.houses.length > 0) {
      history.goBack();
    }
  }, [user.houses]);

  return (
    !ui.taskList.length && (
      <Paper
        elevation={0}
        square={true}
        className={`${classes.aligner} ${classes.margin}`}
      >
        <Box p={6}>
          <Grid container className="fullWidth" spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <img
                src="images/no-houses.png"
                alt={t("no beehomes")}
                className="fullWidth"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography variant="h2" className="preLine">
                  {t("Oh no No houses yet")}
                </Typography>
              </Box>
              <Box>
                <Button
                  id="button-nohouses-addabeehome"
                  variant="contained"
                  startIcon={<StoreOutlined />}
                  color="primary"
                  onClick={() => {
                    setModalOpenStatus(true);
                  }}
                  disableElevation
                >
                  {t("Add a BeeHome")}
                </Button>
                &nbsp;
                {startpopSettings &&
                  startpopSettings.status === "inSeason" &&
                  startpopSettings.osmiaTypeSeason !== null && (
                    <Button
                      id="button-beehome-gotonextstep"
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/startpops");
                      }}
                      disableElevation
                      startIcon={<ShoppingCartOutlined />}
                    >
                      {t("Order startpops")}
                    </Button>
                  )}
              </Box>
              <Box mt={4}>
                <Typography>
                  {t("No BeeHome yet")}{" "}
                  <Link
                    id="link-nohouses-getoneinourstore"
                    href={
                      JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[
                        i18n.language
                      ]
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Get one in our store")}
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <BeehomeModal
            house={{}}
            open={modalOpenStatus}
            onClose={closeModal}
          />
        </Box>
      </Paper>
    )
  );
}
