import React from "react";
import {
  Button,
  Grid,
  Box,
  Paper,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
}));

export default function CornutaWarningModal(propsData) {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clearFields = () => {};

  const closeModal = () => {
    clearFields();
    propsData.onClose();
  };

  return (
    <>
      <Dialog
        open={propsData.open}
        onClose={closeModal}
        onBackdropClick={closeModal}
        fullWidth={true}
        maxWidth="lg"
        fullScreen={isMobile}
        scroll="paper"
      >
        {propsData.warningTextObject && (
          <Box className={classes.root} px={2}>
            <Paper elevation={0} square={true}>
              <Box p={isMobile ? 1 : 6}>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  justify="center"
                >
                  <Grid item>
                    <ErrorOutline
                      className="largeIcon"
                      color="primary"
                    ></ErrorOutline>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="h3"
                      align={isMobile ? "center" : "left"}
                    >
                      {t(propsData.warningTextObject.title)}
                    </Typography>
                    {propsData.warningTextObject.warningHtml ? (
                      propsData.warningTextObject.warningHtml
                    ) : (
                      <Typography variant="body1" align="left" paragraph={true}>
                        <Trans
                          i18nKey={propsData.warningTextObject.warningText}
                          components={{
                            osmiaType: propsData.osmiaType,
                            header: <Typography variant="h6" />,
                          }}
                        />
                      </Typography>
                    )}
                    {propsData.warningTextObject.checkboxCloseText ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="checkbox-cornutawarningmodal-close"
                            onChange={() => {
                              if (propsData.changeAcceptValue) {
                                propsData.changeAcceptValue();
                              }
                              closeModal();
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={t(propsData.warningTextObject.checkboxCloseText)}
                      />
                    ) : (
                      <>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs
                            hidden={
                              !propsData.warningTextObject.backButtonCloseText
                            }
                          >
                            <Button
                              id="button-cornutawarningmodal-close"
                              variant="outlined"
                              color="primary"
                              onClick={closeModal}
                              disableElevation
                            >
                              {t(
                                propsData.warningTextObject.backButtonCloseText
                              )}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm>
                            <Button
                              id="button-cornutawarningmodal-accept"
                              variant="contained"
                              color="primary"
                              onClick={closeModal}
                              href={t(propsData.warningTextObject.urlLink)}
                              target="_BLANK"
                              disableElevation
                              fullWidth
                            >
                              {t(
                                propsData.warningTextObject
                                  .acceptButtonCloseText
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        )}
      </Dialog>
    </>
  );
}
