import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import UIContext from "../contexts/UIContext";
import AuthContext from "../contexts/AuthContext";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import AlertDialog from "./sub-components/AlertDialog";
import themeOptions from "../services/theme-default";

const useStyles = makeStyles(() => themeOptions.custom.preLoggedInPages);

export default function LoginContinued() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const passwordRef = useRef("");
  const [hasPassword, setHasPassword] = useState(false);
  const [, , loader] = useContext(UIContext);
  const { returnTo, setReturnTo, setPasswordEmail, sendLoginLinkEmail } =
    useContext(AuthContext);
  const [displayError, setDisplayError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (history.location.state && history.location.state.email) {
      const stateObject = history.location.state;
      setEmail(stateObject.email);
      if (!returnTo && stateObject.returnTo) {
        setReturnTo(stateObject.returnTo);
      }
      setHasPassword(
        stateObject.loginMethods &&
          stateObject.loginMethods.indexOf("password") > -1
      );
    }
  }, [history.location.state]);

  const helperText = (e = null) => {
    let txt = {};
    const currentPassword =
      e === null ? passwordRef.current.value : e.target.value;
    if (!currentPassword) {
      txt.password = t("Password is required");
    } else if (currentPassword.length < 8) {
      txt.password = t(" ");
    }
    setDisplayError(txt);
    return txt;
  };

  const passwordButtonSubmit = (e) => {
    e.preventDefault();
    if (hasPassword === false) {
      setPasswordEmail(email, "setpassword");
    } else {
      loginWithPassword();
    }
  };

  const loginWithPassword = () => {
    loader.addTask("Email Login");
    firebase
      .auth()
      .signInWithEmailAndPassword(email, passwordRef.current.value)
      .then(() => {
        let unsubscribe = firebase.auth().onIdTokenChanged((user) => {
          unsubscribe();
          if (user && user.email) {
            history.push("/");
          } else {
            history.push("/login");
          }
        });
      })
      .catch((error) => {
        loader.clearTask("Email Login");
        let errorCode = error.code;
        const errorMessage = error.message;
        setOpenConfirmDialog(true);
        if (errorCode === "auth/wrong-password" || errorCode === 400) {
          setAlertMessage(t("436"));
        } else {
          console.warn(" msg", errorMessage);
          setAlertMessage(t("23"));
        }
      })
      .finally(loader.clearTask("Email Login"));
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item sm={12} md={6} bgcolor="white">
          <Box textAlign="center" className="fullHeight">
            <Paper elevation={0} square={true} className="fullHeight">
              <form onSubmit={passwordButtonSubmit}>
                <Box p={4}>
                  <img
                    src="/images/bee-icon.png"
                    alt="Wildbiene + Partner"
                    className={classes.beeIcon}
                  />
                  <Typography variant="h3" color="primary" className="preLine">
                    {t(hasPassword === false ? "456" : "455")}
                  </Typography>
                  <div>&nbsp;</div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={{ textAlign: "start" }} xs sm={12} md={9}>
                      <Typography variant="body1" color="textSecondary">
                        {email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box py={2}>
                    <TextField
                      id={"password"}
                      inputRef={passwordRef}
                      type={showPassword ? "text" : "password"}
                      label={t(hasPassword === false ? "434" : "433")}
                      variant="outlined"
                      disabled={!hasPassword}
                      onChange={helperText}
                      InputProps={{
                        autoFocus: true,
                        endAdornment: (
                          <>
                            {hasPassword && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(
                                      (previousValue) => !previousValue
                                    );
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined color="primary" />
                                  ) : (
                                    <VisibilityOffOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      autoFocus={true}
                      error={!!displayError.password}
                      fullWidth
                    ></TextField>
                  </Box>
                  {hasPassword === false ? null : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item style={{ textAlign: "start" }} xs>
                        <Link
                          id="button-logincontinued-setpassword"
                          variant="body2"
                          onClick={(e) => {
                            e.preventDefault();
                            setPasswordEmail(email, "resetpassword");
                          }}
                          underline="hover"
                          style={{ cursor: "pointer" }}
                        >
                          {t("437")}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                  <Box my={3}>
                    <Button
                      id="button-logincontinued-loginwithpassword"
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={
                        !email ||
                        Object.keys(displayError).length > 0 ||
                        (hasPassword === true && !passwordRef.current.value)
                      }
                      size="large"
                      disableElevation
                    >
                      {t(hasPassword === false ? "449" : "438")}
                    </Button>
                    <br />
                    <Box mt={1} mb={3}>
                      <span className={classes.divider}>{t("OR")}</span>
                      <hr className={classes.rule}></hr>
                    </Box>
                  </Box>
                  <Box my={3}>
                    <Button
                      id="button-logincontinued-loginwithemail"
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        sendLoginLinkEmail(email);
                      }}
                      disabled={!email}
                      size="large"
                      disableElevation
                      fullWidth
                    >
                      {t("439")}
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      textAlign="start"
                      variant="body1"
                      color="textSecondary"
                    >
                      {t("435")}
                    </Typography>
                  </Box>
                </Box>
              </form>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <AlertDialog
        type="alert"
        title=""
        message={t(alertMessage)}
        yesButtonText={t("OK")}
        open={openConfirmDialog}
        onTrueHandle={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </>
  );
}
