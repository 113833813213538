import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

const StartPopOrderContext = createContext([{ loading: true }, () => {}]);

export default StartPopOrderContext;

export const StartPopOrderProvider = ({ children }) => {
  const { t } = useTranslation();
  const orderState = useState({
    address_id: 0,
    amount: "",
    validated_auto_vouchers: [],
    discount_vouchers: [],
    delivery_on: "",
    total_price: 0,
    price: 0.0,
    titleTranslations: {
      mainTitle: t("StartpopOrder"),
      voucherTitle: t("Vouchers"),
      donationTitle: t("Donation"),
    },
  });

  return (
    <StartPopOrderContext.Provider value={orderState}>
      {children}
    </StartPopOrderContext.Provider>
  );
};
