import { Grid, Paper, Box, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import AlertDialog from "./sub-components/AlertDialog";
import themeOptions from "../services/theme-default";
import UIContext from "../contexts/UIContext";
import AuthContext from "../contexts/AuthContext";
import firebase from "firebase/app";

const useStyles = makeStyles(() => themeOptions.custom.preLoggedInPages);

export default function LoginConfirmation(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [email] = useState(
    props.location.state ? props.location.state.email : null
  );
  const [firstName] = useState(
    props.location.state && props.location.state.first_name
      ? props.location.state.first_name
      : ""
  );
  const [response, setResponse] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const {
    setPasswordEmail,
    sendLoginLinkEmail,
    sendVerifyBeforeUpdateEmail,
    sendLoginVerificationEmail,
  } = useContext(AuthContext);
  const [, setUI] = useContext(UIContext);

  const resendEmail = async () => {
    setUI((prevUI) => ({ ...prevUI, username: null }));
    const request_type = props.location.state.type;
    var q = false;
    switch (request_type) {
      case "verifyemail":
      case "register":
        q = await sendLoginVerificationEmail(
          firebase.auth().currentUser,
          request_type
        );
        break;
      case "changedemail":
        q = await sendVerifyBeforeUpdateEmail(email);
        break;
      case "resetpassword":
      case "setpassword":
        q = await setPasswordEmail(email, request_type);
        break;
      case "sensitiveaction":
      default:
        q = await sendLoginLinkEmail(email, request_type);
        break;
    }
    if (q) location.reload();
  };
  useEffect(() => {
    setUI((prevUI) => ({ ...prevUI, username: null }));
    let hText = t("13a") + " " + firstName;
    switch (props.location.state.type) {
      case "changedemail":
        hText = "Confirm your new email";
        break;
      case "sensitiveaction":
        hText = "Please log in again to make changes to your account";
        break;
      case "resetpassword":
      case "setpassword":
        hText = "440";
        break;
    }
    setHeaderText(t(hText));
  }, [t, firstName, props.location.state.type]);

  return (
    <div className="fullWidth">
      <Grid container className={classes.root}>
        <Grid item sm={12} md={6} bgcolor="white">
          <Box textAlign="center" className="fullHeight">
            <Paper elevation={0} square={true} className="fullHeight">
              <Box p={5} color="primary">
                <img
                  src="images/smiley.png"
                  className={classes.smiley}
                  alt="happy face"
                />
                <Trans
                  i18nKey="13"
                  values={{
                    email: email,
                    headerText: headerText,
                  }}
                  components={{
                    1: <h2 />,
                    2: <h3 />,
                  }}
                />
                <Box mt={4}>
                  <Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setResponse({
                        message: (
                          <Trans
                            i18nKey="18"
                            values={{
                              supportPhoneNumber: t("support-phone-number"),
                            }}
                            components={{
                              resendLnk: (
                                <Link
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    resendEmail();
                                  }}
                                  rel="noreferrer"
                                  target="_blank"
                                />
                              ),
                              lnk: (
                                <Link
                                  href={"mailto:" + t("19")}
                                  rel="noreferrer"
                                  target="_blank"
                                />
                              ),
                            }}
                          />
                        ),
                        title: t("17"),
                      });
                      setOpenConfirmDialog(true);
                    }}
                  >
                    {t("I haven't received any email")}
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <AlertDialog
        type="alert"
        title={response.title}
        message={response.message}
        open={openConfirmDialog}
        onTrueHandle={() => {
          setOpenConfirmDialog(false);
        }}
        onFalseHandle={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </div>
  );
}
