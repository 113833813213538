import { useEffect } from "react";
import DateIOAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/de";
import { useTranslation } from "react-i18next";

export function customMoment() {
  const { i18n } = useTranslation();
  moment.locale(i18n.language.substring(0, 2));

  useEffect(() => {
    moment.updateLocale(i18n.language.substring(0, 2), { week: { dow: 0 } });
  }, []);

  return moment;
}

export default function CustomDateAdapter(options) {
  const adapter = new DateIOAdapter(options);

  const constructDayObject = (day) => ({ charAt: () => day });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = customMoment().weekdaysShort();
      return customWeekdays.map((day) => constructDayObject(day));
    },
  };
}
