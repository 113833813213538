import { useContext } from "react";
import SettingsContext from "../contexts/SettingsContext";

const excludedRegions = [
  {
    value: "Schleswig-Holstein",
    adminArea: "google_maps_admin_area_1",
  },
];
export const useAddressUtils = () => {
  const settings = useContext(SettingsContext);
  const checkAddress = (address) => {
    let valid = true;
    if (!address) {
      return false;
    }
    excludedRegions.forEach((region) => {
      if (
        address[region.adminArea] == region.value &&
        settings &&
        settings.startpop.osmiaTypeSeason &&
        settings.startpop.osmiaTypeSeason.toLowerCase() == "cornuta"
      ) {
        valid = false;
      }
    });
    return valid;
  };

  return {
    checkAddress,
  };
};
