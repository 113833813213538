import React, { useContext, useEffect, useState, Fragment } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import history from "../services/history";
import { Trans, useTranslation } from "react-i18next";
import StartpopOrderContext from "../contexts/StartpopOrderContext";
import StartpopOrderSideBar from "./StartpopOrderSideBar";
import { ChevronRight } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import WBPPage from "./sub-components/WBPPage";
import WBPAddressText from "./sub-components/WBPAddressText";
import AddressModal from "./modals/AddressModal";
import { useMount } from "react-use";

import API from "../services/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UIContext from "../contexts/UIContext";
import SettingsContext, {
  StartpopSettingsContext,
} from "../contexts/SettingsContext";
import { useAddressUtils } from "../hooks/AddressUtils.hook";
import themeOptions from "../services/theme-default";
import WBPInfoBox from "./sub-components/WBPInfoBox";
import moment from "moment";

const StartpopDeliveryAddress = () => {
  const { t } = useTranslation();
  const [startpopOrder, setStartpopOrder] = useContext(StartpopOrderContext);
  const [user, , getUser] = useContext(UserContext);
  const [modalOpenStatus, setModalOpenStatus] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [, , loader] = useContext(UIContext);
  useContext(StartpopSettingsContext);
  const [allAddresses, setAllAddresses] = useState(user.addresses);
  const { checkAddress } = useAddressUtils();

  const mainSettings = useContext(SettingsContext);
  const [, setDisplayWarningCountry] = useState("CH");

  useEffect(() => {
    if (!startpopOrder.address_id) {
      let addressId = checkAddress(user.addresses[0])
        ? user.addresses[0].id
        : null;
      for (let i = 0; i < user.addresses.length; i++) {
        if (checkDefault(user.addresses[i])) {
          addressId = checkAddress(user.addresses[i])
            ? user.addresses[i].id
            : null;
        }
      }
      setStartpopOrder((prevStartpopOrder) => ({
        ...prevStartpopOrder,
        address_id: addressId,
      }));
    } else {
      const updatedAddress = user.addresses.find((address) => {
        for (let compareAddress of allAddresses) {
          if (
            address.address_1 === compareAddress.address_1 &&
            address.address_2 === compareAddress.address_2 &&
            address.company_name === compareAddress.company_name &&
            address.country_code === compareAddress.country_code &&
            address.first_name === compareAddress.first_name &&
            address.id === compareAddress.id &&
            address.last_name === compareAddress.last_name &&
            address.postal_code === compareAddress.postal_code &&
            address.town === compareAddress.town
          ) {
            return false;
          }
        }
        return true;
      });
      if (updatedAddress && checkAddress(updatedAddress)) {
        setStartpopOrder((prevStartpopOrder) => ({
          ...prevStartpopOrder,
          address_id: Number(updatedAddress.id),
        }));
      }
      setAllAddresses(user.addresses);
    }
  }, [user.addresses, startpopOrder.address_id, setStartpopOrder]);

  const checkDefault = (address) => {
    if (address.tags.indexOf("BILLING") > -1 && checkAddress(address)) {
      return true;
    }
    return false;
  };

  useMount(() => {
    if (!startpopOrder.amount) {
      history.push("/startpops");
    }
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (startpopOrder.order_date !== moment.utc().format("YYYY-MM-DD")) {
      location.reload();
    } else {
      if (mainSettings.allowed_countries.indexOf("CH") > -1) {
        setDisplayWarningCountry("CH");
      } else {
        setDisplayWarningCountry("DE");
      }
      history.push("/startpops/order-summary");
    }
  };

  const openModal = (address) => {
    setEditAddress(address);
    setModalOpenStatus(true);
  };

  const refreshUser = () => {
    loader.addTask("REFRESH_USER");
    getUser()
      .then(() => {
        if (
          checkAddress(
            user.addresses.find(
              (address) => address.id === startpopOrder.address_id
            )
          ) !== true
        ) {
          const prevStartpopOrder = { ...startpopOrder };
          delete prevStartpopOrder.address_id;
          setStartpopOrder(prevStartpopOrder);
        }
      })
      .finally(() => {
        loader.clearTask("REFRESH_USER");
      });
  };

  const closeModal = () => {
    setModalOpenStatus(false);
    refreshUser();
  };

  const newAddress = () => {
    const contact = user.billing_contact;
    const addressData = {};
    if (contact) {
      addressData.first_name = contact.first_name;
      addressData.last_name = contact.last_name;
    }

    if (user.addresses.length === 0 || !user.billing_address) {
      addressData.tags = ["BILLING"];
    }

    openModal(addressData);
  };

  const editItem = (ev, row) => {
    openModal(row);
  };

  const deleteItem = (ev, rowID) => {
    API.delete(`me/addresses/` + rowID).then(refreshUser);
  };

  return (
    <StartpopOrderSideBar step={2}>
      <AddressModal
        address={editAddress}
        organisationId={user.organisation ? user.organisation.id : ""}
        open={modalOpenStatus}
        onClose={closeModal}
      />
      <WBPPage
        title={t("481")}
        description={
          <Trans
            i18nKey="482"
            components={{
              header: <strong />,
            }}
          />
        }
      >
        <form onSubmit={submitHandler}>
          <div hidden={user.addresses.length === 0}>
            <WBPInfoBox
              title={t("483")}
              customColor="transparent"
              customPadding="0px"
            >
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  aria-label="address"
                  id="address_id"
                  name="address_id"
                  value={startpopOrder.address_id}
                >
                  {user.addresses.map((addr, ind) => (
                    <Fragment key={ind}>
                      <Box
                        display="flex"
                        p={1}
                        spacing={9}
                        borderRadius="4px"
                        border={
                          Number(startpopOrder.address_id) === Number(addr.id)
                            ? "solid 0.5px " + themeOptions.palette.primary.main
                            : ""
                        }
                      >
                        <Box
                          FormControlLabel
                          p={1}
                          flexGrow={1}
                          onClick={() => {
                            setStartpopOrder({
                              ...startpopOrder,
                              address_id: Number(addr.id),
                            });

                            if (
                              !checkAddress(addr) &&
                              mainSettings.allowed_countries.indexOf("DE") > -1
                            ) {
                              setDisplayWarningCountry("DE");
                            }
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <FormControlLabel
                            key={ind}
                            value={addr.id}
                            control={<Radio />}
                            label={<WBPAddressText address={addr} />}
                            disabled={!checkAddress(addr)}
                            checked={
                              Number(startpopOrder.address_id) ===
                              Number(addr.id)
                            }
                          />
                        </Box>
                        <Box p={1}>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            color="secondary"
                            onClick={(event) => editItem(event, addr)}
                            size="small"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <span
                            hidden={
                              (user.billing_address &&
                                Number(user.billing_address.id) ===
                                  Number(addr.id)) ||
                              Number(startpopOrder.address_id) ===
                                Number(addr.id)
                            }
                          >
                            <br />
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={(event) => deleteItem(event, addr.id)}
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </Box>
                      </Box>
                    </Fragment>
                  ))}
                </RadioGroup>
              </FormControl>
            </WBPInfoBox>
          </div>
          <div hidden={user.addresses.length > 0}>
            <Grid container alignItems="stretch" alignContent="center">
              <Typography variant="body1">
                {t("No address registered, please add one")}
              </Typography>
            </Grid>
          </div>
          <Box my={4}>
            <Button
              id="button-startpops-deliveryaddress-addanewaddress"
              variant="outlined"
              color="primary"
              onClick={newAddress}
              disableElevation
              fullWidth
            >
              {t("Add a new address")}
            </Button>
          </Box>
          <Box my={4}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <Button
                  id="button-startpops-deliveryaddress-back"
                  variant="outlined"
                  color="primary"
                  onClick={history.goBack}
                  fullWidth
                >
                  {t("404")}
                </Button>
              </Grid>
              <Grid item xs={6} sm>
                <Button
                  id="button-startpops-deliveryaddress-submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRight />}
                  disabled={!startpopOrder.address_id}
                  type="submit"
                  fullWidth
                  disableElevation
                >
                  {t("144")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </WBPPage>
    </StartpopOrderSideBar>
  );
};

export default StartpopDeliveryAddress;
