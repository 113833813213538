import {
  Box,
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from "@mui/material";
import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import StartpopOrderContext from "../contexts/StartpopOrderContext";
import { StartpopSettingsContext } from "../contexts/SettingsContext";
import { ExpandLess, Redeem } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import references from "../services/references";
import DonationSummary from "./sub-components/DonationSummary";

const useStyles = makeStyles((theme) => ({
  fixMargin: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 0 10px  !important",
    },
    position: "relative !important",
  },
  sidebarRoot: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      background: theme.palette.background.default,
      width: "100%",
      zIndex: 2,
    },
    "& .MuiBadge-badge": {
      backgroundColor: "rgba(114,114,114,0.9)",
      color: "#fff",
    },
  },
  goldPaper: {
    background: theme.palette.background.default,
  },
  accordionSummary: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "row-reverse",
    },
  },
  thumbnail: {
    width: "4.6em",
    height: "4.6em",
    borderRadius: "8px",
    background: "#fff",
    position: "relative",
  },
  thumbnailIcon: {
    fontSize: "4.6em",
    borderRadius: "8px",
    background: "#fff",
    position: "relative",
    display: "none",
  },
  names: {
    marginBottom: "25px",
  },
  totalPrice: {
    fontSize: "large",
  },
  primaryText: {
    fontSize: "initial",
  },
  secondaryText: {
    fontSize: "inherit",
    color: theme.palette.text.secondary,
  },
}));

export default function StartpopOrderSideBar({ children, step }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [startpopOrder] = useContext(StartpopOrderContext);
  const settings = useContext(StartpopSettingsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const minimumPrice = references.defaultOrders.startpop.minimum_price;
  const titleTranslations = startpopOrder.titleTranslations;

  const summaryBody = () => {
    return (
      <Box px={0} m={0} pb={10} borderRadius="0px">
        {startpopOrder.amount > 0 && startpopOrder.price >= minimumPrice && (
          <Box mb={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs alignItems="center">
                <Box
                  display="flex"
                  flexWrap="nowrap"
                  justifyContent="flex-start"
                  StartpopOrderContext={{ float: "left" }}
                >
                  <Badge
                    badgeContent={startpopOrder.amount}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <img
                      className={classes.thumbnail}
                      src={
                        settings && settings.shopify && settings.shopify.product
                          ? settings.shopify.product.image
                          : "/images/startpopulation.png"
                      }
                    />
                  </Badge>
                </Box>
              </Grid>
              <Grid
                container
                item
                direction="row"
                style={{
                  marginLeft: 5,
                }}
                xs
                alignItems="center"
              >
                <Grid item xs={12}>
                  <span className={classes.primaryText}>
                    <strong>
                      <Trans i18nKey={titleTranslations.mainTitle} />
                    </strong>
                  </span>
                </Grid>
                <Grid item xs>
                  <span className={classes.secondaryText}>
                    (
                    {t(
                      settings.osmiaTypeSeason === "bicornis"
                        ? "Osmia bicornis"
                        : "Osmia cornuta"
                    )}
                    )
                  </span>
                </Grid>
              </Grid>
              <Grid item xs>
                <Box
                  className={classes.primaryText}
                  sx={{ textAlign: "right", float: "right" }}
                >
                  <strong>
                    {(Number(startpopOrder.price) * startpopOrder.amount)
                      .toFixed(2)
                      .toString()
                      .replace(".", ",") +
                      " " +
                      settings.currency}
                  </strong>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {Object.keys(startpopOrder.discount_vouchers).length > 0 && (
          <Box mb={2}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={4} alignItems="center" textAlign="start">
                <Badge color="primary" overlap="circular">
                  <Redeem className={classes.thumbnailIcon} />
                </Badge>
              </Grid>
              <Grid
                container
                item
                direction="row"
                xs
                style={{
                  marginLeft: 5,
                }}
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <span className={classes.primaryText}>
                    <strong>{titleTranslations.voucherTitle}</strong>
                  </span>
                </Grid>
                <Grid item xs>
                  <span className={classes.secondaryText}>
                    {startpopOrder.discount_vouchers
                      .map((a) => a.code)
                      .join(", ")}
                  </span>
                </Grid>
              </Grid>
              <Grid item xs>
                <Box
                  className={classes.primaryText}
                  sx={{ textAlign: "right", float: "right" }}
                >
                  <strong>
                    {"- " +
                      parseFloat(
                        startpopOrder.discount_vouchers.length *
                          startpopOrder.price
                      ).toFixed(2) +
                      " " +
                      settings.currency}
                  </strong>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {startpopOrder.donation > 0 && (
          <Box mb={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs alignItems="center">
                <Box
                  display="flex"
                  flexWrap="nowrap"
                  justifyContent="flex-start"
                  StartpopOrderContext={{ float: "left" }}
                >
                  <Badge
                    badgeContent={1}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <img
                      className={classes.thumbnail}
                      src={
                        settings &&
                        settings.shopify &&
                        settings.shopify.donationOptions
                          ? settings.shopify.donationOptions.image
                          : "/images/startpopulation.png"
                      }
                    />
                  </Badge>
                </Box>
              </Grid>
              <Grid
                container
                item
                direction="row"
                style={{
                  marginLeft: 5,
                }}
                xs
                alignItems="center"
              >
                <Grid item xs={12}>
                  <span className={classes.primaryText}>
                    <strong>{titleTranslations.donationTitle}</strong>
                  </span>
                </Grid>
              </Grid>
              <Grid item xs>
                <Box
                  className={classes.primaryText}
                  sx={{ textAlign: "right", float: "right" }}
                >
                  <strong>
                    {Number(startpopOrder.donation)
                      .toFixed(2)
                      .toString()
                      .replace(".", ",") +
                      " " +
                      settings.currency}
                  </strong>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Divider></Divider>
        <Grid container alignItems="flex-end" justifyContent="space-between">
          <Grid item>
            <span className={classes.primaryText}>
              <strong>{t("Total")}</strong>
            </span>
          </Grid>
          <Grid item>
            <span className={classes.totalPrice}>
              <strong>
                {Number(startpopOrder.total_price)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",") +
                  "  " +
                  settings.currency}
              </strong>
            </span>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item md={8} xs={12} className="fullHeight">
        {children}
      </Grid>
      <Grid item md={4} xs={12} className={classes.sidebarRoot}>
        <Box
          m={3}
          className={
            isMobile
              ? `${classes.goldPaper} ${classes.fixMargin}`
              : `${classes.fixMargin}`
          }
          sx={{ position: !isMobile ? "fixed" : "" }}
        >
          {isMobile ? (
            <Accordion className={classes.goldPaper} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandLess />}
                className={classes.accordionSummary}
                aria-controls="panel1a-content"
                id="orderSummary-header"
                elevation={0}
              >
                <Typography variant="h6">{t("Order summary")}</Typography>
              </AccordionSummary>
              <AccordionDetails>{summaryBody()}</AccordionDetails>
            </Accordion>
          ) : (
            <div>
              {step < 3 && summaryBody()}
              {step === 3 && <DonationSummary />}
            </div>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
