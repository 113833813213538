import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, List, ListItemText, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import history from "../../services/history";
import UserContext from "../../contexts/UserContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { ConfiguredListItem } from "../../StyledComponents";
import UIContext from "../../contexts/UIContext";
import API from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menu: {
    height: "100%",
  },
}));

export default function BeeHomeMenu() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [user] = useContext(UserContext);
  const [menuOptions, setMenuOptions] = useState([
    {
      name: "498",
      path: "/",
    },
    {
      name: "237",
      path: "/mybeehomes",
      submenu: [{ name: "Startpopulation", path: "/startpops" }],
    },
    { name: "Shop", loginHome: "Shopify" },
  ]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const [ui, setUI] = useContext(UIContext);

  const handleMenuItemClick = (event, index, selectedOption) => {
    if (selectedOption.path) {
      history.push({
        pathname: selectedOption.path,
        state: {
          from: history.location.pathname,
        },
      });
    } else if (selectedOption.urLName) {
      window.open(t(selectedOption.urLName), "_blank");
    } else if (selectedOption.loginHome) {
      if (selectedOption.loginHome === "Shopify") {
        let shopifyURL = JSON.parse(process.env.REACT_APP_SHOPIFY_URL)[
          i18n.language
        ];
        let connectionString =
          "me/shopify-login?return_to=" + shopifyURL + "collections";
        if (i18n.language.indexOf("fr") > -1) {
          connectionString += "?locale=fr";
        }

        API.get(connectionString).then((response) => {
          window.open(response.data.url, "_blank");
        });
      }
    }
    setUI({ ...ui, menuOpen: false });
  };

  const selectedMenuOption = (option) => {
    if (history.location.pathname === option.path) {
      return true;
    }

    if (option.submenu) {
      for (let i = 0; i < option.submenu.length; i++) {
        const subOption = option.submenu[i];
        if (
          history.location.pathname
            .toLowerCase()
            .indexOf(subOption.path.toLowerCase()) > -1
        ) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (user.organisation && Number(user.organisation.id) === 1) {
      const menuArray = [
        {
          name: "498",
          path: "/",
        },
        {
          name: "237",
          path: "/mybeehomes",
          submenu: [
            { name: "Startpopulation", path: "/startpops" },
            { name: "Send in box", path: "/sendinbox" },
            { name: "BeeCare Status", path: "/beecarestatus" },
          ],
        },
        { name: "Shop", loginHome: "Shopify" },
        { name: "Statistics", urLName: "Statistics Link" },
      ];
      setMenuOptions(menuArray);
    }
  }, [user]);

  return (
    <Paper className={`${classes.root} ${classes.menu}`} square={true}>
      <List
        component="nav"
        className={classes.root && !isMobile ? classes.menu : ""}
        aria-label="menu"
      >
        {menuOptions.map((option, index) => (
          <span key={index}>
            <ConfiguredListItem
              id={
                "button-beehomemenu-" +
                option.name.toLowerCase().replaceAll(" ", "")
              }
              button
              selected={selectedMenuOption(option)}
              onClick={(event) => handleMenuItemClick(event, index, option)}
            >
              <ListItemText primary={t(option.name)} />
            </ConfiguredListItem>
            {option.submenu && (
              <Collapse
                key={option.name}
                in={true}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {option.submenu.map((submenuOption) => (
                    <ConfiguredListItem
                      id={
                        "button-beehomemenu-" +
                        submenuOption.name.toLowerCase().replaceAll(" ", "")
                      }
                      button
                      key={submenuOption.name}
                      className={classes.nested}
                      selected={
                        history.location.pathname
                          .toLowerCase()
                          .indexOf(submenuOption.path.toLowerCase()) > -1
                      }
                      onClick={(event) =>
                        handleMenuItemClick(event, index, submenuOption)
                      }
                      display="none"
                    >
                      <ListItemText primary={t(submenuOption.name)} />
                    </ConfiguredListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </span>
        ))}
      </List>
    </Paper>
  );
}
