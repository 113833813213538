import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

export default function LocaleSelector(props) {
  const {
    match: { params },
  } = props;
  const [, i18n] = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(
      params.lang.toLowerCase() + "-" + params.country.toUpperCase()
    );
  }, [i18n, params]);
  return <Redirect to="/" />;
}
