import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import firebase from "firebase/app";
import "firebase/auth";
import history from "../services/history";
import firebaseConfig from "../services/firebaseConfig";
import UIContext from "./UIContext";

import { useAuth } from "../hooks/Auth.hook";
import { useTranslation } from "react-i18next";
import AlertDialog from "../components/sub-components/AlertDialog";

const AuthContext = createContext([{ loading: true }, () => {}]);

export default AuthContext;

if (!firebase.apps.length) {
  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const AuthProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [authData, setAuthData] = useState(null);
  const [protect, setProtect] = useState(false);
  const [returnTo, setReturnTo] = useState(null);
  const [, , loader] = useContext(UIContext);
  const { getBeeAdminID, signupExisting, signup } = useAuth();
  const [alertMessage, setAlertMessage] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  //This function loops through the Children of the authorisation component and determines weather any of them are the active route.
  const authListener = useCallback(
    async (user) => {
      if (user && protect) {
        loader.addTask("AUTH");
        const token = await firebase.auth().currentUser.getIdToken();
        /*
      If the token contains an ID for BeeAdmin use it
      else call the signupExisting end point
      */
        if (getBeeAdminID(token)) {
          setAuthData({ ...user });
          loader.clearTask("AUTH");
        } else {
          /*
        signup an existing user with beeAdmin,
        if successful update the firebase token(triggering this function to run again)
        else redirect to signup
        */
          try {
            await signupExisting();
            await user.getIdToken(true);
            loader.clearTask("AUTH");
          } catch {
            const existingData = localStorage.getItem("signupData");
            const userAuth = firebase.auth().currentUser;
            userAuth
              .reload()
              .then(() => {
                if (userAuth.emailVerified === false) {
                  sendLoginVerificationEmail(userAuth, "verifyemail");
                } else {
                  if (existingData) {
                    localStorage.removeItem("signupData");
                    signup(JSON.parse(existingData));
                  } else {
                    history.push({
                      pathname: "/signup",
                      state: { email: user.email },
                    });
                  }
                }
              })
              .finally(loader.clearTask("AUTH"));
          }
        }
      } else {
        history.push("/login");
        loader.clearTask("AUTH");
      }
    },
    [getBeeAdminID, protect, signupExisting, loader, signup]
  );

  useEffect(() => {
    if (i18n.language) {
      firebase.auth().languageCode = i18n.language;
    }
  }, [i18n.language]);

  const prepareActionCodeSettings = () => {
    let continueURL = process.env.REACT_APP_BASE_URL + "/loginverify";
    continueURL += returnTo !== null ? "?return_to=" + returnTo : "";
    return {
      url: continueURL,
      handleCodeInApp: true,
    };
  };

  const emailSuccess = (email, request_type = null) => {
    window.localStorage.setItem("emailForSignIn", email);
    history.push({
      pathname: "/loginconfirmation",
      state: { email: email, type: request_type },
    });
    return true;
  };

  const emailFailed = (error) => {
    const errorMessage = error.message;
    console.error("email failed", errorMessage);
    setOpenConfirmDialog(true);
    setAlertMessage(t("23"));
    return false;
  };

  const setPasswordEmail = useCallback(async (email, request_type) => {
    loader.addTask("Send Password Link");
    let actionCodeSettings = prepareActionCodeSettings();
    actionCodeSettings.url +=
      actionCodeSettings.url.indexOf("?") > -1 ? "&" : "?";
    actionCodeSettings.url += "request_type=" + request_type;
    return await firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => emailSuccess(email, request_type))
      .catch((e) => emailFailed(e))
      .finally(() => {
        loader.clearTask("Send Password Link");
      });
  }, []);

  const sendLoginLinkEmail = useCallback(async (email, request_type = null) => {
    loader.addTask("Email Login");
    let actionCodeSettings = prepareActionCodeSettings();
    actionCodeSettings.url +=
      actionCodeSettings.url.indexOf("?") > -1 ? "&" : "?";
    actionCodeSettings.url += "email=" + email;
    actionCodeSettings.url +=
      actionCodeSettings.url.indexOf("return_to") === -1 &&
      request_type === "sensitiveaction"
        ? "&return_to=/account"
        : "";
    return await firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => emailSuccess(email, request_type))
      .catch(emailFailed)
      .finally(() => {
        loader.clearTask("Email Login");
      });
  }, []);

  const sendVerifyBeforeUpdateEmail = useCallback(async (newEmailAddress) => {
    let actionCodeSettings = prepareActionCodeSettings();
    actionCodeSettings.url +=
      actionCodeSettings.url.indexOf("?") > -1 ? "&" : "?";
    actionCodeSettings.url +=
      actionCodeSettings.url.indexOf("return_to") > -1
        ? ""
        : "&return_to=/account";
    const currentUser = firebase.auth().currentUser;
    return await currentUser
      .verifyBeforeUpdateEmail(
        newEmailAddress.trim().toLowerCase(),
        actionCodeSettings
      )
      .then(() => emailSuccess(newEmailAddress, "changedemail"))
      .catch(async (error) => {
        if (error.code === "auth/requires-recent-login") {
          console.error("email failed", error);
          setOpenConfirmDialog(true);
          setAlertMessage(t("497"));
        } else if (error.code === "auth/email-already-in-use") {
          setOpenConfirmDialog(true);
          setAlertMessage(t("227"));
        } else {
          emailFailed(error);
        }
        return false;
      });
  }, []);

  const sendLoginVerificationEmail = useCallback(
    async (userAuth, type = null) => {
      loader.addTask("Email Login");
      const actionCodeSettings = prepareActionCodeSettings();
      return await userAuth
        .sendEmailVerification(actionCodeSettings)
        .then(() => emailSuccess(userAuth.email, type))
        .catch(emailFailed)
        .finally(loader.clearTask("Email Login"));
    },
    []
  );

  useEffect(() => {
    if (protect) {
      firebase.auth().onIdTokenChanged(authListener);
    }
  }, [protect]);

  return (
    <AuthContext.Provider
      value={{
        authData,
        setProtect,
        returnTo,
        setReturnTo,
        setPasswordEmail,
        sendLoginLinkEmail,
        sendLoginVerificationEmail,
        sendVerifyBeforeUpdateEmail,
      }}
    >
      {children}
      <AlertDialog
        type="alert"
        message={t(alertMessage)}
        yesButtonText={t("OK")}
        open={openConfirmDialog}
        onTrueHandle={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </AuthContext.Provider>
  );
};
