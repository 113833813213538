/*
This component is linked to by the login emails.
It needs to check if the correct info is associated with the request and also that there is a signinemail cookie.
If the cookie is not pressent it should show a form field to confirm the users email.
This ensures that the site is not being inadvertantly/maliciously accessed.
*/

import React, { useContext, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import history from "../services/history";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import AlertDialog from "./sub-components/AlertDialog";
import UIContext from "../contexts/UIContext";
import AuthContext from "../contexts/AuthContext";
import { useMount } from "react-use";

const useStyles = makeStyles(() => ({
  root: {
    border: "5px solid white",
    height: "100%",
    padding: 0,
    flexGrow: 1,
    minHeight: "calc( 100vh - 200px)",
  },
  hcFinder: {
    width: "90%",
    maxWidth: "200px",
  },
  beeIcon: {
    maxWidth: "40px",
  },
  divider: {
    backgroundColor: "white",
    padding: "4px",
    color: "#666",
  },
  rule: {
    marginTop: "-8px",
    color: "#666",
  },
}));

export default function LoginVerify() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [needsVerification, setNeedsVerification] = useState(false);
  const [response, setResponse] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [, , , sendAlert] = useContext(UIContext);
  const { setReturnTo } = useContext(AuthContext);

  const completeVerification = useCallback(
    (emailForSignIn) => {
      firebase
        .auth()
        .signInWithEmailLink(emailForSignIn, window.location.href)
        .then((result) => {
          const goto = localStorage.getItem("goto");
          var pendingCred = JSON.parse(
            localStorage.getItem("pendingCredential")
          );
          if (!pendingCred) {
            history.push(!goto ? "/" : goto);
          } else {
            const fbCred = firebase.auth.FacebookAuthProvider.credential({
              accessToken: pendingCred.oauthAccessToken,
            });
            result.user
              .linkWithCredential(fbCred)
              .then(function () {
                // Facebook account successfully linked to the existing Firebase user.
                history.push(!goto ? "/" : goto);
              })
              .catch((error) => {
                console.error("error", error);
                localStorage.removeItem("pendingCredential");
              });
          }
          // Clear email from storage.
          localStorage.removeItem("pendingCredential");
          localStorage.removeItem("emailForSignIn");
          localStorage.removeItem("goto");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          if (error.code && error.code === "auth/invalid-action-code") {
            sendAlert(t("Your link has expired Please sign in again"));
            history.push("/");
          }
          setResponse({
            ...error,
            message:
              "E-Mail address does not match please enter the email address you used to sign in Please try again",
            title: "",
          });

          setOpenConfirmDialog(true);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    },
    [sendAlert, t]
  );

  const processEmailLink = (emailAddress) => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const actionCodeInfo = firebase.auth.ActionCodeURL.parseLink(
        window.location.href
      );
      firebase
        .auth()
        .checkActionCode(actionCodeInfo.code)
        .then(() => {
          let emailForSignIn = window.localStorage.getItem("emailForSignIn");
          if (emailForSignIn) {
            completeVerification(emailForSignIn);
          } else if (emailAddress) {
            completeVerification(emailAddress);
          } else {
            setNeedsVerification(true);
          }
        })
        .catch(() => {
          sendAlert(t("Your link has expired Please sign in again"));
          history.push("/");
        });
    }
  };

  useMount(() => {
    const searchParam = window.location.search
      ? window.location.search
      : history.location.search;
    const urlParams = new URLSearchParams(searchParam);
    const continueUrl = urlParams.has("continueUrl");
    if (continueUrl) {
      var returnTo = new URL(urlParams.get("continueUrl")).searchParams.get(
        "return_to"
      );
      setReturnTo(returnTo);
    }
    const actionCode = urlParams.get("oobCode");
    const mode = urlParams.get("mode");
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        var requestType = new URL(
          urlParams.get("continueUrl")
        ).searchParams.get("request_type");
        handleResetPassword(actionCode, requestType);
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        handleRecoverEmail(actionCode);
        break;
      case "verifyAndChangeEmail":
        handleVerifyChangedEmail(actionCode);
        break;
      case "verifyEmail":
        handleVerifyEmail(actionCode);
        break;
      case "signIn": {
        const continueURLString = urlParams.get("continueUrl");
        console.log(continueURLString);

        if (continueURLString) {
          try {
            const continueURL = new URL(continueURLString);
            const email = continueURL.searchParams.get("email");

            if (email) {
              processEmailLink(email);
            } else {
              processEmailLink();
            }
          } catch (error) {
            console.error("Invalid URL:", error);
            processEmailLink();
          }
        } else {
          processEmailLink();
        }
        break;
      }
    }
  });

  function handleVerifyEmail(actionCode) {
    firebase
      .auth()
      .applyActionCode(actionCode)
      .then(history.push("/"))
      .catch((error) => {
        console.error("Error - On Verification", error);
      });
  }

  function handleVerifyChangedEmail(actionCode) {
    firebase
      .auth()
      .checkActionCode(actionCode)
      .then((info) => {
        setEmail(info["data"]["email"]);
        firebase.auth().languageCode = i18n.language;
        return firebase.auth().applyActionCode(actionCode);
      })
      .then(() => {
        setResponse((previousResponse) => ({
          ...previousResponse,
          message: "469",
          title: "468",
        }));
        setOpenConfirmDialog(true);
      })
      .catch((error) => {
        console.error("Error - On Verification", error);
      });
  }

  function handleResetPassword(actionCode, requestType) {
    // Verify the password reset code is valid.
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        history.push({
          pathname: requestType,
          state: {
            email: email,
            actionCode: actionCode,
          },
        });
      })
      .catch((error) => {
        console.error("Reset Password Error - On Verification", error);
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  function handleRecoverEmail(actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // let restoredEmail = null;
    // Confirm the action code is valid.
    firebase
      .auth()
      .checkActionCode(actionCode)
      .then((info) => {
        setEmail(info["data"]["email"]);
        // Revert to the old email.
        firebase.auth().languageCode = i18n.language;
        return firebase.auth().applyActionCode(actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail
        // TODO: Display a confirmation message to the user.

        setResponse((previousResponse) => ({
          ...previousResponse,
          message: "469",
          title: "468",
        }));
        setOpenConfirmDialog(true);
      })
      .catch((error) => {
        console.error(error);
        // Invalid code.
      });
  }

  const changeEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  return (
    <Grid container className={classes.root}>
      {needsVerification && (
        <Grid item sm={12} md={6} bgcolor="white">
          <Box textAlign="center" className="fullHeight">
            <Paper elevation={0} square={true} className="fullHeight">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  completeVerification(email);
                }}
              >
                <Box p={4}>
                  <img
                    src="images/bee-icon.png"
                    alt="Wildbiene + Partner"
                    className={classes.beeIcon}
                  />
                  <Typography variant="h3" color="primary" className="preLine">
                    {t("Welcome back")}
                  </Typography>
                  <Box py={2} textAlign="left">
                    <Typography variant="body2">
                      {t("Please confirm your email address")}
                    </Typography>
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      label={t("Email")}
                      variant="outlined"
                      value={email}
                      onChange={changeEmail}
                    ></TextField>
                  </FormControl>
                  <Box my={3}>
                    <Button
                      id="button-loginverify-submit"
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {t("Confirm")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Paper>
          </Box>
        </Grid>
      )}
      <AlertDialog
        type="alert"
        title={t(response.title)}
        message={t(response.message)}
        yesButtonText={t(response.message === "469" ? "438" : "OK")}
        open={openConfirmDialog}
        onTrueHandle={() => {
          setOpenConfirmDialog(false);
          history.push(email ? "/login?email=" + email : "login");
        }}
      />
    </Grid>
  );
}
