import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import history from "../services/history";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import UIContext from "../contexts/UIContext";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import references from "../services/references";
import themeOptions from "../services/theme-default";
import AuthContext from "../contexts/AuthContext";

const useStyles = makeStyles(() => themeOptions.custom.preLoggedInPages);

export default function SetPassword() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentRoute = history.location.pathname.replace("/", "");
  const routeStatus = {
    register: {
      texts: {
        title: "452",
        description: "Please enter your new password",
      },
    },
    setpassword: {
      texts: {
        title: "451",
        description: "Please enter a new password and click submit",
      },
    },
    resetpassword: {
      texts: {
        title: "450",
        description: "Please enter a new password and click submit",
      },
    },
  };
  const [email, setEmail] = useState("");
  const [actionCode, setActionCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [, , loader] = useContext(UIContext);
  const [displayError, setDisplayError] = useState({});
  const passwordRegEx = references.allowedRegex.password;
  const { sendLoginVerificationEmail } = useContext(AuthContext);
  const passwordRef = useRef("");
  const confirmPasswordRef = useRef("");

  useEffect(() => {
    if (history.location.state && history.location.state.email) {
      setEmail(history.location.state.email);
      if (history.location.state.actionCode) {
        setActionCode(history.location.state.actionCode);
      }
    } else {
      history.goBack();
    }
  }, [history.location.state]);

  const submitPassword = (e) => {
    e.preventDefault();
    const hText = helperText();
    if (Object.keys(hText).length === 0) {
      const confirmPassword = confirmPasswordRef.current.value;
      loader.addTask("Loading Password");
      firebase.auth().languageCode = i18n.language;
      switch (currentRoute) {
        case "register":
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, confirmPassword)
            .then(verifyUser)
            .catch((e) => {
              console.error("error", e);
            })
            .finally(loader.clearTask("Loading Password"));
          break;
        case "setpassword":
        case "resetpassword":
          // Save the new password.
          firebase
            .auth()
            .confirmPasswordReset(actionCode, confirmPassword)
            .then(verifyUser)
            .catch((error) => {
              console.error("Confirm Password Set - error", error);
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
            })
            .finally(loader.clearTask("Loading Password"));

          break;
        default:
          loader.clearTask("Loading Password");
          break;
      }
    }
  };

  const verifyUser = (response) => {
    let user = null,
      isNewUser = false;
    if (response) {
      user = response.user;
      isNewUser = response.additionalUserInfo.isNewUser;
    }

    if (isNewUser || (user && user.emailVerified === false)) {
      sendLoginVerificationEmail(user, currentRoute);
    } else if (user && user.emailVerified === true) {
      history.push("/passwordsuccess");
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, confirmPasswordRef.current.value)
        .then(() => {
          let unsubscribe = firebase.auth().onIdTokenChanged(() => {
            unsubscribe();
            history.push("/passwordsuccess");
          });
        });
    }
  };

  const helperText = (e = null) => {
    let txt = {};
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    if (e === null) {
      if (!password) {
        txt.password = t("442");
      } else if (!passwordRegEx.test(password)) {
        txt.password = t("444");
      }

      if (!confirmPassword) {
        txt.confirmPassword = t("443");
      } else if (password !== confirmPassword) {
        txt.confirmPassword = t("445");
      }
    } else {
      const fieldValue = e.target.value.trim();
      switch (e.target.id) {
        case "password":
          if (!fieldValue) {
            txt.password = t("442");
          } else if (!passwordRegEx.test(fieldValue)) {
            txt.password = t("444");
          }
          break;
        case "confirmPassword":
          if (!fieldValue) {
            txt.confirmPassword = t("443");
          } else if (password !== fieldValue) {
            txt.confirmPassword = t("445");
          }
          break;
      }
    }
    setDisplayError(txt);
    return txt;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={6} bgcolor="white">
        <Box textAlign="center" className="fullHeight">
          <Paper elevation={0} square={true} className="fullHeight">
            <form onSubmit={submitPassword}>
              <Box p={4}>
                <img
                  src="images/bee-icon.png"
                  alt="Wildbiene + Partner"
                  className={classes.beeIcon}
                />
                <Typography variant="h3" color="primary" className="preLine">
                  {t(routeStatus[currentRoute].texts.title)}
                </Typography>
                <Box
                  pt={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  {email}
                </Box>
                <Box py={1}>
                  <TextField
                    id={"password"}
                    inputRef={passwordRef}
                    type={showPassword ? "text" : "password"}
                    label={t("433")}
                    variant="outlined"
                    onChange={helperText}
                    InputProps={{
                      autoFocus: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPassword(
                                (previousValue) => !previousValue
                              );
                            }}
                          >
                            {showPassword ? (
                              <VisibilityOutlined color="primary" />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      ("password" in displayError && displayError.password) ||
                      " "
                    }
                    error={"password" in displayError}
                    fullWidth
                  ></TextField>
                </Box>
                <Box py={1}>
                  <TextField
                    id={"confirmPassword"}
                    inputRef={confirmPasswordRef}
                    type={showConfirmPassword ? "text" : "password"}
                    label={t("441")}
                    variant="outlined"
                    onChange={helperText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setShowConfirmPassword(
                                (previousValue) => !previousValue
                              );
                            }}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOutlined color="primary" />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      ("confirmPassword" in displayError &&
                        displayError.confirmPassword) ||
                      " "
                    }
                    error={"confirmPassword" in displayError}
                    fullWidth
                  ></TextField>
                </Box>
                <Box my={2}>
                  <Button
                    id="button-setpassword-submit"
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={Object.keys(displayError).length > 0}
                    size="large"
                    disableElevation
                  >
                    {t("406")}
                  </Button>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
