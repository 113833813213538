import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { customMoment } from "../../services/customDateAdapter";
import themeOptions from "../../services/theme-default";
import { useTranslation } from "react-i18next";

export default function DateToolBar(propsData) {
  const moment = customMoment();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  useEffect(() => {
    if (propsData.selectedDate) {
      setSelectedDate(moment(propsData.selectedDate));
    }
  }, [propsData.selectedDate]);
  return (
    <Box
      px={4}
      bgcolor="primary.main"
      color={themeOptions.palette.primary.contrastText}
      paddingTop={3}
      paddingBottom={3}
    >
      <Typography
        variant="h4"
        color={themeOptions.palette.primary.contrastText}
      >
        {t("499")}
      </Typography>
      <Typography variant="h4" color="rgba(255, 255, 255, 0.54)">
        {String(selectedDate.date()).padStart(2, "0") +
          "." +
          String(selectedDate.month() + 1).padStart(2, "0") +
          "." +
          selectedDate.year()}
      </Typography>
    </Box>
  );
}
