import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";
import { Link } from "react-router-dom";

export default function BeeCareStatusCocoons({ value, displayStepDetails }) {
  const { t } = useTranslation();

  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return value.beecare.step3 ? (
    <>
      <WBPBeeCareStatusInfoBox
        selectedStep={value.beecare.selectedStep}
        displayStepDetails={changeSelectedStep}
        title={t("Here's what we've found in your beehome - cocoons")}
        secondary
      >
        <Typography variant="body1" className="preLine">
          <Trans
            i18nKey="268"
            components={{
              lnk: (
                <Link
                  href={t("Statistics Link")}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </WBPBeeCareStatusInfoBox>
    </>
  ) : (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      title={t("261")}
      secondary
    >
      {t("262")}
    </WBPBeeCareStatusInfoBox>
  );
}
