import { customMoment } from "../services/customDateAdapter";
import references from "../services/references";
import { useTranslation } from "react-i18next";

export const useFormatter = () => {
  const { t } = useTranslation();
  const moment = customMoment();

  const dateFormat = (format_date) => {
    return format_date
      ? moment.parseZone(format_date).format("DD.MM.YYYY")
      : " - ";
  };

  const getTypeImage = (type) => {
    return (
      "/" +
      references.house_types.find(
        (ref) => ref.code === type || (type === null && ref.isDefault)
      ).image
    );
  };

  const getTypeName = (type) => {
    return t(
      references.house_types.find(
        (ref) => ref.code === type || (type === null && ref.isDefault)
      ).name
    );
  };

  return { dateFormat, getTypeImage, getTypeName };
};
