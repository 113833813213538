import {
  useTheme,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 0!important",
    marginLeft: "-16px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "24px",
    },
  },
  customSteps: {
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepIcon-root": {
      background:
        "white url(/images/step-inactive.svg) no-repeat center center / cover",
      color: "transparent",
      cursor: "pointer",
    },
    "& .Mui-completed": {
      background:
        "white url(/images/step-complete.svg) no-repeat center center / cover",
    },
    "& .Mui-active": {
      background:
        "white url(/images/step-active-bee.svg) no-repeat center center / cover",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      margin: "16px 0",
      padding: 0,
      minWidth: "95px",
      fontWeight: "normal",
      background: "transparent",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("lg")]: {
        position: "absolute",
        marginLeft: "-24px",
      },
      [theme.breakpoints.only("sm")]: {
        marginLeft: 0,
      },
    },
  },
  selectedStep: {
    "& .MuiStepIcon-root": {
      border: "solid",
      borderColor: "#d6d6d6",
      borderRadius: "50%",
    },
  },
  firstLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginLeft: "0!important",
    },
  },
  lastLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      textAlign: "right",
      marginLeft: 0,
      right: 0,
    },
  },
}));

export default function BeeCareStatusProgressBar({
  step,
  completedSteps,
  selectedStep,
  displayStepDetails,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentTheme = useTheme();
  const isDesktop = useMediaQuery(currentTheme.breakpoints.up("lg"));
  useEffect(() => {
    setActiveStep(selectedStep);
  }, [selectedStep]);
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    active: {
      "& $line": {
        borderColor: currentTheme.palette.primary.main,
      },
    },
    completed: {
      "& $line": {
        borderColor: currentTheme.palette.primary.main,
      },
    },
    line: {
      borderColor: "#d6d6d6",
      borderTopWidth: 2,
    },
  })(StepConnector);

  const steps = [
    { name: "Inbound", iconText: " " },
    { name: "Tube analysis", iconText: "A" },
    { name: "Cocoon analysis", iconText: "B" },
    { name: "Refill", iconText: "C" },
    { name: "248", iconText: "D" },
  ];

  const getLabelClass = (index) => {
    if (index === steps.length - 1 || index === steps.length - 2) {
      return classes.lastLabel;
    } else if (!index) {
      return classes.firstLabel;
    }
    return "";
  };

  return (
    <div>
      <Stepper
        id="button-beecarestatusprogressbar-stepper"
        connector={<QontoConnector />}
        alternativeLabel
        nonLinear
        className={classes.root}
      >
        {steps.map((label, index) => {
          return (
            <Step
              id={
                "button-beecarestatusprogressbar-" +
                label.name.toLowerCase().replaceAll(" ", "")
              }
              key={label.name}
              completed={
                index < 5 &&
                completedSteps &&
                completedSteps.indexOf(index) > -1
              }
              active={
                step === index ||
                (completedSteps.indexOf(index) === -1 &&
                  completedSteps.indexOf(index - 1) > -1)
              }
              className={
                index !== activeStep
                  ? classes.customSteps
                  : `${classes.customSteps} ${classes.selectedStep}`
              }
            >
              <StepLabel
                onClick={(event) => {
                  event.preventDefault();
                  displayStepDetails(index);
                  setActiveStep(index);
                }}
                className={getLabelClass(index)}
              >
                {(isDesktop || index === activeStep) && t(label.name)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
