import React from "react";
import { Paper, Box, Typography, useTheme, useMediaQuery } from "@mui/material";

export default function WBPPage({ title, description, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box p={3} style={{ width: "100%" }}>
      {isMobile ? (
        <Box p={0}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
          <Paper elevation={0}>
            <Box p={4} my={2}>
              {children}
            </Box>
          </Paper>
        </Box>
      ) : (
        <Paper elevation={0} square={true}>
          <Box p={isMobile ? 0 : 6}>
            <Typography variant="h2">{title}</Typography>
            <Box mt={2}>
              <Typography variant="body1">{description}</Typography>
            </Box>
            <Box my={4}>{children}</Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
}
