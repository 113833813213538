import { Grid, Typography, Link, useMediaQuery, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import WBPBeeCareStatusInfoBox from "../WBPBeeCareStatusInfoBox";

export default function BeeCareStatusOnItsWayBack({
  value,
  displayStepDetails,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const changeSelectedStep = (stepIndex) => {
    displayStepDetails(stepIndex);
  };

  return value.beecare.step4 && value.beecare.tracking_number ? (
    <>
      <WBPBeeCareStatusInfoBox
        selectedStep={value.beecare.selectedStep}
        displayStepDetails={changeSelectedStep}
        title={t("271")}
        showNextButton={value.lastSeason.psp !== "PAYREXX" ? true : false}
        secondary
      >
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" paragraph>
              {t("272")}
            </Typography>
            <Typography variant="body1" paragraph>
              {t("274")}
            </Typography>
            <ul className="bullets">
              <li>{t("274a")}</li>
              <li>{t("274b")}</li>
              <li>{t("274c")}</li>
            </ul>
            <Typography variant="body1" paragraph>
              <Trans
                i18nKey="274d"
                components={{
                  lnk: (
                    <Link
                      href={t("274d-url")}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            md={4}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            style={{ marginTop: isMobile ? "24px" : 0 }}
          >
            <Typography
              variant="button"
              color="textSecondary"
              align={isMobile ? "left" : "right"}
            >
              {t("298")}:
              <br />
              <Link
                href={value.beecare.tracking_url}
                rel="noreferrer"
                target="_blank"
              >
                {value.beecare.tracking_number}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </WBPBeeCareStatusInfoBox>
      <WBPBeeCareStatusInfoBox
        selectedStep={value.beecare.selectedStep}
        displayStepDetails={changeSelectedStep}
        title={t("275")}
        showNextButton={value.lastSeason.psp !== "PAYREXX" ? true : false}
        secondary
      >
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">{t("276")}</Typography>
          </Grid>
          <Grid
            container
            item
            xs
            md={4}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            style={{ marginTop: isMobile ? "24px" : 0 }}
          >
            <Link
              href={t("Statistics Link")}
              rel="noreferrer"
              target="_blank"
              align={isMobile ? "left" : "right"}
            >
              {t("277")}
            </Link>
          </Grid>
        </Grid>
      </WBPBeeCareStatusInfoBox>
    </>
  ) : (
    <WBPBeeCareStatusInfoBox
      selectedStep={value.beecare.selectedStep}
      displayStepDetails={changeSelectedStep}
      title={t("Here's what's happening in - on way back")}
      showNextButton={value.lastSeason.psp !== "PAYREXX" ? true : false}
      secondary
    >
      <Trans i18nKey="266" />
    </WBPBeeCareStatusInfoBox>
  );
}
