import { useCallback, useContext } from "react";
import jwt_decode from "jwt-decode";
import API from "../services/api";
import history from "../services/history";
import UIContext from "../contexts/UIContext";
import firebase from "firebase/app";
import "firebase/auth";

export const useAuth = () => {
  const [, , loader] = useContext(UIContext);

  const getBeeAdminID = useCallback((token) => {
    const tokenInfo = jwt_decode(token);
    return tokenInfo.client_id;
  }, []);

  const signupExisting = useCallback(async () => {
    const result = await API.post("me/signup-existing");
    return result;
  }, []);

  const signup = useCallback(
    async (data) => {
      loader.addTask("SIGNUP");

      API.post(`me/signup`, data)
        .then((res) => {
          firebase
            .auth()
            .updateCurrentUser(firebase.auth().currentUser)
            .then(() => {
              loader.clearTask("SIGNUP");

              history.push({
                pathname: "/",
                state: {
                  data: res.data,
                }, // your data array of objects
              });
              return true;
            });
        })
        .catch((error) => {
          console.warn(error);
          loader.clearTask("SIGNUP");
          return false;
        });
    },
    [loader]
  );
  return {
    getBeeAdminID,
    signupExisting,
    signup,
  };
};
