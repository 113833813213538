import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../contexts/UserContext";
import firebase from "firebase/app";
import { useLocation } from "react-use";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

export const Freshdesk = () => {
  const [t, i18n] = useTranslation();
  const [user] = useContext(UserContext);
  const [widgetCount, setWidgetCount] = useState(0);

  const freshdeskId = t("freshdesk-widget-id");
  const freshdeskLanguage = t("freshdesk-widget-locale");
  const freshdeskToken = t("freshdesk-widget-token");
  const location = useLocation();

  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  const deleteWidget = () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget("destroy");

      delete window.FreshworksWidget;
      delete window.fwSettings;
      if (document.getElementById("freshdesk")) {
        document.getElementById("freshdesk").remove();
      }
      if (document.getElementById("freshchat-js-sdk")) {
        document.getElementById("freshchat-js-sdk").remove();
      }
    }
    if (window.fcWidget) {
      window.fcWidget.destroy();
      delete window.fcWidget;
    }
    if (window.fc_cobrowse) {
      // window.fc_cobrowse.cleanUp();
      delete window.fc_cobrowse;
    }
  };

  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.user.setProperties({
        countryCode: i18n.language.substring(3, 5),
      });
    }
  }, [i18n.language, window.fcWidget]);

  useEffect(() => {
    if (user && user.id && window.fcWidget && window.fcWidget.user) {
      const userProperties = {
        firstName: user.name,
        email: user.billing_contact.email,
      };
      if (user.billing_contact) {
        userProperties.firstName = user.billing_contact.first_name;
        userProperties.lastName = user.billing_contact.last_name;
      }

      window.fcWidget.user.setProperties(userProperties);
    }
  }, [user, window.fcWidget]);

  useEffect(() => {
    if (!isMobile) {
      const timer = setTimeout(() => {
        if (window.fcWidget) {
          window.fcWidget.open();
        }
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [location.pathname, isMobile]);

  useEffect(() => {
    if (
      i18n.language &&
      i18n.language !== firebase.auth().languageCode &&
      freshdeskId &&
      freshdeskLanguage &&
      freshdeskToken
    ) {
      if (widgetCount < 1) {
        initFreshChat();
        setWidgetCount((prevCount) => Number(prevCount + 1));
      }
    }
    return () => {};
  }, [i18n.language]);

  const initFreshChat = () => {
    const countryCode = i18n.language.substring(3, 5).toUpperCase();
    if (countryCode !== "CH" && countryCode !== "DE") {
      deleteWidget();
    } else {
      if (
        window.fcWidget &&
        window.fcWidget.isInitialized() === true &&
        window.fcWidget.isLoaded() === true
      ) {
        window.location.reload();
      } else {
        deleteWidget();
        window.fwSettings = {
          widget_id: freshdeskId,
          locale: freshdeskLanguage,
          siteId:
            (user && user.organisation && user.organisation.id === 3) ||
            countryCode === "DE"
              ? "Deutschland"
              : "Schweiz",
        };
        if (freshdeskToken !== "none") {
          const initialScript = document.createElement("script");
          initialScript.type = "text/javascript";
          initialScript.src = "https://wchat.freshchat.com/js/widget.js";
          initialScript.id = "freshchat-js-sdk";
          initialScript.async = true;
          initialScript.defer = true;
          initialScript.onload = () => {
            window.fcWidget.init({
              token: `${freshdeskToken}`,
              host: "https://wchat.freshchat.com",
              locale: `${freshdeskLanguage}`,
              config: {
                headerProperty: {
                  appName:
                    countryCode === "CH" ? "Wildbiene + Partner" : "BeeHome",
                },
              },
            });
          };

          document.body.appendChild(initialScript);
        }
      }
    }
  };

  return <></>;
};
