import React, { useContext, useState, useEffect, useRef } from "react";
import {
  TextField,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import history from "../services/history";
import { StartpopSettingsContext } from "../contexts/SettingsContext";
import StartpopOrderContext from "../contexts/StartpopOrderContext";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import StartpopOrderSideBar from "./StartpopOrderSideBar";
import WBPPage from "./sub-components/WBPPage";
import WBPInfoBox from "./sub-components/WBPInfoBox";
import { ChevronRight, EventOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DateToolBar from "./sub-components/DateToolBar";
import { makeStyles } from "@mui/styles";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import "dayjs/locale/fr";
import "dayjs/locale/de";

import CornutaWarningModal from "./modals/CornutaWarningModal";
import moment from "moment/moment";
import themeOptions from "../services/theme-default";
import HeaderTextDropdowns from "./sub-components/HeaderTextDropdowns";

const useStyles = makeStyles(() => ({
  dateModal: {
    marginBottom: "30px",
  },
}));

const StartpopDeliveryDate = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const settings = useContext(StartpopSettingsContext);
  const [calendarOpen, setCalendarOpen] = useState(true);
  const [errorOn, setErrorOn] = useState(false);
  const dateUnderstoodRef = useRef(false);
  const [startpopOrder, setStartpopOrder] = useContext(StartpopOrderContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const locale = i18n.language.substring(0, 2);

  const [warningModalOpenStatus, setWarningModalOpenStatus] = useState(false);

  useMount(() => {
    if (!startpopOrder.amount) {
      history.push("/startpops");
    }
  });

  useEffect(() => {
    const today = new Date(moment.parseZone(moment.now()).format("YYYY-MM-DD"));
    const selDate = new Date(startpopOrder.delivery_on);
    setErrorOn(!startpopOrder.delivery_on || today >= selDate);
  }, [startpopOrder.delivery_on]);

  useEffect(() => {
    if (!startpopOrder.delivery_on && settings.next_possible_date) {
      setStartpopOrder((prevStartpopOrder) => ({
        ...prevStartpopOrder,
        delivery_on: moment
          .parseZone(settings.next_possible_date)
          .format("YYYY-MM-DD"),
        deliveryChosenOn: new Date(),
      }));
    }
  }, [
    startpopOrder.delivery_on,
    settings.next_possible_date,
    setStartpopOrder,
  ]);

  const nextPage = (e) => {
    e.preventDefault();
    if (startpopOrder.order_date !== moment.utc().format("YYYY-MM-DD")) {
      location.reload();
    } else if (
      settings.osmiaTypeSeason === "cornuta" &&
      !dateUnderstoodRef.current.checked
    ) {
      setErrorOn(true);
    } else if (settings.osmiaTypeSeason === "bicornis") {
      setWarningModalOpenStatus(true);
    } else {
      history.push("/startpops/delivery-address");
    }
  };

  const handleDateChange = (date) => {
    const todayDate = moment.parseZone(new Date()).format("YYYY-MM-DD");
    const currentDate = moment.parseZone(new Date(date)).format("YYYY-MM-DD");

    if (todayDate !== currentDate) {
      setStartpopOrder((prevStartpopOrder) => ({
        ...prevStartpopOrder,
        delivery_on: currentDate,
      }));
    }
  };

  const disableDate = (date) => {
    let next_possible_date = new Date(
      moment
        .parseZone(settings.next_possible_date)
        .format("YYYY-MM-DD 00:00:00")
    );
    const currentDate = new Date(date);

    let isDisabled =
      moment.parseZone(currentDate).dayOfYear() <
      moment.parseZone(next_possible_date).dayOfYear();

    isDisabled =
      settings.unavailable_dates &&
      settings.unavailable_dates.indexOf(
        moment.parseZone(currentDate).format("YYYY-MM-DD")
      ) > -1
        ? true
        : isDisabled;
    return isDisabled;
  };

  return (
    <>
      <StartpopOrderSideBar step={1}>
        <WBPPage title={t("110")}>
          <form onSubmit={nextPage}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    {isMobile ? (
                      <MobileDatePicker
                        orientation="portrait"
                        label=" "
                        DialogProps={{
                          classes: { root: classes.dateModal },
                        }}
                        open={calendarOpen}
                        onOpen={() => setCalendarOpen(true)}
                        onClose={() => setCalendarOpen(false)}
                        value={startpopOrder.delivery_on}
                        onChange={handleDateChange}
                        disablePast
                        disableHighlightToday
                        minDate={moment(settings.start_date)}
                        maxDate={moment(settings.end_date)}
                        shouldDisableYear={() => true}
                        ToolbarComponent={() => (
                          <DateToolBar
                            selectedDate={startpopOrder.delivery_on}
                          />
                        )}
                        cancelText={t("Cancel")}
                        shouldDisableDate={(d) =>
                          disableDate(d, settings.next_possible_date)
                        }
                        dayOfWeekFormatter={(d) => d}
                        views={["day"]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setCalendarOpen(!calendarOpen)
                                    }
                                    onMouseDown={() =>
                                      setCalendarOpen(!calendarOpen)
                                    }
                                  >
                                    <EventOutlined />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    ) : (
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={startpopOrder.delivery_on}
                        onChange={handleDateChange}
                        orientation="portrait"
                        label=" "
                        disablePast
                        disableHighlightToday
                        minDate={moment(settings.start_date)}
                        maxDate={moment(settings.end_date)}
                        shouldDisableYear={() => true}
                        ToolbarComponent={() => (
                          <DateToolBar
                            selectedDate={startpopOrder.delivery_on}
                          />
                        )}
                        dayOfWeekFormatter={(d) => d}
                        shouldDisableDate={disableDate}
                        views={["day"]}
                        renderInput={(params) => <TextField {...params} />}
                        showToolbar
                      />
                    )}
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs>
                <WBPInfoBox
                  customColor={themeOptions.palette.primary.main}
                  customPadding="8px 32px"
                  roundedBorderRadius
                >
                  <HeaderTextDropdowns
                    translationId="480"
                    components={{
                      chk:
                        settings.osmiaTypeSeason === "bicornis" ? (
                          <></>
                        ) : (
                          <FormControlLabel
                            hidden={true}
                            inputRef={dateUnderstoodRef}
                            control={
                              <Checkbox
                                onChange={() => setErrorOn(false)}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                sx={{
                                  color: errorOn
                                    ? theme.palette.error.main
                                    : "",
                                }}
                                color="default"
                              />
                            }
                            label={t("480-checkbox")}
                            componentsProps={{
                              typography: {
                                color: errorOn ? theme.palette.error.main : "",
                              },
                            }}
                          />
                        ),
                    }}
                  />
                </WBPInfoBox>
              </Grid>
            </Grid>
            <Box mb={4}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  id="button-startpops-deliverydate-back"
                  variant="outlined"
                  color="primary"
                  onClick={history.goBack}
                >
                  {t("404")}
                </Button>
                <Button
                  id="button-startpops-deliverydate-submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRight />}
                  type="submit"
                  disabled={errorOn}
                  disableElevation
                >
                  {t("406")}
                </Button>
              </Grid>
            </Box>
          </form>
        </WBPPage>
      </StartpopOrderSideBar>
      <CornutaWarningModal
        warningTextObject={{
          warningText: "505",
          acceptButtonCloseText: "OK",
        }}
        open={warningModalOpenStatus}
        onClose={() => {
          setWarningModalOpenStatus(false);
          history.push("/startpops/delivery-address");
        }}
      />
    </>
  );
};

export default StartpopDeliveryDate;
