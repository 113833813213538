import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import WBPPage from "./sub-components/WBPPage";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  bees: {
    width: "100%",
    maxWidth: 800,
  },
}));

export default function PageNotFound() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <WBPPage>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6}>
          <img
            src="/images/away-with-the-bees.png"
            alt={t("The website is currently down for maintenance")}
            className={classes.bees}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box ml={4}>
            <Typography variant="h2" align="center">
              404
            </Typography>
            <Typography variant="h3">
              {t("Oh No, This page is not found")}
            </Typography>
            <Typography variant="body1">
              {t("Need any help Call")}&nbsp;
              <a href={"tel:" + t("support-phone-number").split(" ").join("")}>
                {t("support-phone-number")}
              </a>
              .
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </WBPPage>
  );
}
