import React from "react";
import { useTranslation } from "react-i18next";
import ErrorPage from "./ErrorPage";

export default function Maintenance() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      title={t("The website is currently down for maintenance")}
      message="here you go"
    ></ErrorPage>
  );
}
