import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function WBPInfoBox({
  children,
  customColor,
  customPadding,
  roundedBorderRadius,
  secondary,
  title,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      p={customPadding ? customPadding : 4}
      mb={4}
      bgcolor={
        customColor
          ? customColor
          : secondary
          ? theme.palette.background.default
          : "grey.300"
      }
      borderRadius={roundedBorderRadius ? "4px" : "0px"}
    >
      {title && <Typography variant="h6">{t(title)}</Typography>}
      {children}
    </Box>
  );
}
